import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MenuBookIcon from "@mui/icons-material/MenuBook";
export const bottomRoutes = [
  {
    name: "Roles Management",
    icon: <ManageAccountsIcon fontSize="small" />,
    route: [
      { name: "User", link: "" },
      { name: "Role", link: "" },
      { name: "Permission", link: "" },
      { name: "Resourses", link: "" },
    ],
  },
  

  // {
  //   icon: <DashboardOutlinedIcon fontSize="small" />,
  //   name: "Dashboard",
  //   link: "/dashboard",
  // },
  // {
  //   icon: <DocumentScannerOutlinedIcon fontSize="small" />,
  //   name: "Records",
  //   link: "/records",
  // },
  // {
  //   icon: <FolderOpenOutlinedIcon fontSize="small" />,
  //   name: "Tutor",
  //   link: "/Tutor",
  // },
  // {
  //   icon: <SettingsOutlinedIcon fontSize="small" />,
  //   name: "Supplier",
  //   link: "*",
  // },
  // {
  //   icon: <CastForEducationOutlinedIcon fontSize="small" />,
  //   name: "Seller",
  //   link: "*",
  // },
  // {
  //   icon: <GroupsOutlinedIcon fontSize="small" />,
  //   name: "FBA Calculator",
  //   link: "*",
  // },
  // {
  //   icon: <ForumOutlinedIcon fontSize="small" />,
  //   name: "Sales Estiamte",
  //   link: "*",
  // },
  // {
  //   icon: <FaChrome fontSize={16} />,
  //   name: "Bulk Analisis Tool",
  //   link: "*",
  // },
  // {
  //   icon: <SlSocialYoutube fontSize={16} />,
  //   name: "Bundles Ideas",
  //   link: "*",
  // },
  // {
  //   icon: <CiFacebook fontSize={16} />,
  //   name: "Facebook Group",
  //   link: "*",
  // },
  // {
  //   icon: <FaDiscord fontSize={16} />,
  //   name: "Discord",
  //   link: "*",
  // },
];
