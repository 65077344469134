import React from "react";

function Box(props) {
  const { actionData } = props;
  const actionDataValue = actionData?.map((item, index) => {
    return (
      <div className="box-block" key={index}>
        <div onClick={() => props.actionHandler("Notify")}>{item.Notify}</div>
        <div onClick={() => props.actionHandler("Profile")}>{item.Profile}</div>
        <div onClick={() => props.actionHandler("Edit")}>{item.Edit}</div>
        <div onClick={() => props.actionHandler("Delete")}>{item.Delete}</div>
      </div>
    );
  });
  return <div className="box-block-shadow">{actionDataValue}</div>;
}

export default Box;
