import { Form, Modal, Dropdown, DropdownButton, Button } from "react-bootstrap";
import upload from "../../Assests/icon/file.png";
import BasicButton from "../Button/Button";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BasicDropdown from "../Dropdown/Dropdown";
import { CircleMinus } from "lucide-react";
import React, { useEffect, useState, useRef, useCallback } from "react";
import AdvanceTable from "../Table/AdvanceTable";
import BasicTable from "../Table/BasicTable";
import BasicModal from "../Modal/Modal";
import defaultImage from "../../Assests/icon/profile-picture.png";

import * as pdfjsLib from "pdfjs-dist/webpack";
import "pdfjs-dist/web/pdf_viewer.css";

import * as pdfAnnotate from "annotpdf/_bundles/pdfAnnotate";
import Loader from "../../Components/Loader/Loader"; // Assuming you have a Loader component
import "../../Layout/PDFEditor/AnnotPdf.css";
import { getPdfMeta } from "../../Service/BaseService/BaseService";
import { saveAs } from 'file-saver';
import { PDFDocument, rgb, grayscale, StandardFonts } from 'pdf-lib';
import * as fontkit from 'fontkit';
import inkfreeFontUrl from '../../Fonts/Inkfree.ttf';


const userDetails = JSON.parse(localStorage.getItem("User"));
const accessToken = userDetails?.token;


export const UploadBox = (props) => {
  const {
    isModal,
    modalCloseHandler,
    submitHandler,
    form,
    changeValue,
    data,
    student,
    changeHandler,
    removeRowHandler,
    selectAll,
    handleFileChange,
    isAssignmentId,
    selectallHandler,
    file,
    tutor,
    status,
  } = props;
  console.log("UploadBox", props);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSelectionChange = (selectedRows) => {
    setSelectedStudents(selectedRows);
  };

  const handleClick = () => {
    document.getElementById("file-upload").click();
  };

  const assignmentColumns = [
    {
      field: "imageURL",
      headerName: "",
      flex: 1,
      width: 80,
      renderCell: (params) => {
        return (
          <div className="table-profile-picture">
            <img
              src={params.row.imageURL ? params.row.imageURL : defaultImage}
              alt="profile-img"
            />
          </div>
        );
      },
    },
    {
      field: "firstName",
      headerName: "Name",

      flex: 1,
      renderCell: (params) => (
        <div>
          <p className="tabel-cell">{params.row.firstName}</p>
        </div>
      ),
    },
    {
      field: "lastName",
      headerName: "last Name",
      flex: 1,
      renderCell: (params) => (
        <div>
          <p className="tabel-cell">{params.row.lastName}</p>
        </div>
      ),
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      flex: 1,
      renderCell: (params) => (
        <div>
          <p className="tabel-cell">{params.row.dateOfBirth}</p>
        </div>
      ),
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
      renderCell: (params) => (
        <div>
          <p className="tabel-cell">{params.row.gender}</p>
        </div>
      ),
    },
    {
      field: "parent",
      headerName: "Parent",
      flex: 1,
      renderCell: (params) => (
        <div>
          <p className="tabel-cell">{params.row?.parent ?? ""}</p>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const isAssigned =
          status === "assigned" && !form.studentIds.includes(params.row.id);

        if (isAssigned) {
          return (
            <div>
              <p className="tabel-cell">Queued</p>
            </div>
          );
        }

        return (
          <div>
            <p className="tabel-cell">
              {status !== "assigned" && status !== "immediate"
                ? "Queued"
                : "Assigned"}
            </p>
          </div>
        );
      },
    },
    {
      field: "additionalFieldUpdate",
      headerName: "Remove",
      flex: 1,

      renderCell: (params) => {
        const isAssigned =
          status === "assigned" && form.studentIds.includes(params.row.id);
        if (isAssigned) {
          return null;
        }
        return (
          <div
            className="button visibility-icon"
            onClick={() => changeHandler("remove", [params.row.id])}
          >
            <svg
              className="svg"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              height="25"
              width="25"
            >
              <path
                d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z"
                clip-rule="evenodd"
                fill-rule="evenodd"
              ></path>
            </svg>
          </div>
        );
      },
    },
  ];

  const modalColumns = assignmentColumns.filter(
    (col) => col.field !== "additionalFieldUpdate" && col.field !== "status"
  );

  const rows = student
    ? student
      .filter((item) => item.isChecked)
      .map((item, index) => ({
        id: item._id,
        firstName: item?.firstName ?? "",
        lastName: item?.lastName ?? "",
        dateOfBirth: item?.dateOfBirth ?? "",
        gender: item?.gender ?? "",
        parent: item?.parent?.firstName ?? "",
        imageURL: item?.imageURL,
      }))
    : [];

  const uncheckedStudents = student
    ? student
      .filter((item) => !item.isChecked)
      .map((item) => ({
        id: item._id,
        firstName: item.firstName,
        lastName: item.lastName,
        dateOfBirth: item.dateOfBirth,
        gender: item.gender,
        parent: item?.parent?.firstName ?? "",
        imageURL: item?.imageURL,
      }))
    : [];

  const handleSaveChanges = () => {
    const selectedIds = selectedStudents.map((student) => student);
    changeHandler("add", selectedStudents);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Form
        onSubmit={submitHandler}
        style={{
          color: "#212529",
          backgroundColor: "#ffffff",
          padding: "9px",
          borderRadius: "13px",
        }}
      >
        <div className="d-flex gap-3">
          <div style={{ flex: 1 }}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="modal-label">Title</Form.Label>
              <Form.Control
                className="text-focus"
                required
                value={form?.name}
                onChange={changeValue}
                name="name"
                readOnly={status !== "" && status !== undefined}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="modal-label">Description</Form.Label>
              <Form.Control
                as="textarea"
                className="form-control"
                name="description"
                required
                value={form?.description}
                onChange={changeValue}
                rows={3}
              />
            </Form.Group>
            <div className="d-flex gap-2">
              <div style={{ flex: 1 }}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="modal-label">
                    Due date(optional)
                  </Form.Label>
                  <Form.Control
                    value={form?.dueDate}
                    type="datetime-local"
                    className="modal-input"
                    onChange={changeValue}
                    name="dueDate"
                  />
                </Form.Group>
              </div>
              <div style={{ flex: 1 }}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="modal-label">
                    Schedule date(optional)
                  </Form.Label>
                  <Form.Control
                    value={form?.scheduleDate}
                    type="datetime-local"
                    className="modal-input"
                    name="scheduleDate"
                    onChange={changeValue}
                  />
                </Form.Group>
              </div>
            </div>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="modal-label">Attachments</Form.Label>
              <div className="upload-block">
                <div>
                  <img src={upload} alt="upload" className="upload-icon" />
                </div>
                <div>
                  <label className="upload-label" onClick={handleClick}>
                    <span className="span-text">Click here to upload</span>
                  </label>
                </div>
                <Form.Control
                  id="file-upload"
                  className="modal-input file-upload"
                  type="file"
                  accept=".pdf"
                  autoFocus
                  onChange={handleFileChange}
                  name="title"
                />
              </div>
            </Form.Group>
            <div className="d-flex gap-2 flex-wrap">
              {file?.map((item, index) => (
                <div className="upload-file" key={index}>
                  <div className="mt-2">
                    <div>
                      <div>
                        <DescriptionOutlinedIcon className="file-icon" />
                        <span>{item.name || item.filename}</span>
                      </div>
                    </div>
                  </div>
                  <CircleMinus
                    className="minus-icon"
                    onClick={() => removeRowHandler(index)}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex gap-2">
              <div style={{ flex: 1 }}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="modal-label">Batch</Form.Label>
                  <Form.Select
                    className="modal-input"
                    onChange={changeValue}
                    value={form?.classRoomId}
                    name="classRoomId"
                    required
                  >
                    <option>select</option>
                    {typeof data === "string" ? (
                      <option value={data}>{data}</option>
                    ) : Array.isArray(data) && data.length > 0 ? (
                      data.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.title}
                        </option>
                      ))
                    ) : (
                      <option>No data available</option>
                    )}
                  </Form.Select>
                </Form.Group>
              </div>
              {/* <div style={{ flex: 1 }}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label className="modal-label">Student</Form.Label>
                  <BasicDropdown
                    data={uncheckedStudents}
                    changeHandler={changeHandler}
                    selectAll={selectAll}
                    selectallHandler={selectallHandler}
                  />
                </Form.Group>
              </div> */}
              <div style={{ flex: 1 }}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlSelect1">
                  <Form.Label className="modal-label">Tutor</Form.Label>
                  <Form.Select
                    onChange={changeValue}
                    aria-label="Select tutor"
                    className="modal-input"
                    name="tutorId"
                    value={form?.tutorId}
                    required
                  >
                    <option value="">select</option>
                    {tutor?.map((item, index) => (
                      <option key={index} value={item._id}>{item.firstName}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-2">
          <div className="d-flex justify-content-end">
            <BasicButton
              baseStyle="submit-save-btn"
              value={"Add Students"}
              onClick={toggleModal}
            />
          </div>
          {student && <BasicTable rows={rows} columns={assignmentColumns} />}
        </div>
        <div className="footer">
          <BasicButton
            value={"Cancel"}
            baseStyle="close-btn"
            onClick={modalCloseHandler}
          />

          <div>
            {status !== "immediate" && status !== "assigned" && (
              <input
                type="submit"
                className="submit-save-btn-draft"
                value={"Save as Draft"}
              />
            )}

            <input
              type="submit"
              className="submit-save-btn"
              value={isAssignmentId ? "Update" : "Submit"}
            />
          </div>
        </div>
        <BasicModal
          isModal={isModalOpen}
          setIsModal={setIsModalOpen}
          searchHandler={(e) => console.log(e.target.value)}
          rows={uncheckedStudents}
          columns={modalColumns}
          handleSelectionChange={handleSelectionChange}
          saveHandler={handleSaveChanges}
          header="Add Students"
        />
      </Form>
    </div>
  );
};

export const DetailsTable = (props) => {
  const { isModal, setIsModal, assignment } = props;
  return (
    <React.Fragment>
      <Modal
        show={isModal}
        onHide={() => setIsModal(false)}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <BasicTable rows={assignment.row} columns={assignment.col} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BasicButton
            value={"Close"}
            baseStyle="close-btn"
            onClick={() => props.setIsModal(false)}
          />
          {/* <BasicButton
            value={"Save Changes"}
            baseStyle="modal-save-btn"
            // onClick={() => {
            //   props.saveHandler();
            //   props.setIsModal(false);
            // }}
          /> */}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export const PdfViewer = (props) => {
  const { modal, modalHandler, isViewAssignment, isStatus, fileId, fileName } = props;
  const [pdfViewer, setPdfViewer] = useState(null);
  const [pdfRendered, setPdfRendered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [pdfFactory, setPdfFactory] = useState(null);


  const viewerContainerRef = useRef(null);

  const initializePdfViewer = useCallback(async () => {
    if (isViewAssignment) {
      console.log("isViewAssignment", isViewAssignment);
      setIsLoading(true);
      const pdfContainer = viewerContainerRef.current;
      const pdfjsdist = window.pdfjsViewer;
      const newPdfViewer = new pdfjsdist.PDFViewer({
        container: pdfContainer,
      });

      let loadingTask = pdfjsLib.getDocument({ url: isViewAssignment });
      loadingTask.promise.then(async (pdfDocument) => {
        pdfDocument.getData().then((data) => {
          const pdffactory = new pdfAnnotate.AnnotationFactory(data);
          setPdfFactory(pdffactory);
        });
        newPdfViewer.setDocument(pdfDocument);
        setTimeout(() => {
          newPdfViewer.currentScaleValue = 'page-width';
          setPdfRendered(true);
          setIsLoading(false);
        }, 1500);

        setPdfViewer(newPdfViewer);
      }).catch((error) => {
        console.error('Error loading PDF:', error);
        setIsLoading(false);
      });
    }
  }, [isViewAssignment]);

  const getMetaData = async (fileId) => {
    const response = await getPdfMeta(accessToken, fileId);
    if (response.ok) {
      const responseData = await response.json();
      setAnnotations(responseData.message.meta);
    } else {
      console.error("Failed to load Meta:", response.statusText);
    }
  };

  const handleClick = () => {

    SaveAnnotations();
  };

  useEffect(() => {
    if (modal) {
      initializePdfViewer();
    } else {
      setPdfViewer(null);
      setPdfRendered(false);
    }

    return () => {
      if (pdfViewer) {
        pdfViewer.cleanup();
      }
    };
  }, [initializePdfViewer, modal]);

  useEffect(() => {
    if (pdfRendered && isStatus == 'reviewed' && fileId) {
      getMetaData(fileId);
    }
  }, [pdfRendered]);

  useEffect(() => {
    if (annotations) {
      renderAnnotations();
    }
  }, [annotations]);

  const renderAnnotations = () => {
    let viewer = document.getElementById("viewer");

    annotations.forEach((annotation) => {
      if (annotation && annotation.length > 0) {
        annotation.forEach((annotation) => {
          console.log("Triggered");
          if (annotation.type === "/Text") {
            let viewportRect = pdfViewer._pages[
              annotation.page
            ].viewport.convertToViewportRectangle(annotation.coordinates);

            let highlightDiv = document.createElement("div");
            let div = document.createElement("div");

            div.classList.add("annotation-icon");
            div.setAttribute("data-annotation-id", annotation.annotationId);
            div.style.left = viewportRect[0] - 20 + "px";
            div.style.top = viewportRect[1] - 10 + "px";

            let icon = document.createElement("i");
            icon.classList.add("bi", "bi-chat-square-text-fill");
            div.appendChild(icon);

            if (annotation.hlCoordinates) {
              const [x1, y1, x2, y2] = annotation.hlCoordinates;
              const page = pdfViewer._pages[annotation.page];
              const viewport = page.viewport;

              highlightDiv.setAttribute(
                "data-annotation-id",
                annotation.annotationId
              );
              highlightDiv.style.position = "absolute";
              highlightDiv.style.backgroundColor = "yellow";
              highlightDiv.style.opacity = "0.5";

              const [left, top] = viewport.convertToViewportPoint(x1, y1);
              const [right, bottom] = viewport.convertToViewportPoint(x2, y2);

              const width = right - left;
              const height = bottom - top;

              highlightDiv.style.left = `${left}px`;
              highlightDiv.style.top = `${top}px`;
              highlightDiv.style.width = `${width}px`;
              highlightDiv.style.height = `${height}px`;
            }

            div.title = annotation.content;

            let pageContainer = viewer.querySelector(
              `.page[data-page-number="${annotation.page + 1}"]`
            );
            if (pageContainer) {
              pageContainer.appendChild(div);
              pageContainer.appendChild(highlightDiv);
            } else {
              console.error(`Page ${annotation.page} not found in viewer.`);
            }
          } else if (annotation.type === "/Highlight") {
            if (annotation.coordinates.length === 4) {
              const [x1, y1, x2, y2] = annotation.coordinates;
              const page = pdfViewer._pages[annotation.page];
              const viewport = page.viewport;

              const strikethroughDiv = document.createElement("div");
              strikethroughDiv.setAttribute(
                "data-annotation-id",
                annotation.annotationId
              );
              strikethroughDiv.style.position = "absolute";
              strikethroughDiv.style.backgroundColor = "rgba(255, 255, 0, 0.35)";
              strikethroughDiv.style.height = "30px";

              const [left, top] = viewport.convertToViewportPoint(x1, y1);
              const [right, bottom] = viewport.convertToViewportPoint(x2, y2);

              const width = right - left;
              const height = bottom - top;
              const midpointY = (top + bottom) / 2;

              strikethroughDiv.style.left = `${left}px`;
              strikethroughDiv.style.top = `${midpointY - 15}px`;
              strikethroughDiv.style.width = `${width}px`;

              const pageContainer = viewer.querySelector(
                `.page[data-page-number="${annotation.page + 1}"]`
              );
              if (pageContainer) {
                pageContainer.appendChild(strikethroughDiv);
              } else {
                console.error(`Page ${annotation.page} not found in viewer.`);
              }
            }
          } else if (annotation.type === "/Strikethrough") {
            if (annotation.coordinates.length === 4) {
              const [x1, y1, x2, y2] = annotation.coordinates;
              const page = pdfViewer._pages[annotation.page];
              const viewport = page.viewport;

              const strikethroughDiv = document.createElement("div");
              strikethroughDiv.setAttribute(
                "data-annotation-id",
                annotation.annotationId
              );
              strikethroughDiv.style.position = "absolute";
              strikethroughDiv.style.borderBottom = "2px solid red";

              const [left, top] = viewport.convertToViewportPoint(x1, y1);
              const [right, bottom] = viewport.convertToViewportPoint(x2, y2);

              const width = right - left;
              const height = bottom - top;

              strikethroughDiv.style.left = `${left}px`;
              strikethroughDiv.style.top = `${top + height / 2}px`;
              strikethroughDiv.style.width = `${width}px`;

              const pageContainer = viewer.querySelector(
                `.page[data-page-number="${annotation.page + 1}"]`
              );
              if (pageContainer) {
                pageContainer.appendChild(strikethroughDiv);
              } else {
                console.error(`Page ${annotation.page} not found in viewer.`);
              }
            }
          } else if (annotation.type === "/Circle") {
            if (annotation.coordinates.length === 4) {
              const [x1, y1, x2, y2] = annotation.coordinates;
              const page = pdfViewer._pages[annotation.page];
              const viewport = page.viewport;

              const [vx1, vy1] = viewport.convertToViewportPoint(x1, y1);
              const [vx2, vy2] = viewport.convertToViewportPoint(x2, y2);

              const centerX = (vx1 + vx2) / 2;
              const centerY = (vy1 + vy2) / 2;
              const xScale = Math.abs(vx2 - vx1) / 2;
              const yScale = Math.abs(vy2 - vy1) / 2 + 15;


              // const xScale = Math.abs(x2 - x1) / 2;
              // const yScale = Math.abs(y2 - y1) / 2 + 15;

              const ellipseDiv = document.createElement("div");
              ellipseDiv.setAttribute("data-annotation-id", annotation.annotationId);
              ellipseDiv.style.position = "absolute";
              ellipseDiv.style.border = "2px solid red";
              ellipseDiv.style.borderRadius = "50%";
              ellipseDiv.style.backgroundColor = "transparent";

              ellipseDiv.style.left = `${centerX - xScale}px`;
              ellipseDiv.style.top = `${centerY - yScale}px`;
              ellipseDiv.style.width = `${xScale * 2}px`;
              ellipseDiv.style.height = `${yScale * 2}px`;

              const pageContainer = viewer.querySelector(`.page[data-page-number="${annotation.page + 1}"]`);
              if (pageContainer) {
                pageContainer.appendChild(ellipseDiv);
              } else {
                console.error(`Page ${annotation.page} not found in viewer.`);
              }
            }
          } else if (annotation.type === "/FreeText") {

            if (annotation.coordinates.length === 4) {
              const [x1, y1] = annotation.coordinates;
              const page = pdfViewer._pages[annotation.page];
              const viewport = page.viewport;

              const [left, top] = viewport.convertToViewportPoint(x1, y1);

              const freeTextDiv = document.createElement("div");
              freeTextDiv.setAttribute("data-annotation-id", annotation.annotationId);
              freeTextDiv.style.position = "absolute";
              freeTextDiv.style.backgroundColor = "none";
              freeTextDiv.style.whiteSpace = "pre-wrap";

              freeTextDiv.style.left = `${left}px`;
              freeTextDiv.style.top = `${top - 14}px`;
              freeTextDiv.innerText = annotation.content;

              // Set the font, size, and color for the text
              freeTextDiv.style.fontFamily = "Inkfree";
              freeTextDiv.style.fontSize = "1.1rem";
              freeTextDiv.style.color = "red";

              const pageContainer = viewer.querySelector(`.page[data-page-number="${annotation.page + 1}"]`);
              if (pageContainer) {
                pageContainer.appendChild(freeTextDiv);
              } else {
                console.error(`Page ${annotation.page} not found in viewer.`);
              }
            }
          }
        });
      }
    });

  };

  const loadFont = async () => {
    const response = await fetch(inkfreeFontUrl);
    const fontBytes = await response.arrayBuffer();
    return fontBytes;
  };

  const SaveAnnotations = async () => {

    try {
      setLoading(true);
      let pdfBytesWithAnnotations;

      // Collect all promises from async operations
      const annotationPromises = annotations.map(async (annotation) => {
        return Promise.all(annotation.map(async (element) => {
          switch (element.type) {
            case "/Text":
              pdfFactory.createTextAnnotation(
                element.page,
                [
                  element.coordinates[0],
                  element.coordinates[1],
                  element.coordinates[0] + 22,
                  element.coordinates[1] + 22,
                ],
                element.content,
                element.author
              );
              if (element.hlCoordinates) {
                pdfFactory.createHighlightAnnotation(
                  element.page,
                  [
                    element.hlCoordinates[0],
                    element.hlCoordinates[1],
                    element.hlCoordinates[2],
                    element.hlCoordinates[3],
                  ],
                  "",
                  ""
                );
              }
              break;

            case "/Highlight":
            case "/Strikethrough":
            case "/FreeText":
            case "/Circle":
              pdfBytesWithAnnotations = await addAnnotationsToPDF(isViewAssignment, annotations);
              break;

            default:
              console.warn(`Unhandled annotation type: ${element.type}`);
              break;
          }
        }));
      });

      // Wait for all annotation processing to complete
      await Promise.all(annotationPromises);

      // Ensure pdfBytesWithAnnotations is defined before saving
      if (pdfBytesWithAnnotations) {
        console.log("pdfBytesWithAnnotations", pdfBytesWithAnnotations);
        saveAs(new Blob([pdfBytesWithAnnotations], { type: 'application/pdf' }), 'Reviewed_' + fileName);
      } else {
        console.error("Failed to generate PDF with annotations.");
      }

    } catch (err) {
      console.error("Error during saving annotations:", err.message);
    } finally {
      setLoading(false);
    }
  };

  const addAnnotationsToPDF = async (pdfBytes, annotations) => {
    const pdfDoc = await PDFDocument.load(pdfBytes);
    pdfDoc.registerFontkit(fontkit);


    const pages = pdfDoc.getPages();

    const fontBytes = await loadFont();
    const inkfreeFont = await pdfDoc.embedFont(fontBytes);

    annotations.forEach((Lannotation) => {
      Lannotation.forEach(annotation => {
        const { type, page, coordinates, content } = annotation;

        if (type == "/FreeText") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          const adjustedX = x1;
          const adjustedY = y1;

          pdfPage.drawText(content, {
            x: adjustedX,
            y: adjustedY,
            font: inkfreeFont,
            size: 17,
            color: rgb(1, 0, 0),
          });
        }
        else if (type == "/Strikethrough") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          try {
            pdfPage.drawLine({
              start: { x: x1, y: y1 },
              end: { x: x2, y: y1 },
              color: rgb(1, 0, 0),
              thickness: 2,
              opacity: 0.75,
            });

          } catch (error) {
            console.error(error);
          }

        }
        else if (type === "/Circle") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          // Calculate the center of the ellipse
          const centerX = (x1 + x2) / 2;
          const centerY = (y1 + y2) / 2;

          // Calculate the scales
          const xScale = Math.abs(x2 - x1) / 2;
          const yScale = Math.abs(y2 - y1) / 2 + 10;

          try {
            pdfPage.drawEllipse({
              x: centerX,
              y: centerY,
              xScale: xScale,
              yScale: yScale,
              borderColor: rgb(1, 0, 0),
              borderWidth: 2,
            });
          } catch (error) {
            console.error(error);
          }
        }
        else if (type === "/Highlight") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          try {
            pdfPage.drawLine({
              start: { x: x1, y: y1 },
              end: { x: x2, y: y1 },
              color: rgb(1, 1, 0),
              thickness: 25,
              borderColor: grayscale(1),
              opacity: 0.35,
            });
          } catch (error) {
            console.error(error);
          }
        }

      });

    });

    return await pdfDoc.save();
  };

  return (
    <div>
      <Modal show={modal} onHide={modalHandler} dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Modal.Title className="modal-title" style={{ fontSize: '18px !important' }}>Assignment</Modal.Title>
            {/* <button className='submit-save-btn'>Download</button> */}
            {isStatus == 'reviewed' && <Button
              variant="outline-primary"
              size="sm"
              onClick={!Loading ? handleClick : null}
            >
              {Loading ? 'Downloading…' : 'Download'}
            </Button>}
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{ display: 'flex', height: '100vh', flexDirection: 'column' }}
          >
            <div
              id="viewerContainer"
              ref={viewerContainerRef}
              style={{ width: '100%', height: '100vh', overflow: 'auto' }}
            >
              <div id="viewer" className="pdfViewer" />
            </div>
            {!pdfRendered && !isLoading && <div>Loading PDF...</div>}
            {isLoading && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Loader />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
