import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import BasicButton from "../Button/Button";
import upload from "../../Assests/icon/file.png";
import BasicTable from "../Table/BasicTable";
import { Table } from "react-bootstrap";
import { Input } from "@mui/material";
import { MdOutlineSearch } from "react-icons/md";

function BasicModal(props) {
  const {
    isModal,
    searchHandler,
    rows,
    columns,
    handleSelectionChange,
    setIsModal,
    saveHandler,
    header
  } = props;
  return (
    <React.Fragment>
      <Modal
        show={isModal}
        onHide={() => setIsModal(false)}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">{header ?? 'Set configure'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> */}
            {/* <Form.Label className="modal-label">Course</Form.Label> */}
            {/* <Form.Select
                className="modal-input"
                onChange={changeValue}
                name="title"
              >
                {course?.map((item, index) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </Form.Select> */}
            {/* </Form.Group> */}
            <Form.Group>
              <div className="d-flex align-items-center input-flex">
                <div className="search-icon-block">
                  <MdOutlineSearch
                    style={{ fontSize: "25px", color: "#3D3690" }}
                  />
                </div>
                <input
                  className="input-block"
                  placeholder="Search here..."
                  onChange={searchHandler}
                />
              </div>{" "}
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <BasicTable
                rows={rows}
                columns={columns}
                checkbox={true}
                height="150px"
                handleSelectionChange={handleSelectionChange}
              />
              {/* <div className="student-table-box">
                <Table bordered className="student-table">
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>First Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {0 < props?.data?.length ? (
                      data?.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                className="ui-checkbox"
                                checked={item.isChecked}
                                onChange={(e) => props.addStudentHandler(index)}
                              />
                            </td>
                            <td>{item.firstName}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <div className="d-flex justify-content-start">
                        <p>No data found</p>
                      </div>
                    )}
                  </tbody>
                </Table>
              </div> */}

              {/* <div className="upload-block">
                <div>
                  <img src={upload} alt="upload" className="upload-icon" />
                </div>
                <div>
                  <label className="upload-label">
                    <Form.Control
                      className="modal-input file-upload"
                      type="file"
                      accept=".pdf"
                      autoFocus
                      // value={props.isGet.title}
                      // onChange={props.changeValue}
                      name="title"

                      // name={Object.keys(props.isGet.title)}
                    />
                    <span className="span-text">Click here to upload</span>
                  </label>
                </div>
              </div> */}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <BasicButton
            value={"Cancel"}
            baseStyle="close-btn"
            onClick={() => setIsModal(false)}
          />
          <BasicButton
            value={"Submit"}
            baseStyle="submit-save-btn"
            onClick={() => {
              saveHandler();
              setIsModal(false);
            }}
          />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default BasicModal;
