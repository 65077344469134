import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../Assests/icon/11+GA Logo 3x4.png";
function ForgotPassword() {
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={4}>
          <div className="contanier-type">
            <form>
              <Link to={"/"} className="clk-link">
                Back to Login
              </Link>
              <div className="mt-3">
                <img src={logo} alt="Logo Small" />
                <h3>Forgot Password?</h3>
                <p>
                  Enter your email, and we'll send you instructions to reset
                  your password.
                </p>
                <div className="form-block">
                  <input
                    placeholder="Email"
                    required
                    autoComplete="email"
                    // value={isForm.email}
                    // onChange={(e) => {
                    //   setIsForm({ ...isForm, email: e.target.value });
                    // }}
                  />
                </div>
                <div className="justify-content-center d-flex">
                  <div className="submit-register-block mt-3 w-100">
                    <input
                      type="submit"
                      className="submit-btn-register"
                      value={"submit"}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
