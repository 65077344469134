const BaseUrl = "https://jsonplaceholder.typicode.com";

const baseURL = process.env.REACT_APP_BASE_URL;

export const oAuthLogin = (data) => {
  return fetch(`${baseURL}/user/login`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const oAuthRegister = (formData) => {
  return fetch(`${baseURL}/user/register`, {
    method: "POST",
    body: formData,

    // headers: {
    //   "Content-Type": "multipart/form-data",
    // },
  });
};
export const getCourse = (token) => {
  return fetch(`${baseURL}/course`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const getProfileData = (token) => {
  return fetch(`${baseURL}/user/profile`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
export const getBatchDetails = (token, id) => {
  return fetch(`${baseURL}/class/${id}/details`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};
//parent//
export const studentRegister = (formData, token) => {
  return fetch(`${baseURL}/student`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const roleRegister = (token, data) => {
  return fetch(`${baseURL}/user/createUser`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
//get//
export const fetchData = (token) => {
  return fetch(`${BaseUrl}/1`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getLastestStudent = (token) => {
  return fetch(`${baseURL}/student/latest`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const createClass = (token, data) => {
  return fetch(`${baseURL}/class`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAllClass = (token) => {
  return fetch(`${baseURL}/class`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getByClass = (token, id) => {
  return fetch(`${baseURL}/class/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClassStudent = (token, id) => {
  return fetch(`${baseURL}/class/assignedStudents?${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getStudentAssignment = (token, id) => {
  console.log(id)
  return fetch(`${baseURL}/student/assignments/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const assignmentDownload = (token, id) => {
  return fetch(`${baseURL}/assignment/download/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const assignmentDownloadTutor = (token, id) => {
  return fetch(`${baseURL}/assignment/${id}/download/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      const headers = {};
      response.headers.forEach((value, name) => {
        if (headers[name]) {
          if (!Array.isArray(headers[name])) {
            headers[name] = [headers[name]];
          }
          headers[name].push(value);
        } else {
          headers[name] = value;
        }
      });
      console.log(response.headers.get('Content-Disposition'));
      return response;
    })
    .catch((error) => {
      console.error("Error fetching:", error);
      throw error;
    });
};


export const getSubjectData = (token) => {
  return fetch(`${baseURL}/subject`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putClassData = (token, id, data) => {
  return fetch(`${baseURL}/class/${id}`, {
    method: "PUT",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUnallocate = (token, courseId) => {
  return fetch(`${baseURL}/course/allocated/${courseId}`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const fetchDataStudent = (token, data) => {
  return fetch(`${baseURL}/student/allStudents`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const createAssignment = (token, classId, data) => {
  console.log(data)
  console.log(classId)
  return fetch(`${baseURL}/assignment/${classId}/createAssignment`, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAssignment = (token) => {
  return fetch(`${baseURL}/assignment`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const removeAssignment = (token, id) => {
  return fetch(`${baseURL}/assignment/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getByIdAssignment = (token, id) => {
  return fetch(`${baseURL}/assignment/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const assignmentSubmission = (token, id, data) => {
  return fetch(`${baseURL}/assignment/submission/${id}`, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const assignmentView = (token, id, status) => {
  return fetch(`${baseURL}/assignment/view/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "X-AssignmentStatus": status
    },
  });
};
export const activation = (id) => {
  return fetch(`${baseURL}/user/activate/${id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
  });
};
export const getAssignmentDetails = (token, id) => {
  return fetch(`${baseURL}/assignment/submitted?${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getClassDetails = (id) => {
  return fetch(`${baseURL}/class/course/${id}`, {
    method: "GET",
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
  });
};
export const updateAssignment = (token, id, data) => {
  return fetch(`${baseURL}/assignment/${id}/update`, {
    method: "PUT",
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
export const getByStudent = (token, id) => {
  return fetch(`${baseURL}/student/${id}`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const postCourse = (token, data) => {
  return fetch(`${baseURL}/course`, {
    method: "POST",
    body: JSON.stringify(data),

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchDataTutor = (token) => {
  return fetch(`${baseURL}/user/tutors`, {
    method: "Get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteData = (id) => {
  return fetch(`${BaseUrl}/posts/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteStudent = (token, id) => {
  return fetch(`${baseURL}/student/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateData = (request) => {
  const { id, isGet } = request;
  return fetch(`${BaseUrl}/posts/${id}`, {
    method: "PUT",
    body: JSON.stringify(isGet),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateTutor = (token, data) => {

  return fetch(`${baseURL}/user/updateTutor`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

//////////////TUTOR////////////////////
export const getClass = (token) => {
  return fetch(`${baseURL}/class`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export async function updateCourse(token, courseId, updatedCourseData) {
  try {
    const response = await fetch(
      ` https://api.gurukulacademy.uk/api/course/${courseId}`,
      `${baseURL}/course/${courseId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updatedCourseData),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update course");
    }

    return response;
  } catch (error) {
    throw new Error(`Error updating course: ${error.message}`);
  }
}

//////////    PAYMENT

export async function getpaymentStatus(token, page, limit, status) {
  try {
    let url = `${baseURL}/course/payment-status?page=${page}&limit=${limit}`;
    if (status) {
      url += `&status=${status}`;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to update course");
    }

    return response;
  } catch (error) {
    throw new Error(`Error getting payment status: ${error.message}`);
  }
}

export async function sendNotificationSchedule(token, data) {
  try {
    const response = await fetch(`${baseURL}/notification/notify-payment`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to update course");
    }
    return response;
  } catch (error) {
    throw new Error(`Error scheduling Notifications: ${error.message}`);
  }
}
export const deleteTutor = async (id, token) => {
  try {
    const response = await fetch(`${baseURL}/user/deleteTutor/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return true;
    } else {
      console.error(`Failed to delete tutor. Status code: ${response.status}`);
    }
  } catch (error) {
    console.error("Error deleting the tutor:", error);
  }
};

export const getById = async (id, token) => {
  try {
    const response = await fetch(`${baseURL}/user/tutors/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      console.error(`Failed to fetch tutor. Status code: ${response.status}`);
    }
  } catch (error) {
    console.error("Error fetching the tutor:", error);
    throw error;
  }
};
export const tutorAssigned = (token) => {
  return fetch(`${baseURL}/class/tutor`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const changePasswordAPI = async (passwordData, token) => {
  try {
    const response = await fetch(
      `${baseURL}/user/changePassword`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(passwordData),
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      const errorData = await response.json();
      throw new Error(errorData.message || "Password change failed");
    }
  } catch (error) {
    throw new Error("Password change failed: " + error.message);
  }
};

export const getMetrics = (token) => {
  return fetch(`${baseURL}/user/metrics`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getParents = (token, id) => {
  const url = id ? `${baseURL}/user/parents/${id}` : `${baseURL}/user/parents`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};


export const updateStudent = async (formData, token, id) => {
  const response = await fetch(`${baseURL}/student/${id}`, {
    method: 'PUT',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};
export const getAssignedStudents = async (classId, token) => {
  try {
    const response = await fetch(
      `${baseURL}/class/assignedStudents?classId=${classId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = await response.json();
    const students = responseData.assignedStudents[0].studentId;
    const assignmentId = responseData.assignedStudents[0].assignmentId;

    return { students, assignmentId };
  } catch (err) {
    console.error("Error fetching assigned students:", err);
    throw err;
  }
};

export const reviewedAssignment = (token, assignmentSubmissionId, data) => {
  return fetch(`${baseURL}/assignment/evaluation/${assignmentSubmissionId}`, {
    method: "POST",
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};


export const getPdfMeta = (token, id) => {
  return fetch(`${baseURL}/assignment/pdfMeta/${id}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export async function pdfMetaData(token, id, data, status) {
  try {
    const queryString = `assignmentSubmissionId=${id}` + (status ? `&status=${status}` : '');
    const response = await fetch(`${baseURL}/assignment/pdfMeta?${queryString}`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error("Failed to update course");
    }
    return response;
  } catch (error) {
    throw new Error(`Error scheduling Notifications: ${error.message}`);
  }
}
