import React from 'react';
import './InputModal.css';

const InputModal = ({ isOpen, onClose, onSubmit, content, setContent, actionType }) => {
    if (!isOpen) return null;

    const handleAdd = () => {
        onSubmit(content, 'add'); // Use 'content' directly
        onClose();
        setContent(""); // Reset content
    };

    const handleUpdate = () => {
        onSubmit(content, 'update'); // Use 'content' directly
        onClose();
        setContent(""); // Reset content
    };

    const handleDelete = () => {
        onSubmit(content, 'delete'); // Use 'content' directly
        onClose();
        setContent(""); // Reset content
    };

    return (
        <div className="modal-overlay">
            <div className="input-modal-content">
                <textarea className='text-area'
                    value={content}
                    onChange={(e) => setContent(e.target.value)} 
                    rows="4"
                />
                <div className="modal-actions">
                    {actionType === 'add' && (
                        <button className='submit-save-btn' onClick={handleAdd}>Save</button>
                    )}
                    {actionType === 'update' && (
                        <>
                            <button className='submit-save-btn' onClick={handleUpdate}>Update</button>
                            <button className='close-btn' onClick={handleDelete}>Delete</button>
                        </>
                    )}
                    <button className='close-btn' onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default InputModal;
