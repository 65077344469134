import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import Basicpagination from "../../../../../Components/Pagination/Basicpagination";
import english from "../../../../../Assests/icon/371063-PBSV7F-292.jpg";
import maths from "../../../../../Assests/icon/2111.w039.n003.5B.p1.5.jpg";
import social from "../../../../../Assests/icon/12795.jpg";
import science from "../../../../../Assests/icon/ca25_a96z_230713.jpg";
import { useNavigate } from "react-router-dom";

function Subject() {
  const navigate = useNavigate();
  const addCourseHandler = () => {
    navigate("/add-subject");
  };
  const data = [
    {
      id: 1,
      img: english,
      describe:
        "An introductory course for English language basics, covering vocabulary, grammar, and communication skills",
      value: 4,
      title: "English",
      course: "Gurukulacademy",
      rating: "4.5 ",
      price: "₹1,199",
    },
    {
      id: 2,
      describe:
        "Foundational mathematics course introducing arithmetic, algebra, and geometry in an easy-to-understand format",
      img: maths,
      title: "Maths",
      value: 4,
      course: "Gurukulacademy",
      rating: "4.1 ",
      price: "₹1,099",
    },
    {
      id: 3,
      img: social,
      describe:
        "Comprehensive social studies course exploring history, geography, civics, and economics concepts",
      title: "Social",
      value: 3,
      course: "Gurukulacademy",
      rating: "3.1 ",
      price: "₹1,999",
    },
    {
      id: 4,
      describe:
        "Introductory science course covering biology, chemistry, physics, and environmental science topics",
      img: science,
      title: "science",
      course: "Gurukulacademy",
      value: 4,
      rating: "3.9 ",
      price: "₹1,199",
    },
  ];
  return (
    <Container>
      <Row>
        <Col>
          <div className="product">
            <SearchBar onClick={addCourseHandler} value={"subject"} />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        {data.map((item, index) => {
          return (
            <Col key={index} sm={12} md={3} lg={3} className="mb-2">
              <div className="course-card">
                <div>
                  <img src={item.img} alt="profile" />
                  <p>{item.title}</p>
                  <span>{item.course}</span>
                  <span>{item.describe}</span>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col>
          <div className="mt-2 d-flex justify-content-end">
            <Basicpagination />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Subject;
