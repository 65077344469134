import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assests/icon/11+GA Logo 3x4.png";
function Activation() {
  const location = useLocation();
  const getParams = new URLSearchParams(location.search);
  const getEmail = getParams.get("email");
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={4}>
          <div className="contanier-type">
            <form>
              <div className="mb-3">
                <img src={logo} alt="Logo Small" />{" "}
                <h3>Check your email to verify</h3>
                <p>
                  Activation link sent to{" "}
                  <span className="underline">{getEmail}</span>. Please click
                  the link to continue.
                </p>
                <p>
                  Didn't receive the email? Please check spam folder or{" "}
                  <Link to={"#"} className="clk-resend">
                    click to resend.
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Activation;
