import React from "react";

function ProfileBox(props) {
  const { ProfileactionData } = props;
  const profielDataValue = ProfileactionData.map((item, index) => {
    return (
      <div className="profile-block" key={index}>
        <div onClick={() => props.actionHandler("profile")}>{item.profile}</div>
        <div onClick={() => props.actionHandler("signout")}>{item.signout}</div>
      </div>
    );
  });
  return <div>{profielDataValue}</div>;
}

export default ProfileBox;
