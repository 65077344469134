import React from "react";
import { Button } from "react-bootstrap";

function BasicButton(props) {
  const { baseStyle, onClick, value } = props;
  return (
    <Button className={baseStyle} onClick={onClick}>
      {value}
    </Button>
  );
}

export default BasicButton;
