import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { routes } from "./Routes";
import logo from "../../../Assests/icon/11+GA Logo 3x4.png";
import logoname from "../../../Assests/icon/logo-name.png";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

function Sidebar() {
  const location = useLocation();
  const [isCollapse, setIsCollapse] = useState(null);
  const routeHadler = (i) => {
    setIsCollapse((prev) => {
      if (prev === i) {
        return null;
      }
      return i;
    });
  };
  const route = routes.map((item, index) => {
    return (
      <ul className="route-layout" key={index}>
        <Link to={item.link} className="nav-link-li">
          <div
            className={`route-block ${
              location.pathname === item.active ? "active" : ""
            }`}
            onClick={() => routeHadler(index)}
          >
            <div className="d-flex align-items-center gap-2">
              <span style={{ display: "inline-block" }}>{item.icon}</span>
              <p style={{ margin: 0, padding: 0 }} className="link-name">
                {item.name}
              </p>
            </div>
            <div>
              {0 < item.route.length && isCollapse === index && (
                <IoMdArrowDropdown />
              )}
              {0 < item.route.length && isCollapse !== index && (
                <IoMdArrowDropright />
              )}
            </div>
          </div>
        </Link>

        <div
          className={`line-item-block ${isCollapse === index ? "open" : ""}`}
        >
          {item.route.map((item, index) => {
            return (
              <NavLink className="nav-link-li" to={item.link}>
                <li className="li-name">{item.name}</li>
              </NavLink>
            );
          })}
        </div>
      </ul>
    );
  });

  return (
    <div className="sidebar">
      <div className="sidebar-block">
        <div className="d-flex justify-content-start">
          <img src={logo} className="sidebar-logo" alt="side-bar-logo" />
          <h5>Gurukul Academy</h5>
        </div>
      </div>
      <div>{route}</div>
    </div>
  );
}

export default Sidebar;
