import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Modal,
  Form,
  InputGroup,
  Dropdown,
} from "react-bootstrap";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import "./index.css";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import { CircleMinus } from "lucide-react";
import {
  getCourse,
  getSubjectData,
  updateCourse,
} from "../../../../../Service/BaseService/BaseService";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../../../../Components/Button/Button";
import { message } from "antd";

function CourseList() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [isSubject, setIsSubject] = useState(null);

  const [selectedDuration, setSelectedDuration] = useState("Duration");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    courseDuration: "",
    shortDescription: "",
    longDescription: "",
    duration: "",
    fee: "",
    rating: "",
    subjects: [{ _id: "", duration: "", name: "" }],
    imageURL: "",
    code: "",
    isActive: "",
    resources: "",
    monthlyFee: "",
    courseDurationUnit: "",
  });
  const [isInput, setIsInput] = useState(formData);
  useEffect(() => {
    fetchAllData();
    getSubject();
  }, []);

  const fetchAllData = async () => {
    try {
      const response = await getCourse(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        setData(responseData.message);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error fetching data: ", err);
    }
  };

  const addCourseHandler = () => {
    navigate(`/add-course`);
  };

  const handleEdit = (courseId) => {
    const selectedCourse = data.find((course) => course._id === courseId);
    if (selectedCourse) {
      setFormData({
        name: selectedCourse.name,
        courseDuration: selectedCourse.courseDuration,
        shortDescription: selectedCourse.shortDescription,
        longDescription: selectedCourse.longDescription,
        duration: selectedCourse.duration,
        fee: selectedCourse.fee, // Changed 'fees' to 'fee'
        rating: selectedCourse.rating,
        subjects: selectedCourse.subjects.map((subject) => ({
          _id: subject._id,
          name: subject.name,
          duration: subject.duration,
        })),
        imageURL: selectedCourse.imageURL,
        code: selectedCourse.code,
        isActive: selectedCourse.isActive,
        resources: selectedCourse.resources,
        onetimeFee: selectedCourse.onetimeFee,
        monthlyFee: selectedCourse.monthlyFee,
        courseDurationUnit: selectedCourse.courseDurationUnit,
      });

      setSelectedCourse(courseId);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCourse(null);
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    console.log("Switch value:", checked);
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        isActive: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    const form = e.target.form;
  };

  const updateCourseHandler = async () => {
    try {
      const updatedFormData = {
        ...formData,
        subjects: formData.subjects.map((subject) => ({
          _id: subject._id,
          name: subject.name,
          duration: subject.duration,
        })),
      };

      await updateCourse(userToken.token, selectedCourse, updatedFormData);
      fetchAllData();
      setShowModal(false);
      setSelectedCourse(null);
      message.success("Successfully Updated");
      {
        console.log("Form is invalid");
      }
    } catch (error) {
      console.error("Error updating course: ", error);
      message.error("Error updating course: " + error);
    }
  };

  const columns = [
    { field: "name", headerName: "Title", flex: 1 },
    { field: "shortDescription", headerName: "Description", flex: 1 },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      valueGetter: (params) =>
        `${params.row.duration} ${params.row.courseDurationUnit}`,
    },
    {
      field: "subjectsduration",
      headerName: "Subject(Duration)",
      flex: 1,
      valueGetter: (params) => `${params.row.duration} hours`,
    },
    { field: "fee", headerName: "Fee", flex: 1 },
    { field: "monthlyFee", headerName: "Monthly(fee)", flex: 1 },

    {
      field: "isActive",
      headerName: "Active",
      flex: 1,
      renderCell: (params) => (
        <div className="toggle-menu">
          <label className="switch">
            <input
              type="checkbox"
              checked={params.row.isActive}
              onChange={() => handleToggle(params.row.id)}
            />
            <span className="slider"></span>
          </label>
        </div>
      ),
    },

    {
      field: "edit",
      headerName: "Edit",
      flex: 1,
      renderCell: (params) => (
        <button onClick={() => handleEdit(params.row.id)} className="button">
          <svg
            className="svg-icon"
            fill="none"
            height="24"
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              stroke="#4D44B5"
              // strokeLinecap="round"
              // strokeWidth="2"
              className="stroke-color"
            >
              <path d="m20 20h-16"></path>
              <path
                clipRule="evenodd"
                d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                fillRule="evenodd"
              ></path>
            </g>
          </svg>
        </button>
      ),
    },
  ];

  const rows = data.map((course) => ({
    id: course._id,
    name: course.name,
    shortDescription: course.shortDescription,
    longDescription: course.longDescription,
    duration: course.courseDuration,
    courseDurationUnit: course.courseDurationUnit,
    fee: course.fee,
    monthlyFee: course.monthlyFee,
    isActive: course.isActive,
    rating: course.rating,
    subjects: course.subjects.map((subject) => subject.name).join(", "),
    subjectsduration: course.subjects
      .map((subject) => subject.duration)
      .join(", "),
    imageURL: course.imageURL,
  }));

  const changeHandler = (e, i) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      if (0 <= i) {
        return {
          ...prev,
          subjects: prev.subjects.map((item, index) =>
            index === i ? { ...item, [name]: value } : item
          ),
        };
      } else {
        if (name === "fees") {
          return {
            ...prev,
            fees: value === "" ? value.trim() : parseInt(value),
          };
        } else if (name === "courseDuration") {
          return {
            ...prev,
            courseDuration: value === "" ? value.trim() : parseInt(value),
          };
        }
        return {
          ...prev,
          [name]: value,
        };
      }
    });
  };

  const removeRowHandler = (i) => {
    setFormData((prev) => {
      return {
        ...prev,
        subjects: prev.subjects.filter((item, index) => index !== i),
      };
    });
  };

  const getSubject = async () => {
    try {
      const response = await getSubjectData(userToken.token);
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        setIsSubject(responseData.message);
      }
    } catch (Error) {}
  };

  const addRowHandler = () => {
    setFormData((prev) => {
      return {
        ...prev,
        subjects: [...prev.subjects, { _id: "", duration: "" }],
      };
    });
  };
  const handleDurationSelect = (eventKey, event) => {
    let unit = eventKey.toLowerCase();
    setSelectedDuration(unit);
    setIsInput((prev) => ({
      ...prev,
      courseDurationUnit: unit,
    }));
  };
  useEffect(() => {
    fetchAllData();
    getSubject();
  }, []);

  useEffect(() => {
    if (formData.courseDurationUnit) {
      setSelectedDuration(formData.courseDurationUnit);
    }
  }, [formData.courseDurationUnit]);
  const handleToggle = (id) => {};
  return (
    <Container>
      <Row>
        <Col>
          <div className="product mb-2">
            <SearchBar onClick={addCourseHandler} value={"course"} />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          {showModal ? (
            <Modal
              show={true}
              onHide={handleCloseModal}
              dialogClassName="custom-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Update Course</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <div className="row">
                    <div>
                      <Form.Group controlId="exampleForm.ControlInput1">
                        <Form.Label className="modal-label">Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter course name"
                          name="name"
                          className="modal-input"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <Form.Group controlId="exampleForm.ControlInput3">
                        <Form.Label className="modal-label">
                          Short Description
                        </Form.Label>
                        <textarea
                          value={formData.shortDescription}
                          className="form-control modal-inputCourseModal"
                          name="shortDescription"
                          onChange={handleChange}
                        ></textarea>
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <Form.Group controlId="exampleForm.ControlInput3">
                        <Form.Label className="modal-label ">
                          Long Description
                        </Form.Label>
                        <textarea
                          value={formData.longDescription}
                          className="form-control modal-inputCourseModal"
                          name="longDescription"
                          onChange={handleChange}
                        ></textarea>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <label className="modal-label">One-Time Fee</label>
                      <InputGroup className="mb-3 no-border ">
                        <InputGroup.Text
                          className="submit-save-btn rounded-left "
                          style={{
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          }}
                        >
                          £
                        </InputGroup.Text>
                        <input
                          id="oneTimeFee"
                          className="form-control no-border modal-label modal-inputCourseModal"
                          value={formData.fee}
                          aria-label="Amount (to the nearest dollar)"
                          name="fee"
                          onChange={(e) => changeHandler(e)}
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                        />
                      </InputGroup>
                    </div>
                    <div className="col-md-6">
                      <label className="modal-label">Monthly Fee</label>
                      <InputGroup className="mb-3 no-border">
                        <InputGroup.Text
                          className="submit-save-btn rounded-left"
                          style={{
                            borderTopRightRadius: "0px",
                            borderBottomRightRadius: "0px",
                          }}
                        >
                          £
                        </InputGroup.Text>
                        <input
                          id="monthlyFee"
                          className="form-control no-border modal-label modal-inputCourseModal"
                          value={formData.monthlyFee}
                          aria-label="Amount (to the nearest dollar)"
                          name="monthlyFee"
                          onChange={(e) => changeHandler(e)}
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="modal-label">Duration</label>
                      <InputGroup className=" modal-label ">
                        <Form.Control
                          aria-label="Text input with dropdown button"
                          value={formData.courseDuration}
                          name="courseDuration"
                          onChange={changeHandler}
                          className="modal-label modal-inputCourseModal"
                        />
                        <Dropdown onSelect={handleDurationSelect} className="">
                          <Dropdown.Toggle
                            variant="light"
                            id="dropdown-basic"
                            className="submit-save-btn rounded-right "
                            value={selectedDuration}
                            style={{
                              borderTopRightRadius: "5px",
                              borderBottomRightRadius: "5px",
                            }}
                          >
                            {selectedDuration}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              eventKey="months"
                              onSelect={handleDurationSelect}
                            >
                              Months
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="weeks"
                              onSelect={handleDurationSelect}
                            >
                              Weeks
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="days"
                              onSelect={handleDurationSelect}
                            >
                              Days
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </InputGroup>
                    </div>
                    <div className="col-md-6">
                      <label className="modal-label mt-2">Status</label>
                      <Form.Group className="d-flex  gap-4 align-items-center mt-2">
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={formData.isActive}
                            onChange={(e) => {
                              console.log("Switch value:", e.target.checked);
                              handleChange(e);
                            }}
                          />
                          <span className="slider"></span>
                        </label>
                      </Form.Group>
                    </div>
                  </div>
                  {formData.subjects.map((item, index) => (
                    <div key={index} className="row">
                      <div className="d-flex flex-row align-items-end gap-2 mt-5">
                        <div style={{ position: "relative", flex: 1 }}>
                          <CircleMinus
                            className="minus-icon-remove"
                            onClick={() => removeRowHandler(index)}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <label className="modal-label">Subject</label>
                          <Form.Select
                            className="form-control select-block modal-inputCourseModal"
                            value={item._id}
                            name="_id"
                            onChange={(e) => changeHandler(e, index)}
                          >
                            <option value="" className="modal-label">
                              Select
                            </option>
                            {isSubject?.map((subject, subIndex) => (
                              <option key={subIndex} value={subject._id}>
                                {subject.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group>
                          <label className="modal-label">
                            Duration in Hours
                          </label>
                          <input
                            className="form-control select-block modal-inputCourseModal"
                            name="duration"
                            value={item.duration}
                            onChange={(e) => changeHandler(e, index)}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  ))}

                  <div className="d-flex flex-row justify-content-end mt-4">
                    <BasicButton
                      value={"+ Add Subject"}
                      baseStyle="profile-save"
                      onClick={addRowHandler}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer className="modal-footer-modal border">
                <input
                  type="submit"
                  className="submit-save-btn"
                  value={"Save"}
                  onClick={updateCourseHandler}
                />
              </Modal.Footer>
            </Modal>
          ) : (
            <AdvanceTable
              rows={rows}
              columns={columns}
              isLoading={isLoading}
              onEdit={handleEdit}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default CourseList;
