import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import { getClass } from "../../../../../Service/BaseService/BaseService";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import { useNavigate } from "react-router-dom";
import { tutorAssigned } from "../../../../../Service/BaseService/BaseService";
import VisibilityIcon from "@mui/icons-material/Visibility";

function ClassRoom() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPage, setIsPage] = useState(1);
  const [isCount, setIsCount] = useState(10);
  const [isResponseData, setIsResponseData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchAllData();

    // const handleClick = (event) => {
    //   if (actionBtn.current && !actionBtn.current.contains(event.target)) {
    //     btnClick(null);
    //   }
    // };

    // document.addEventListener("click", handleClick);
    // return () => {
    //   document.removeEventListener("click", handleClick);
    // };
  }, []);

  const fetchAllData = async (page = 1, limit = 10) => {
    setIsLoading(true);
    try {
      console.log(userToken);
      const response = await tutorAssigned(userToken.token);
      const responseData = await response.json();
      if (response.ok && responseData.message.length > 0) {
        setIsResponseData(responseData.message);
        const prioritizedColumns = [
          {
            field: "title",
            headerName: "Batch",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{params.row.title}</p>
              </div>
            ),
          },
          {
            field: "description",
            headerName: "Description",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{params.row.description}</p>
              </div>
            ),
          },
          {
            field: "course",
            headerName: "Course",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{params.row.course?.name}</p>
              </div>
            ),
          },
          {
            field: "assignments",
            headerName: "Assignments",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{params.row?.assignments ?? 0}</p>
              </div>
            ),
          },
          {
            field: "startDate",
            headerName: "Start Date",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">
                  {new Date(params.row.startDate).toLocaleDateString()}
                </p>
              </div>
            ),
          },
          {
            field: "endDate",
            headerName: "End Date",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">
                  {new Date(params.row.endDate).toLocaleDateString()}
                </p>
              </div>
            ),
          },
        ];
        const headers = Object.keys(responseData.message[0]);
        console.log(headers);
        const prioritizedFields = prioritizedColumns.map((col) => col.field);
        const dynamicColumns = headers
          .filter((header) => !prioritizedFields.includes(header))
          .map((header) => ({
            field: header,
            headerName: header.charAt(0).toUpperCase() + header.slice(1),
            flex: 1,
            width: 180,
          }));

        const columns = [...prioritizedColumns, ...dynamicColumns];

        columns.push({
          field: "additionalFieldUpdate",
          headerName: "View",
          flex: 1,
          width: 100,
          renderCell: (params) => {
            return (
              <div
                class="button visibility-icon"
                onClick={() => navigate(`/batch-details?id=${params.row._id}`)}
              >
                <VisibilityIcon />
              </div>
            );
          },
        });

        const rows = responseData.message.map((dataItem) => ({
          id: dataItem._id,
          ...dataItem,
          assignments: dataItem.totalBatchAssignments
        }));

        setColumnDefs(
          columns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "createdBy",
                "parentId",
                "createdAt",
                "updatedAt",
                "condition",
                "isActive",
                "totalBatchAssignments"
              ].includes(item.field)
          )
        );
        setRowData(rows);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="product">
            <SearchBar value={"assignment"} hide={true} />
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <AdvanceTable rows={rowData} columns={columnDefs} />
        </Col>
      </Row>
    </Container>
  );
}

export default ClassRoom;
