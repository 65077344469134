import React, { useState } from "react";
import { CircleMinus, CircleChevronDown } from "lucide-react";
import { FaCamera } from "react-icons/fa";
import profile from "../../Assests/icon/profile-picture.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs from "dayjs";
import { FaAngleDown } from "react-icons/fa6";

function CardBlock(props) {
  const { form, removeRowHandler, changeValueHandler, classData, courseData } =
    props;
  const data = form?.map((item, index) => {
    return (
      <div className="registration-block">
        <div className="d-flex align-items-center justify-content-between register-box">
          <h5>Student Details</h5>
          <div className="minus-block d-flex gap-2 flex-row-reverse">
            {index !== 0 && (
              <CircleMinus
                className="minus-icon"
                onClick={() => removeRowHandler(index)}
              />
            )}
          </div>
        </div>
        <div className="formCollapse">
          <div className="profile-parent-card">
            <div>
              <img
                alt="profile"
                src={item.previewImage ? item.previewImage : profile}
                style={{ width: "100px", height: "100px" }}
              />
              <div className="camera-icon-block">
                <label>
                  <input
                    // type="file"
                    className="hide-input modal-inputCourseModal"
                    // onChange={props.uploadImageHandler}
                    type="file"
                    name="studentProfilePictures"
                    onChange={(e) => changeValueHandler(e, index)}
                  />
                  <span>
                    <FaCamera className="camera-icon" />
                  </span>
                </label>
              </div>
            </div>
            <div className="d-flex flex-column align-items-center gap-2">
              <p>Name</p>
              <p className="student-name">{item.firstName}</p>
            </div>
            {/* <span>{`D.O.B : ${item.dateOfBirth}`}</span> */}
          </div>
          <div>
            <div className="registration-setup">
              <div>
                <label>Name</label>
                <input
                  className="form-control modal-inputCourseModal"
                  name="firstName"
                  value={item.firstName}
                  required
                  onChange={(e) => changeValueHandler(e, index)}
                />
              </div>
              <div>
                <label>Surname</label>
                <input
                  className="form-control modal-inputCourseModal"
                  name="lastName"
                  value={item.lastName}
                  required
                  onChange={(e) => changeValueHandler(e, index)}
                />
              </div>
            </div>
            <div className="registration-setup">
              <div style={{ position: "relative" }}>
                <label>Gender</label>
                <select
                  className="form-control select-block modal-inputCourseModal"
                  name="gender"
                  value={item.gender}
                  required
                  onChange={(e) => changeValueHandler(e, index)}
                >
                  <option>select</option>
                  <option>Male</option>
                  <option>Female</option>
                  <option>Others</option>
                </select>
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "25px",
                    display: "inline-block",
                  }}
                >
                  <FaAngleDown />
                </div>
              </div>

              <div>
                <label>Date of Birth</label>
                <input
                  className="form-control modal-inputCourseModal"
                  type="date"
                  name="dateOfBirth"
                  value={item.dateOfBirth}
                  required
                  onChange={(e) => changeValueHandler(e, index)}
                />
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    className="date-picker-box"
                    defaultValue={dayjs("2022-04-17")}
                    // label="Basic date picker"
                    // // name="dateofBirth"
                    // className="form-control"
                    onChange={(date) => handleDateChange(date, index)}
                  />
                </LocalizationProvider> */}
              </div>
            </div>
            <div className="registration-setup">
              <div>
                <label>Academic Session</label>
                <input
                  disabled
                  name="academic"
                  className="form-control modal-inputCourseModal"
                  value={item.academic}
                  required
                  onChange={(e) => changeValueHandler(e, index)}
                />
                {/* <select
                  className="form-control select-block"
                  name="academic"
                  value={item.academic}
                  required
                  onChange={(e) => changeValueHandler(e, index)}
                >
                  <option>Year 3</option>
                  <option>Year 4</option>
                  <option>Year 5</option>
                </select> */}
              </div>
              <div>
                <label>Current School</label>
                <input
                  name="currentSchool"
                  required
                  value={item.currentSchool}
                  className="form-control modal-inputCourseModal"
                  onChange={(e) => changeValueHandler(e, index)}
                />
              </div>
            </div>
            <div className="registration-setup">
              <div className="d-flex flex-row gap-2">
                <div style={{ position: "relative", flex: 1 }}>
                  <label>School year</label>
                  <select
                    className="form-control select-block modal-inputCourseModal"
                    name="schoolYear"
                    value={item.schoolYear}
                    required
                    onChange={(e) => changeValueHandler(e, index)}
                  >
                    <option>select</option>
                    <option>Year 3</option>
                    <option>Year 4</option>
                    <option>Year 5</option>
                  </select>
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "25px",
                      display: "inline-block",
                    }}
                  >
                    <FaAngleDown />
                  </div>
                </div>
                <div style={{ position: "relative", flex: 1 }}>
                  <label>Courses</label>
                  <select
                    className="form-control select-block modal-inputCourseModal"
                    name="courses"
                    required
                    value={item.courses}
                    onChange={(e) => changeValueHandler(e, index)}
                  >
                    <option>select</option>
                    {courseData?.map((item, index) => {
                      return (
                        <option value={item._id} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "30px",
                      display: "inline-block",
                    }}
                  >
                    <FaAngleDown />
                  </div>
                </div>
                <div style={{ position: "relative", flex: 1 }}>
                  <label>Batch</label>
                  <select
                    className="form-control select-block modal-inputCourseModal"
                    name="class"
                    required
                    value={item.class}
                    onChange={(e) => changeValueHandler(e, index)}
                  >
                    <option>select</option>
                    {classData?.map((item, index) => {
                      return (
                        <option value={item._id} key={index}>
                          {item.title}
                        </option>
                      );
                    })}
                  </select>
                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "30px",
                      display: "inline-block",
                    }}
                  >
                    <FaAngleDown />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-start px-3 gap-1 confirmation-check mt-3 mb-4">
              <span >Gurukul Academic Student</span>
              <div className="d-flex mt-2 align-items-center" style={{ margin: "1px", height: "2px" }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    name="studentcheck"
                    className="ui-checkbox modal-inputCourseModal "
                    onChange={(e) => changeValueHandler(e, index)}
                    checked={item.isGurukulStudent}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="save-border"></div>
      </div>
    );
  });

  return <div>{data}</div>;
}

export default CardBlock;
