import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import "./Profile.css";
import BasicButton from "../../../../Components/Button/Button";
import profile from "../../../../Assests/icon/profile-picture.png";
import { FaCamera } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { updateStudent, getCourse, getParents, studentRegister } from "../../../../Service/BaseService/BaseService";
import { successAlert } from "../../../../Components/Popup/Basicpopup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Profile() {
    const user = localStorage.getItem("User");
    const userToken = JSON.parse(user);
    const location = useLocation();
    const getParams = new URLSearchParams(location.search);
    const record = getParams.get("record");
    const getId = getParams.get("id");
    const [isVisible, setIsVisible] = useState(false);
    const { row } = location.state || {};
    const [isCourseData, setIsCourseData] = useState([]);
    const [isParents, setIsParents] = useState([]);
    const navigate = useNavigate();

    const formatDate = (date) => {
        if (!date) return "";
        const d = new Date(date);
        const month = `0${d.getMonth() + 1}`.slice(-2);
        const day = `0${d.getDate()}`.slice(-2);
        return `${d.getFullYear()}-${month}-${day}`;
    };

    useEffect(() => {
        fetchCourses();
        fetchParents();
        if (record) {
            const dashboardTextElements = document.getElementsByClassName('dashboard-text');
            for (let element of dashboardTextElements) {
                element.innerHTML = `<span class="assignment-title">Add Student</span>`;
            }
        }
    }, []);

    const form = {
        firstName: row?.firstName ?? "",
        lastName: row?.lastName ?? "",
        dateOfBirth: formatDate(row?.dateOfBirth) ?? "",
        gender: row?.gender ?? "",
        schoolYear: row?.schoolYear ?? "",
        courses: row?.courses.map(course => course._id).join(", ") || "",
        academic: row?.academic ?? "2024-2025",
        currentSchool: row?.currentSchool ?? "",
        imageURL: row?.imageURL ?? "",
        parent: row?.parent.firstName ?? "",
        isGurukulStudent: row?.isGurukulStudent
    };

    const [isInput, setIsInput] = useState(form);
    const handleChange = (e) => {
        setIsInput((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setIsInput((prev) => ({
            ...prev,
            profilePicture: file,
            imageURL: URL.createObjectURL(file)
        }));
    };

    const fetchCourses = async () => {
        try {
            const response = await getCourse();
            const responseData = await response.json();
            if (response.ok) {
                const sortedCourses = responseData.message.sort((a, b) => {
                    const aSelected = row?.courses.find(course => course._id === a._id);
                    const bSelected = row?.courses.find(course => course._id === b._id);
                    return bSelected - aSelected;
                });
                setIsCourseData(sortedCourses);
            }
        } catch (err) {
            console.error("Error fetching courses:", err);
        }
    };

    const fetchParents = async () => {
        try {
            const response = await getParents(userToken.token);
            const responseData = await response.json();
            if (response.ok) {
                setIsParents(responseData.message);
            }
        } catch (err) {
            console.error("Error fetching courses:", err);
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        try {

            const formData = new FormData();
            formData.append("firstName", isInput.firstName);
            formData.append("lastName", isInput.lastName);
            formData.append("dateOfBirth", isInput.dateOfBirth);
            formData.append("gender", isInput.gender);
            formData.append("schoolYear", isInput.schoolYear);
            formData.append("courses", isInput.courses);
            formData.append("academic", isInput.academic);
            formData.append("currentSchool", isInput.currentSchool);
            formData.append("isGurukulStudent", isInput.isGurukulStudent);
            if (isInput.profilePicture) {
                formData.append("profilePicture", isInput.profilePicture);
            }
            if (isInput.parent && record) {
                formData.append("parent", isInput.parent);
            }

            const response = record === 'student'
                ? await studentRegister(formData, userToken.token)
                : await updateStudent(formData, userToken.token, getId);
            const responseData = await response.json();
            if (response.ok) {
                successAlert(responseData.message);
                navigate("/student");
            }
        } catch (err) {
            console.error("Error updating student:", err);
        }
    };

    return (
        <Container>
            <Row>
                <Col md={12}>
                    <div className="registration-block">
                        <form onSubmit={submitHandler}>
                            <div className="formCollapse">
                                <div className="profile-parent-card">
                                    <div>
                                        <img
                                            alt="profile"
                                            src={isInput.imageURL ? isInput.imageURL : profile}
                                            style={{ width: "100px", height: "100px" }}
                                        />
                                        <div className="camera-icon-block">
                                            <label>
                                                <input
                                                    className="hide-input modal-inputCourseModal"
                                                    type="file"
                                                    name="studentProfilePictures"
                                                    onChange={handleFileChange}
                                                />
                                                <span>
                                                    <FaCamera className="camera-icon" />
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-center gap-2">
                                        <p>First Name</p>
                                        <p className="student-name">{isInput.firstName}</p>
                                    </div>
                                </div>
                                <div>
                                    <div className="registration-setup">
                                        {record && (
                                            <div style={{ position: "relative", flex: 1 }}>
                                                <label>Parent</label>
                                                <select
                                                    className="form-control select-block"
                                                    name="parent"
                                                    onChange={handleChange}
                                                    value={isInput.parent || ""}
                                                >
                                                    <option value="">Select</option>
                                                    {isParents.map((parent) => (
                                                        <option key={parent._id} value={parent._id}>
                                                            {parent.firstName}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        top: "26px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    <FaAngleDown />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="registration-setup">
                                        <div>
                                            <label>Name</label>
                                            <input
                                                className="form-control modal-inputCourseModal"
                                                name="firstName"
                                                value={isInput.firstName}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <label>Last Name</label>
                                            <input
                                                className="form-control modal-inputCourseModal"
                                                name="lastName"
                                                value={isInput.lastName}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="registration-setup">
                                        <div style={{ position: "relative" }}>
                                            <label>Gender</label>
                                            <select
                                                className="form-control select-block modal-inputCourseModal"
                                                name="gender"
                                                value={isInput.gender}
                                                required
                                                onChange={handleChange}
                                            >
                                                <option>select</option>
                                                <option>Male</option>
                                                <option>Female</option>
                                                <option>Others</option>
                                            </select>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: "10px",
                                                    top: "25px",
                                                    display: "inline-block",
                                                }}
                                            >
                                                <FaAngleDown />
                                            </div>
                                        </div>

                                        <div>
                                            <label>Date of Birth</label>
                                            <input
                                                className="form-control modal-inputCourseModal"
                                                type="date"
                                                name="dateOfBirth"
                                                value={isInput.dateOfBirth}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="registration-setup">
                                        <div>
                                            <label>Academic Session</label>
                                            <input
                                                disabled
                                                name="academic"
                                                className="form-control modal-inputCourseModal"
                                                value={isInput.academic}
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div>
                                            <label>Current School</label>
                                            <input
                                                name="currentSchool"
                                                required
                                                value={isInput.currentSchool}
                                                className="form-control modal-inputCourseModal"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="registration-setup">
                                        <div className="d-flex flex-row gap-2">
                                            <div style={{ position: "relative", flex: 1 }}>
                                                <label>School year</label>
                                                <select
                                                    className="form-control select-block modal-inputCourseModal"
                                                    name="schoolYear"
                                                    value={isInput.schoolYear}
                                                    required
                                                    onChange={handleChange}
                                                >
                                                    <option>select</option>
                                                    <option>Year 3</option>
                                                    <option>Year 4</option>
                                                    <option>Year 5</option>
                                                </select>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        top: "25px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    <FaAngleDown />
                                                </div>
                                            </div>
                                            <div style={{ position: "relative", flex: 1 }}>
                                                <label>Courses</label>
                                                <select
                                                    className="form-control select-block"
                                                    name="courses"
                                                    onChange={handleChange}
                                                    value={isInput.courses || ""}
                                                >
                                                    <option value="">Select</option>
                                                    {isCourseData.map((course) => (
                                                        <option key={course._id} value={course._id}>
                                                            {course.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        right: "10px",
                                                        top: "30px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    <FaAngleDown />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-start px-3 gap-1 confirmation-check mt-3 mb-4">
                                        <span >Gurukul Academic Student</span>
                                        <div className="d-flex mt-2 align-items-center" style={{ margin: "1px", height: "2px" }}>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    name="isGurukulStudent"
                                                    className="form-switch active-toggle"
                                                    onChange={(e) => setIsInput((prev) => ({
                                                        ...prev,
                                                        isGurukulStudent: e.target.checked,
                                                    }))}
                                                    checked={isInput.isGurukulStudent}
                                                />
                                                <span className="slider"></span>
                                            </label>
                                        </div>
                                        {/* <input
                                            type="checkbox"
                                            name="isGurukulStudent"
                                            className="form-switch active-toggle"
                                            onChange={(e) => setIsInput((prev) => ({
                                                ...prev,
                                                isGurukulStudent: e.target.checked,
                                            }))}
                                            checked={isInput.isGurukulStudent}
                                        />
                                        <span >Gurukul Academic Student</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className="save-border d-flex justify-content-end">
                                <input
                                    type="submit"
                                    className="submit-save-btn custom-margin"
                                    value={record === "student" ? "Submit" : "Update"}
                                />
                            </div>
                        </form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Profile;

