import React, { useCallback, useState, useEffect, useRef } from "react";
import {
  assignmentView,
  reviewedAssignment,
  pdfMetaData,
  getPdfMeta,
} from "../../Service/BaseService/BaseService";
import Loader from "../../Components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFont,
  faHighlighter,
  faEraser,
  faShapes,
  faCrop,
  faChevronDown,
  faStrikethrough,
  faFileExport,
  faSpinner,
  faPen,
  faCircle,
  faArrowRotateLeft,
  faArrowRotateRight
} from "@fortawesome/free-solid-svg-icons";
import * as pdfjsLib from "pdfjs-dist/webpack";
import "pdfjs-dist/web/pdf_viewer.css";
import * as pdfAnnotate from "annotpdf/_bundles/pdfAnnotate";

import Snackbar, {
  notifyError,
  notifySucess,
} from "../../Components/Snackbar/Snackbar";
import "./AnnotPdf.css";
import InputModal from "../../Components/Modal/InputModal";
import AnnotationIcon from "../../Assests/note.png";
import Annotationpen from "../../Assests/annotatepen.png";
import { v4 as uuidv4 } from "uuid";
import { CircularProgress } from "@mui/material";
import { PDFDocument, rgb, grayscale, StandardFonts } from 'pdf-lib';
import * as fontkit from 'fontkit';
import { saveAs } from 'file-saver';
import inkfreeFontUrl from '../../Fonts/Inkfree.ttf';
// import { Undo } from "lucide-react";


const PdfContainer = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfMeta, setPdfMeta] = useState(null);
  const [pdfFactory, setPdfFactory] = useState(null);
  const [pdfViewer, setPdfViewer] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [SelectionCoOrdinates, setSelectionCoordinates] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [annotationType, setAnnotationType] = useState(null);
  const [selection, setSelection] = useState(null);
  const [content, setContent] = useState("");
  const [renderedAnnotations, setRenderedAnnotations] = useState({});
  const [pdfRendered, setPdfRendered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [modalAction, setModalAction] = useState("add");
  const [activeTool, setActiveTool] = useState(null);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [customTitle, setCustomTitle] = useState("");
  const [startCoordinates, setStartCoordinates] = useState([0, 0]);
  const [endCoordinates, setEndCoordinates] = useState([0, 0]);
  const [currentAnnotationElement, setCurrentAnnotationElement] = useState(null);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const location = useLocation();

  const userDetails = JSON.parse(localStorage.getItem("User"));
  const accessToken = userDetails?.token;
  const params = new URLSearchParams(location.search);
  const getId = params.get("id");

  const { name, title } = location.state || {};

  const assignmentViewer = useCallback(
    async (id) => {
      try {
        const status = userDetails.role === "tutor" ? "in-review" : "";
        const response = await assignmentView(accessToken, id, status);
        if (response.ok) {
          const responseData = await response.json();
          setPdfUrl(responseData.message);
        } else {
          console.error("Failed to load assignment:", response.statusText);
        }
      } catch (err) {
        console.error("Failed to load assignment:", err);
      }
    },
    [accessToken, userDetails.role]
  );

  const getMetaData = async (id) => {
    const response = await getPdfMeta(accessToken, id);
    if (response.ok) {
      const responseData = await response.json();
      setPdfMeta(responseData.message);
      setAnnotations(responseData.message.meta);
    } else {
      console.error("Failed to load Meta:", response.statusText);
    }
  };

  useEffect(() => {
    if (location.pathname === "/assignment-editor") {
      setCustomTitle(
        `<span class="assignment-title">${title}</span> <span class="submitted-by">submitted by ${name}</span>`
      );
    } else {
      setCustomTitle("");
    }

    if (getId) {
      assignmentViewer(getId);
      getMetaData(getId);
    }
  }, [getId, assignmentViewer]);

  useEffect(() => {
    if (customTitle) {
      const dashboardTextElements =
        document.getElementsByClassName("dashboard-text");
      for (let element of dashboardTextElements) {
        element.innerHTML = customTitle;
      }
    }
  }, [customTitle]);

  useEffect(() => {
    if (pdfRendered) {
      renderSavedAnnotations(annotations);
    }
  }, [pdfRendered]);


  useEffect(() => {
    const initializePdfViewer = async () => {
      if (pdfUrl && !pdfViewer) {
        const pdfContainer = document.getElementById("viewerContainer");
        const pdfjsdist = window.pdfjsViewer;
        const newPdfViewer = new pdfjsdist.PDFViewer({
          container: pdfContainer,
        });

        let loadingTask = pdfjsLib.getDocument({ url: pdfUrl });
        loadingTask.promise.then((pdfDocument) => {
          pdfDocument.getData().then((data) => {
            const pdffactory = new pdfAnnotate.AnnotationFactory(data);
            setPdfFactory(pdffactory);
          });
          newPdfViewer.setDocument(pdfDocument);
          setTimeout(() => {
            newPdfViewer.currentScaleValue = "page-width";
            setPdfRendered(true);
          }, 1500);
        });

        setPdfViewer(newPdfViewer);
      }
    };

    initializePdfViewer();

    return () => {
      if (pdfViewer) {
        pdfViewer.cleanup();
      }
    };
  }, [pdfUrl, pdfViewer]);

  const handleMouseDown = (evt) => {
    if (pdfViewer) {
      const offset = computePageOffset();
      let x = evt.pageX - offset.left;
      let y = evt.pageY - offset.top;
      const currentPage = pdfViewer._pages[pdfViewer.currentPageNumber - 1];
      const x_y = currentPage.viewport.convertToPdfPoint(x, y);
      setStartCoordinates([x_y[0], x_y[1]]);
    }
  };

  const handleMouseMove = (evt) => {
    if (pdfViewer && startCoordinates && pdfRendered) {
      const offset = computePageOffset();
      let x = evt.pageX - offset.left;
      let y = evt.pageY - offset.top;
      const currentPage = pdfViewer._pages[pdfViewer.currentPageNumber - 1];
      if (currentPage) {
        const x_y = currentPage.viewport.convertToPdfPoint(x, y);
        setEndCoordinates([x_y[0], x_y[1]]);

        // Update annotation dimensions while moving the mouse
        updateAnnotationOnMove(x_y[0], x_y[1]);
      }
    }
  };

  const handleMouseUp = () => {
    // Complete the annotation creation when mouse is released
    if (startCoordinates && endCoordinates) {
      finalizeAnnotation();
    }
  };

  useEffect(() => {
    if (startCoordinates && (annotationType === "/Strikethrough" || annotationType === "/Circle" || annotationType === "/Highlight")) {
      renderOnMoveAnnotations(); // Call only after startCoordinates is updated
    }
  }, [startCoordinates]);

  useEffect(() => {
    if (pdfViewer) {
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [startCoordinates, endCoordinates]);

  const renderOnMoveAnnotations = () => {
    const viewer = document.getElementById("viewer");
    const page = pdfViewer._pages[pdfViewer.currentPageNumber - 1];

    const annotationId = generateRandomId();
    const viewport = page.viewport;
    const [x1, y1] = startCoordinates;

    if (annotationType === "/Strikethrough" && startCoordinates && page) {
      // Create the strikethrough annotation div
      const strikethroughDiv = document.createElement("div");
      strikethroughDiv.setAttribute("data-annotation-id", annotationId);
      strikethroughDiv.style.position = "absolute";
      strikethroughDiv.style.borderBottom = "2px solid red";

      const [left, top] = viewport.convertToViewportPoint(x1, y1);
      strikethroughDiv.style.left = `${left}px`;
      strikethroughDiv.style.top = `${top}px`;
      strikethroughDiv.style.width = `0px`;

      addDeleteIcon(strikethroughDiv, annotationId);

      const pageContainer = viewer.querySelector(`.page[data-page-number="${pdfViewer.currentPageNumber}"]`);
      if (pageContainer) {
        pageContainer.appendChild(strikethroughDiv);
        setCurrentAnnotationElement(strikethroughDiv);
      }
    }
    else if (annotationType == "/Highlight" && startCoordinates && page) {

      const [x2, y2] = endCoordinates || [x1, y1];

      const strikethroughDiv = document.createElement("div");
      strikethroughDiv.setAttribute(
        "data-annotation-id",
        annotationId
      );
      strikethroughDiv.style.position = "absolute";
      strikethroughDiv.style.backgroundColor = "rgba(255, 255, 0, 0.45)";
      strikethroughDiv.style.height = "30px";

      const [left, top] = viewport.convertToViewportPoint(x1, y1);
      const [right, bottom] = viewport.convertToViewportPoint(x2, y2);

      const width = right - left;
      const midpointY = (top + bottom) / 2;

      strikethroughDiv.style.left = `${left}px`;
      strikethroughDiv.style.top = `${midpointY - 15}px`;
      strikethroughDiv.style.width = `${width}px`;

      addDeleteIcon(strikethroughDiv, annotationId);

      const pageContainer = viewer.querySelector(`.page[data-page-number="${pdfViewer.currentPageNumber}"]`);
      if (pageContainer) {
        pageContainer.appendChild(strikethroughDiv);
        setCurrentAnnotationElement(strikethroughDiv);
      }
    }
    else if (annotationType == "/Circle" && startCoordinates && page) {

      const [x2, y2] = endCoordinates || [x1, y1];

      const [vx1, vy1] = viewport.convertToViewportPoint(x1, y1);
      const [vx2, vy2] = viewport.convertToViewportPoint(x2, y2);

      const centerX = (vx1 + vx2) / 2;
      const centerY = (vy1 + vy2) / 2;
      const xScale = Math.abs(vx2 - vx1) / 2;
      const yScale = Math.abs(vy2 - vy1) / 2 + 15;


      // const xScale = Math.abs(x2 - x1) / 2;
      // const yScale = Math.abs(y2 - y1) / 2 + 15;

      const ellipseDiv = document.createElement("div");
      ellipseDiv.setAttribute("data-annotation-id", annotationId);
      ellipseDiv.style.position = "absolute";
      ellipseDiv.style.border = "2px solid red";
      ellipseDiv.style.borderRadius = "50%";
      ellipseDiv.style.backgroundColor = "transparent";

      ellipseDiv.style.left = `${centerX - xScale}px`;
      ellipseDiv.style.top = `${centerY - yScale}px`;
      ellipseDiv.style.width = `${xScale * 2}px`;
      ellipseDiv.style.height = `${yScale * 2}px`;

      addDeleteIcon(ellipseDiv, annotationId);

      const pageContainer = viewer.querySelector(`.page[data-page-number="${pdfViewer.currentPageNumber}"]`);
      if (pageContainer) {
        pageContainer.appendChild(ellipseDiv);
        setCurrentAnnotationElement(ellipseDiv);
      }
    }
  };

  const addDeleteIcon = (annotationDiv, annotationId) => {
    const deleteIcon = document.createElement("i");
    deleteIcon.className = "bi bi-trash";
    deleteIcon.style.position = "absolute";
    deleteIcon.style.right = "10px";
    deleteIcon.style.top = "5px";
    deleteIcon.style.cursor = "pointer";
    deleteIcon.style.fontSize = "27px";
    deleteIcon.style.color = "red";
    deleteIcon.style.display = "none";

    deleteIcon.addEventListener("click", (e) => {
      e.stopPropagation();
      handleDeleteAnnotation(annotationId);
    });

    annotationDiv.appendChild(deleteIcon);

    annotationDiv.addEventListener("click", (e) => {
      e.stopPropagation();
      deleteIcon.style.display = "block";
    });

    document.addEventListener("click", () => {
      deleteIcon.style.display = "none";
    });
  };

  const updateAnnotationOnMove = (x2, y2) => {
    if (currentAnnotationElement && startCoordinates) {
      const page = pdfViewer._pages[pdfViewer.currentPageNumber - 1];
      const viewport = page.viewport;

      const [x1, y1] = startCoordinates;
      const [right, bottom] = viewport.convertToViewportPoint(x2, y2);

      const width = right - parseFloat(currentAnnotationElement.style.left);
      const height = bottom - parseFloat(currentAnnotationElement.style.top);

      currentAnnotationElement.style.width = `${width}px`;

    }
  };

  const addAnnotation = () => {
    let newAnnotation;
    const pageIndex = pdfViewer.currentPageNumber - 1;

    const annotationId = currentAnnotationElement.getAttribute('data-annotation-id');

    if (annotationType === "/Highlight") {
      newAnnotation = {
        annotationId: annotationId,
        type: "/Highlight",
        page: pageIndex,
        coordinates: [
          startCoordinates[0],
          startCoordinates[1],
          endCoordinates[0],
          endCoordinates[1]
        ],
        content: "",
        author: "",
      };
    } else if (annotationType === "/Strikethrough") {
      newAnnotation = {
        annotationId: annotationId,
        type: "/Strikethrough",
        page: pageIndex,
        coordinates: [
          startCoordinates[0],
          startCoordinates[1],
          endCoordinates[0],
          endCoordinates[1]
        ],
        content: "",
        author: "",
      };
    }
    else if (annotationType === "/Circle") {
      newAnnotation = {
        annotationId: annotationId,
        type: "/Circle",
        page: pageIndex,
        coordinates: [
          startCoordinates[0],
          startCoordinates[1],
          endCoordinates[0],
          endCoordinates[1]
        ],
        content: "",
        author: "",
      };
    }

    setUndoStack([...undoStack, newAnnotation]);
    setAnnotations((prevAnnotations) => {
      const updatedAnnotations = [...prevAnnotations];

      // Ensure no null entries in the annotations array
      for (let i = 0; i <= pageIndex; i++) {
        if (!updatedAnnotations[i]) {
          updatedAnnotations[i] = [];
        }
      }

      updatedAnnotations[pageIndex] = [
        ...updatedAnnotations[pageIndex],
        newAnnotation,
      ]; // Ensure immutability
      return updatedAnnotations;
    });
    setRedoStack([]);

  }

  const finalizeAnnotation = () => {
    // Finalize the annotation, e.g., store it or make any adjustments necessary
    if (currentAnnotationElement) {

      addAnnotation();

      setCurrentAnnotationElement(null);
      setStartCoordinates(null);
      setEndCoordinates(null);
    }
  };

  const undo = () => {
    // Check if the undo stack has elements to undo
    if (undoStack.length === 0) return;

    // Get the last state from the undo stack
    const lastState = undoStack[undoStack.length - 1];
    const { annotationId, page, type } = lastState;

    // Remove the annotation from the annotations state
    setAnnotations((prevAnnotations) => {
      const newAnnotations = [...prevAnnotations];

      // Ensure page-specific annotations exist and filter out the one being undone
      if (newAnnotations[page]) {
        newAnnotations[page] = newAnnotations[page].filter(
          (annotation) => annotation.annotationId !== annotationId
        );
      }

      return newAnnotations;
    });

    // Handle deletion of the corresponding DOM element
    if (type === "/Strikethrough" || type === "/Highlight" || type === "/Circle" || type === "/FreeText") {
      handleDeleteAnnotation(annotationId);  // This should handle the DOM removal
    }

    // Add the last state to the redo stack
    setRedoStack((prevRedoStack) => [...prevRedoStack, lastState]);

    // Remove the last state from the undo stack
    setUndoStack((prevUndoStack) => prevUndoStack.slice(0, -1));
  };


  const redo = () => {
    if (redoStack.length === 0) return; // Check if there's anything to redo

    // Get the last state from the redo stack
    const lastState = redoStack[redoStack.length - 1];
    const { annotationId, page, type } = lastState;

    // Restore the annotation in the annotations state
    setAnnotations((prevAnnotations) => {
      const newAnnotations = [...prevAnnotations];

      // Ensure page-specific annotations exist and re-add the annotation
      if (!newAnnotations[page]) {
        newAnnotations[page] = [];
      }
      newAnnotations[page] = [...newAnnotations[page], lastState];

      return newAnnotations;
    });

    // Handle rendering of the annotation in the DOM
    if (type === "/Strikethrough" || type === "/Highlight" || type === "/Circle") {
      renderSavedAnnotations([[lastState]]);
    }

    setUndoStack((prevUndoStack) => [...prevUndoStack, lastState]);

    setRedoStack((prevRedoStack) => prevRedoStack.slice(0, -1));
  };

  const handlePageClick = (evt) => {
    if (pdfViewer) {
      const offset = computePageOffset();
      let x = evt.pageX - offset.left;
      let y = evt.pageY - offset.top;
      const currentPage = pdfViewer._pages[pdfViewer.currentPageNumber - 1];
      const x_y = currentPage.viewport.convertToPdfPoint(x, y);
      x = x_y[0];
      y = x_y[1];
      setCoordinates([x, y]);
      const selected = window.getSelection();
      if (selected.rangeCount !== 0) {
        setSelection(selected);
        setSelectionCoordinates(selectionCoordinates());
      }
    }
  };

  const computePageOffset = () => {
    const pageId = "page" + pdfViewer.currentPageNumber;
    const pg = document.getElementById(pageId);
    const rect = pg.getBoundingClientRect();
    const bodyElt = document.body;
    return {
      top: rect.top + bodyElt.scrollTop,
      left: rect.left + bodyElt.scrollLeft,
    };
  };

  const back = () => {
    window.history.back();
  };

  const submit = async () => {
    setIsLoading(true);
    try {
      const response = await pdfMetaData(accessToken, getId, annotations);
      if (response.ok) {
        const responseData = await response.json();
        notifySucess("Assignment reviewed to draft!");
      } else {
        console.error("Failed to Save Meta:", response.statusText);
        notifyError("Failed to save metadata. Please try again.");
      }
    } catch (err) {
      console.error("Error:", err);
      notifyError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const reviewed = async () => {
    setIsLoading(true);
    try {
      const response = await pdfMetaData(
        accessToken,
        getId,
        annotations,
        "reviewed"
      );
      if (response.ok) {
        const responseData = await response.json();
      } else {
        console.error("Failed to Save Meta:", response.statusText);
        notifyError("Failed to save metadata. Please try again.");
      }
    } catch (err) {
      console.error("Error:", err);
      notifyError("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
      notifySucess("Assignment reviewed successfully!");
      setTimeout(() => {
        back();
      }, 1000);
    }
  };

  const download = async () => {
    setLoading(true);
    try {
      await SaveAnnotations();
      // await saveReviewed();
    } catch (error) {
      console.error("Error during export:", error);
    } finally {
      setLoading(false);
    }
  };

  const SaveAnnotations = async () => {
    // Add annotations to the PDF
    let pdfBytesWithAnnotations;

    // Collect all promises from async operations
    const annotationPromises = annotations.map(async (annotation) => {
      return Promise.all(annotation.map(async (element) => {
        switch (element.type) {
          case "/Text":
            pdfFactory.createTextAnnotation(
              element.page,
              [
                element.coordinates[0],
                element.coordinates[1],
                element.coordinates[0] + 22,
                element.coordinates[1] + 22,
              ],
              element.content,
              element.author
            );
            if (element.hlCoordinates) {
              pdfFactory.createHighlightAnnotation(
                element.page,
                [
                  element.hlCoordinates[0],
                  element.hlCoordinates[1],
                  element.hlCoordinates[2],
                  element.hlCoordinates[3],
                ],
                "",
                ""
              );
            }
            break;

          case "/Highlight":
          case "/Strikethrough":
          case "/FreeText":
          case "/Circle":
            pdfBytesWithAnnotations = await addAnnotationsToPDF(pdfUrl, annotations);
            break;

          default:
            console.warn(`Unhandled annotation type: ${element.type}`);
            break;
        }
      }));
    });

    // Wait for all annotation processing to complete
    await Promise.all(annotationPromises);

    // Ensure pdfBytesWithAnnotations is defined before saving
    if (pdfBytesWithAnnotations) {
      saveAs(new Blob([pdfBytesWithAnnotations], { type: 'application/pdf' }), 'reviewed.pdf');
    } else {
      console.error("Failed to generate PDF with annotations.");
    }
  };

  useEffect(() => {
    if (
      annotationType &&
      selection?.type === "Range" &&
      annotationType !== "/Highlight" &&
      annotationType !== "/Strikethrough" &&
      annotationType !== "/Circle"
    ) {
      openContentPopup("add");
    }// else if (
    //   (annotationType === "/Highlight" && startCoordinates) ||
    //   (annotationType === "/Strikethrough" && startCoordinates) ||
    //   (annotationType === "/Circle" && startCoordinates)
    // ) {
    //   // handleAddAnnotation();
    // }
    else if (annotationType === '/FreeText' && selection?.type === "Caret") {
      openContentPopup("add");
    }
  }, [coordinates, annotationType, selection]);

  const openContentPopup = (actionType, annotation) => {
    setIsModal(true);
    setModalAction(actionType);
    if (actionType === "update") {
      setContent(annotation.content);
      setSelectedAnnotation(annotation);
    } else {
      setContent("");
      setSelectedAnnotation(null);
    }
  };

  const handleModalSubmit = (content, actionType) => {
    if (actionType === "add" || actionType === "update") {
      if (actionType === "add") {
        handleAddAnnotation(content);
      } else if (actionType === "update" && selectedAnnotation) {
        handleUpdateAnnotation(content, selectedAnnotation.annotationId); // Pass annotationId for update
      }
    } else if (actionType === "delete" && selectedAnnotation) {
      handleDeleteAnnotation(selectedAnnotation.annotationId); // Pass annotationId for delete
    }
    setIsModal(false);
  };

  const generateRandomId = () => {
    return uuidv4(); // Generates a UUID (example: "1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed")
  };

  const handleAddAnnotation = (annotationContent) => {
    if (pdfFactory && pdfViewer) {
      const parameters = getParameters(annotationContent);
      if (parameters) {
        const pageIndex = pdfViewer.currentPageNumber - 1;
        let newAnnotation;

        const id = generateRandomId();

        if (annotationType === "/Text") {
          newAnnotation = {
            annotationId: id,
            type: "/Text",
            page: pageIndex,
            coordinates: [
              parameters[0],
              parameters[1],
              parameters[0],
              parameters[1],
            ],
            hlCoordinates: SelectionCoOrdinates,
            content: annotationContent,
            author: parameters[3],
          };
        } else if (annotationType === "/FreeText") {

          const pageWidth = pdfViewer.getPageView(pageIndex).viewport.width;
          const startX = parameters[0]; // The X coordinate where the user clicked
          const remainingWidth = pageWidth - startX - 150; // Calculate remaining width from the clicked position

          const content = wrapText(parameters[2], remainingWidth);

          newAnnotation = {
            annotationId: id,
            type: "/FreeText",
            page: pageIndex,
            coordinates: [
              parameters[0],
              parameters[1],
              parameters[0],
              parameters[1],
            ],
            content: content,
            author: parameters[3],
          };
        }

        setUndoStack([...undoStack, newAnnotation]);
        setAnnotations((prevAnnotations) => {
          const updatedAnnotations = [...prevAnnotations];

          // Ensure no null entries in the annotations array
          for (let i = 0; i <= pageIndex; i++) {
            if (!updatedAnnotations[i]) {
              updatedAnnotations[i] = [];
            }
          }

          updatedAnnotations[pageIndex] = [
            ...updatedAnnotations[pageIndex],
            newAnnotation,
          ]; // Ensure immutability
          return updatedAnnotations;
        });
        setRedoStack([]);
      }
    }
  };

  const wrapText = (text, pageWidth) => {
    const maxCharsPerLine = Math.floor(pageWidth / 10); // Approximate max characters per line, adjust divisor as needed
    const words = text.split(" ");
    let line = "";
    let result = "";

    for (const word of words) {
      if ((line + word).length > maxCharsPerLine) {
        result += line.trim() + "\n";
        line = word + " ";
      } else {
        line += word + " ";
      }
    }
    result += line.trim();
    return result;
  };;

  const handleUpdateAnnotation = (updatedContent, annotationId) => {
    const element = document.querySelector(
      `[data-annotation-id="${annotationId}"]`
    );
    if (element) {
      element.title = updatedContent;
      element.innerText = updatedContent;
    }

    setAnnotations((prevAnnotations) => {
      const updatedAnnotations = [...prevAnnotations];
      updatedAnnotations.forEach((pageAnnotations, pageIndex) => {
        if (pageAnnotations) {
          updatedAnnotations[pageIndex] = pageAnnotations.map((annotation) => {
            if (annotation.annotationId === annotationId) {
              return {
                ...annotation,
                content: updatedContent,
              };
            }
            return annotation;
          });
        }
      });
      return updatedAnnotations;
    });
  };
  console.log("Annotations", annotations);

  const handleDeleteAnnotation = (annotationId) => {
    var elements = document.querySelectorAll(
      '[data-annotation-id="' + annotationId + '"]'
    );

    elements.forEach((element) => {
      element.remove();
    });

    setAnnotations((prevAnnotations) => {
      const updatedAnnotations = [...prevAnnotations];
      updatedAnnotations.forEach((pageAnnotations, pageIndex) => {
        if (pageAnnotations) {
          updatedAnnotations[pageIndex] = pageAnnotations.filter(
            (annotation) => annotation.annotationId !== annotationId
          );
        }
      });
      return updatedAnnotations;
    });

    setRenderedAnnotations((prevRenderedAnnotations) => {
      const newRenderedAnnotations = { ...prevRenderedAnnotations };

      Object.keys(newRenderedAnnotations).forEach((key) => {
        const [type, page, ...coords] = key.split('-');
        const annotationKey = `${type}-${page}-${coords.join('-')}`;

        const isMatch = annotations.some((pageAnnotations) =>
          pageAnnotations.some(
            (annotation) =>
              annotation.annotationId === annotationId &&
              `${annotation.type}-${annotation.page}-${annotation.coordinates.join('-')}` === annotationKey
          )
        );

        if (isMatch) {
          delete newRenderedAnnotations[key];
        }
      });

      return newRenderedAnnotations;
    });

  };


  const handleAnnotationClick = (annotation) => {
    const element = document.querySelector(
      `[data-annotation-id="${annotation.annotationId}"]`
    );
    annotation.content = element.innerText;
    console.log("Annotation Content", element.innerText);
    openContentPopup("update", annotation);
  };

  const getParameters = (annotationContent) => {
    let author = ""; // Replace with actual author retrieval logic if needed
    let x = coordinates[0];
    let y = coordinates[1];
    return [x, y, annotationContent ?? "", author];
  };

  const selectionCoordinates = () => {
    const selection = window
      .getSelection()
      .getRangeAt(0)
      .getBoundingClientRect();
    const offset = computePageOffset();
    let x1 = selection.x - offset.left;
    let y1 = selection.y - offset.top;
    let x2 = x1 + selection.width;
    let y2 = y1 + selection.height;

    const [x1_pdf, y1_pdf] = pdfViewer._pages[
      pdfViewer.currentPageNumber - 1
    ].viewport.convertToPdfPoint(x1, y1);
    x1 = x1_pdf;
    y1 = y1_pdf;
    const [x2_pdf, y2_pdf] = pdfViewer._pages[
      pdfViewer.currentPageNumber - 1
    ].viewport.convertToPdfPoint(x2, y2);
    x2 = x2_pdf;
    y2 = y2_pdf;

    return [x1, y1, x2, y2];
  };

  useEffect(() => {

    if (annotations && pdfRendered) {
      renderAnnotations();
    }
  }, [annotations, pdfRendered]);

  const renderAnnotations = () => {

    let viewer = document.getElementById("viewer");
    let annotationsToRender = [];

    annotations.forEach((pageAnnotations, pageIndex) => {
      if (pageAnnotations && pageAnnotations.length > 0) {
        pageAnnotations.forEach((annotation) => {
          const annotationKey = `${annotation.type}-${annotation.page
            }-${annotation.coordinates.join("-")}`;
          if (!renderedAnnotations[annotationKey]) {
            renderedAnnotations[annotationKey] = true;
            annotationsToRender.push(annotation);
          }
        });
      }
    });

    annotationsToRender.forEach((annotation) => {
      if (annotation.type === "/Text") {
        let viewportRect = pdfViewer._pages[
          annotation.page
        ].viewport.convertToViewportRectangle(annotation.coordinates);

        let highlightDiv = document.createElement("div");
        let div = document.createElement("div");

        div.classList.add("annotation-icon");
        div.setAttribute("data-annotation-id", annotation.annotationId);
        div.style.left = viewportRect[0] - 20 + "px";
        div.style.top = viewportRect[1] - 10 + "px";

        let icon = document.createElement("i");
        icon.classList.add("bi", "bi-chat-square-text-fill");
        div.appendChild(icon);

        if (annotation.hlCoordinates) {
          const [x1, y1, x2, y2] = annotation.hlCoordinates;
          const page = pdfViewer._pages[annotation.page];
          const viewport = page.viewport;

          highlightDiv.setAttribute(
            "data-annotation-id",
            annotation.annotationId
          );
          highlightDiv.style.position = "absolute";
          highlightDiv.style.backgroundColor = "yellow";
          highlightDiv.style.opacity = "0.5";

          const [left, top] = viewport.convertToViewportPoint(x1, y1);
          const [right, bottom] = viewport.convertToViewportPoint(x2, y2);

          const width = right - left;
          const height = bottom - top;

          highlightDiv.style.left = `${left}px`;
          highlightDiv.style.top = `${top}px`;
          highlightDiv.style.width = `${width}px`;
          highlightDiv.style.height = `${height}px`;
        }

        div.title = annotation.content;

        div.addEventListener("click", (e) => {
          e.stopPropagation();
          handleAnnotationClick(annotation);
        });

        let pageContainer = viewer.querySelector(
          `.page[data-page-number="${annotation.page + 1}"]`
        );
        if (pageContainer) {
          pageContainer.appendChild(div);
          pageContainer.appendChild(highlightDiv);
        } else {
          console.error(`Page ${annotation.page} not found in viewer.`);
        }
      }
      else if (annotation.type === "/FreeText") {
        if (annotation.coordinates.length === 4) {
          const [x1, y1] = annotation.coordinates;
          const page = pdfViewer._pages[annotation.page];
          const viewport = page.viewport;

          const [left, top] = viewport.convertToViewportPoint(x1, y1);

          const freeTextDiv = document.createElement("div");
          freeTextDiv.setAttribute("data-annotation-id", annotation.annotationId);
          freeTextDiv.style.position = "absolute";
          freeTextDiv.style.backgroundColor = "none";
          freeTextDiv.style.whiteSpace = "pre-wrap";

          freeTextDiv.style.left = `${left}px`;
          freeTextDiv.style.top = `${top - 14}px`;
          freeTextDiv.innerText = annotation.content;

          // Set the font, size, and color for the text
          freeTextDiv.style.fontFamily = "Inkfree";
          freeTextDiv.style.fontSize = "1.1rem";
          freeTextDiv.style.color = "red";

          const deleteIcon = document.createElement("i");
          deleteIcon.className = "bi bi-trash";
          deleteIcon.style.position = "absolute";
          deleteIcon.style.right = "10px";
          deleteIcon.style.top = "5px";
          deleteIcon.style.cursor = "pointer";
          deleteIcon.style.fontSize = "27px";
          deleteIcon.style.color = "red";
          deleteIcon.style.display = "none";

          // deleteIcon.addEventListener("click", (e) => {
          //   e.stopPropagation();
          //   handleDeleteAnnotation(annotation.annotationId);
          // });

          freeTextDiv.appendChild(deleteIcon);

          freeTextDiv.addEventListener("click", (e) => {
            e.stopPropagation();
            handleAnnotationClick(annotation);
          });

          document.addEventListener("click", () => {
            deleteIcon.style.display = "none";
          });

          const pageContainer = viewer.querySelector(`.page[data-page-number="${annotation.page + 1}"]`);
          if (pageContainer) {
            pageContainer.appendChild(freeTextDiv);
          } else {
            console.error(`Page ${annotation.page} not found in viewer.`);
          }
        }
      }
    });

    window.getSelection().removeAllRanges();

    setRenderedAnnotations({ ...renderedAnnotations });
    setStartCoordinates(null);
    setEndCoordinates(null);
  };

  const renderSavedAnnotations = (annotations) => {

    const viewer = document.getElementById("viewer");

    annotations.forEach((pageAnnotations) => {

      if (pageAnnotations && pageAnnotations.length > 0) {
        pageAnnotations.forEach((annotation) => {
          const { annotationId, type, coordinates } = annotation;
          const [x1, y1, x2, y2] = coordinates;

          if (type === "/Strikethrough") {

            const page = pdfViewer._pages[annotation.page];
            const viewport = page.viewport;

            const strikethroughDiv = document.createElement("div");
            strikethroughDiv.setAttribute("data-annotation-id", annotationId);
            strikethroughDiv.style.position = "absolute";
            strikethroughDiv.style.borderBottom = "2px solid red";

            const [left, top] = viewport.convertToViewportPoint(x1, y1);
            const [right] = viewport.convertToViewportPoint(x2, y1);

            strikethroughDiv.style.left = `${left}px`;
            strikethroughDiv.style.top = `${top}px`;
            strikethroughDiv.style.width = `${right - left}px`;

            addDeleteIcon(strikethroughDiv, annotationId);

            const pageContainer = viewer.querySelector(`.page[data-page-number="${annotation.page + 1}"]`);
            if (pageContainer) {
              pageContainer.appendChild(strikethroughDiv);
            }
          }
          else if (type === "/Highlight") {

            const page = pdfViewer._pages[annotation.page];
            const viewport = page.viewport;

            const highlightDiv = document.createElement("div");
            highlightDiv.setAttribute("data-annotation-id", annotationId);
            highlightDiv.style.position = "absolute";
            highlightDiv.style.backgroundColor = "rgba(255, 255, 0, 0.45)";
            highlightDiv.style.height = "30px";

            const [left, top] = viewport.convertToViewportPoint(x1, y1);
            const [right, bottom] = viewport.convertToViewportPoint(x2, y2);

            const width = right - left;
            const midpointY = (top + bottom) / 2;

            highlightDiv.style.left = `${left}px`;
            highlightDiv.style.top = `${midpointY - 15}px`;
            highlightDiv.style.width = `${width}px`;

            addDeleteIcon(highlightDiv, annotationId);

            const pageContainer = viewer.querySelector(`.page[data-page-number="${annotation.page + 1}"]`);
            if (pageContainer) {
              pageContainer.appendChild(highlightDiv);
            }
          }
          else if (type === "/Circle") {

            const page = pdfViewer._pages[annotation.page];
            const viewport = page.viewport;

            const [vx1, vy1] = viewport.convertToViewportPoint(x1, y1);
            const [vx2, vy2] = viewport.convertToViewportPoint(x2, y2);

            const centerX = (vx1 + vx2) / 2;
            const centerY = (vy1 + vy2) / 2;
            const xScale = Math.abs(vx2 - vx1) / 2;
            const yScale = Math.abs(vy2 - vy1) / 2 + 15;

            const ellipseDiv = document.createElement("div");
            ellipseDiv.setAttribute("data-annotation-id", annotationId);
            ellipseDiv.style.position = "absolute";
            ellipseDiv.style.border = "2px solid red";
            ellipseDiv.style.borderRadius = "50%";
            ellipseDiv.style.backgroundColor = "transparent";

            ellipseDiv.style.left = `${centerX - xScale}px`;
            ellipseDiv.style.top = `${centerY - yScale}px`;
            ellipseDiv.style.width = `${xScale * 2}px`;
            ellipseDiv.style.height = `${yScale * 2}px`;

            addDeleteIcon(ellipseDiv, annotationId);

            const pageContainer = viewer.querySelector(`.page[data-page-number="${annotation.page + 1}"]`);
            if (pageContainer) {
              pageContainer.appendChild(ellipseDiv);
            }
          }
        });
      }

    });
  };


  const loadFont = async () => {
    const response = await fetch(inkfreeFontUrl);
    const fontBytes = await response.arrayBuffer();
    return fontBytes;
  };

  const addAnnotationsToPDF = async (pdfBytes, annotations) => {
    const pdfDoc = await PDFDocument.load(pdfBytes);
    pdfDoc.registerFontkit(fontkit);


    const pages = pdfDoc.getPages();

    const fontBytes = await loadFont();
    const inkfreeFont = await pdfDoc.embedFont(fontBytes);

    annotations.forEach((Lannotation) => {
      Lannotation.forEach(annotation => {
        const { type, page, coordinates, content } = annotation;

        if (type == "/FreeText") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          const adjustedX = x1;
          const adjustedY = y1;

          pdfPage.drawText(content, {
            x: adjustedX,
            y: adjustedY,
            font: inkfreeFont,
            size: 17,
            color: rgb(1, 0, 0),
          });
        }
        else if (type == "/Strikethrough") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          try {
            pdfPage.drawLine({
              start: { x: x1, y: y1 },
              end: { x: x2, y: y1 },
              color: rgb(1, 0, 0),
              thickness: 2,
              opacity: 0.75,
            });

          } catch (error) {
            console.error(error);
          }

        }
        else if (type === "/Circle") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          // Calculate the center of the ellipse
          const centerX = (x1 + x2) / 2;
          const centerY = (y1 + y2) / 2;

          // Calculate the scales
          const xScale = Math.abs(x2 - x1) / 2;
          const yScale = Math.abs(y2 - y1) / 2 + 10;

          try {
            pdfPage.drawEllipse({
              x: centerX,
              y: centerY,
              xScale: xScale,
              yScale: yScale,
              borderColor: rgb(1, 0, 0),
              borderWidth: 2,
            });
          } catch (error) {
            console.error(error);
          }
        }
        else if (type === "/Highlight") {
          const pdfPage = pages[page];

          const [x1, y1, x2, y2] = coordinates;

          try {
            pdfPage.drawLine({
              start: { x: x1, y: y1 },
              end: { x: x2, y: y1 },
              color: rgb(1, 1, 0),
              thickness: 25,
              borderColor: grayscale(1),
              opacity: 0.45,
            });
          } catch (error) {
            console.error(error);
          }
        }

      });

    });

    return await pdfDoc.save();
  };


  const selectTool = (toolType) => {
    setStartCoordinates(null);
    setEndCoordinates(null);
    if (activeTool === toolType) {
      setActiveTool(null);
      setAnnotationType("");
    } else {
      setActiveTool(toolType);
      setAnnotationType(toolType);
    }
  };

  return (
    <div>
      <header
        className="mt-4 px-1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <button onClick={back} className="pdf-generate">
          Back
        </button>

        <div>
          <button
            onClick={submit}
            className="pdf-generate"
            style={{ marginRight: "10px" }}
          >
            Save Changes
          </button>
          <button
            onClick={reviewed}
            className="pdf-generate"
            style={{ marginRight: "10px" }}
          >
            Mark as Reviewed
          </button>
          {/* <button onClick={download} className="pdf-generate">
            Download
          </button> */}
        </div>
      </header>
      <div className="pdf-container">
        <div className="pdf-viewer-wrapper">
          <div className="d-flex justify-content-center align-items-center">
            <div>
              {/* <button
                id="addTextAnnotation"
                type="button"
                onClick={() => selectTool("/Text")}
                data-tooltip-id="addTextTooltip"
                className={`annotation-button ${activeTool === "/Text" ? "active" : ""
                  }`}
              >
                <div className="icon-container">
                  <img src={AnnotationIcon} className="annotion-img" />
                  {activeTool === "/Text" && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="active-icon"
                    />
                  )}
                </div>
              </button> */}

              <button
                id="addHighlightAnnotation"
                type="button"
                onClick={() => selectTool("/Highlight")}
                data-tooltip-id="addHighlightTooltip"
                className={`annotation-button ${activeTool === "/Highlight" ? "active" : ""
                  }`}
              >
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faHighlighter}
                    className="annotion-img"
                  />
                  {activeTool === "/Highlight" && (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      className="active-icon"
                    />
                  )}
                </div>
              </button>

              <button
                id="addStrikethroughAnnotation"
                type="button"
                onClick={() => selectTool("/Strikethrough")}
                data-tooltip-id="addStrikeThroughTooltip"
                className={`annotation-button ${activeTool === "/Strikethrough" ? "active" : ""
                  }`}
              >
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faStrikethrough}
                    className="annotion-img"
                  />
                  {activeTool === "/Strikethrough" && (
                    <FontAwesomeIcon
                      icon={faStrikethrough}
                      className="active-icon"
                    />
                  )}
                </div>
              </button>

              <button
                id="addCircleAnnotation"
                type="button"
                onClick={() => selectTool("/Circle")}
                data-tooltip-id="addCircleTooltip"
                className={`annotation-button ${activeTool === "/Circle" ? "active" : ""
                  }`}
              >
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faCircle}
                    className="annotion-img"
                  />
                  {activeTool === "/Circle" && (
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="active-icon"
                    />
                  )}
                </div>
              </button>

              <button
                id="addFreeTextAnnotation"
                type="button"
                onClick={() => selectTool("/FreeText")}
                data-tooltip-id="addFreeTextAnnotation"
                className={`annotation-button ${activeTool === "FreeText" ? "active" : ""
                  }`}
              >
                <div className="icon-container">
                  <FontAwesomeIcon
                    icon={faPen}
                    className="annotion-img"
                  />
                  {activeTool === "FreeText" && (
                    <FontAwesomeIcon
                      icon={faPen}
                      className="active-icon"
                    />
                  )}
                </div>
              </button>

              <button
                id="Undo"
                type="button"
                onClick={() => undo()}
                data-tooltip-id="undoAnnotation"
                className="annotation-button"
              >
                <div className="icon-container">
                  <svg
                    fill="none"
                    height="20"
                    viewBox="0 0 20 20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                    className="active-icon"
                  >
                    <path
                      d="M6.8624 6.5H9.25C9.66421 6.5 10 6.83579 10 7.25C10 7.66422 9.66421 8 9.25 8H4.85C4.38056 8 4 7.61945 4 7.15V2.75C4 2.33579 4.33579 2 4.75 2C5.16421 2 5.5 2.33579 5.5 2.75V5.6968L8.55877 3.03785C10.747 1.13561 14.0631 1.36749 15.9653 3.55577C17.8675 5.74405 17.6357 9.06006 15.4474 10.9623L7.27548 18.066C6.96287 18.3378 6.48915 18.3047 6.21741 17.992C5.94566 17.6794 5.97878 17.2057 6.29139 16.934L14.4633 9.83024C16.0264 8.4715 16.192 6.10291 14.8332 4.53986C13.4745 2.9768 11.1059 2.81118 9.54286 4.16992L6.8624 6.5Z"
                      fill="#212121"
                    />
                  </svg>
                </div>
              </button>

              <button
                id="Redo"
                type="button"
                onClick={() => redo()}
                data-tooltip-id="redoAnnotation"
                className="annotation-button"
              >
                <div className="icon-container">
                <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.1408 6.5H10.7532C10.3389 6.5 10.0032 6.83579 10.0032 7.25C10.0032 7.66422 10.3389 8 10.7532 8H15.1532C15.6226 8 16.0032 7.61945 16.0032 7.15V2.75C16.0032 2.33579 15.6674 2 15.2532 2C14.8389 2 14.5032 2.33579 14.5032 2.75V5.6968L11.4444 3.03785C9.25611 1.13561 5.94009 1.36749 4.03785 3.55577C2.13561 5.74405 2.36749 9.06006 4.55577 10.9623L12.7277 18.066C13.0403 18.3378 13.514 18.3047 13.7858 17.992C14.0575 17.6794 14.0244 17.2057 13.7118 16.934L5.53986 9.83024C3.9768 8.4715 3.81118 6.10291 5.16992 4.53986C6.52866 2.9768 8.89725 2.81118 10.4603 4.16992L13.1408 6.5Z" fill="#212121"/></svg>
                </div>
              </button>
            </div>
            <button
              className="pdf-generate m-2"
              onClick={download}
              disabled={loading}
              style={{ transform: 'translateX(19rem)' }}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faFileExport} />
              )}
              <span style={{ marginLeft: "0.5rem" }}>Export</span>
            </button>
          </div>

          {pdfUrl ? (
            <div
              id="viewerContainer"
              className="viewer-container"
              onClick={handlePageClick}
              onMouseDown={handleMouseDown}
              // onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              <div id="viewer" className="pdfViewer" />
            </div>
          ) : (
            <div className="loader-container">
              <Loader />
            </div>
          )}
          {isLoading && (
            <div className="loader-wrapper">
              <div className="loader"></div>
            </div>
          )}
        </div>
      </div>
      <div>
        {/* <ReactTooltip
          id="addTextTooltip"
          content="Text Annotation"
          place="left"
          type="light"
          className="custom-tooltip"
          delayShow={1000}
        /> */}
        <ReactTooltip
          id="addHighlightTooltip"
          content="Highlight Annotation"
          place="right"
          type="light"
          className="custom-tooltip"
          delayShow={1000}
        />
        <ReactTooltip
          id="addStrikeThroughTooltip"
          content="Strikethrough Annotation"
          place="right"
          type="light"
          className="custom-tooltip"
          delayShow={1000}
        />
        <ReactTooltip
          id="addEraserTooltip"
          content="Add Eraser Annotation"
          place="right"
          className="custom-tooltip"
          delayShow={1000}
        />
        <ReactTooltip
          id="addShapesTooltip"
          content="Add Shapes Annotation"
          place="right"
          className="custom-tooltip"
          delayShow={1000}
        />
        <ReactTooltip
          id="addImageCropTooltip"
          content="Add Image Crop Annotation"
          place="right"
          className="custom-tooltip"
          delayShow={1000}
        />
      </div>
      <Snackbar />
      <InputModal
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        onSubmit={handleModalSubmit}
        content={content}
        setContent={setContent}
        actionType={modalAction}
      />
    </div>
  );
};

export default PdfContainer;
