import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Block from "../../../../Components/Block/Block";
import BasicTable from "../../../../Components/Table/BasicTable";
import { FaArrowUp } from "react-icons/fa6";
import PreviewCard from "../../../../Components/PreviewCard/PreviewCard";
import AreaChartBasic from "../../../../Components/Chart/AreaChart";
import AdvanceTable from "../../../../Components/Table/AdvanceTable";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import FaceIcon from "@mui/icons-material/Face";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Snackbar, {
  notifySucess,
} from "../../../../Components/Snackbar/Snackbar";

import { useNavigate } from "react-router-dom";
import { tutorAssigned } from "../../../../Service/BaseService/BaseService";
import { Margin } from "@mui/icons-material";

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

function Tutordashboard() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [assignmentRows, setAssignmentRows] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [assignmentDefs, setAssignmentDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPage, setIsPage] = useState(1);
  const [isCount, setIsCount] = useState(10);
  const [isResponseData, setIsResponseData] = useState(null);
  const [isOgResponse, setOgResponse] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    fetchAllData();
    const visited = localStorage.getItem("visite");
    if (!visited) {
      notifySucess("Login Successful");
      setTimeout(() => {
        localStorage.setItem("visite", true);
      }, 2000);
    }
  }, []);
  const activeSubscription = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        label: "Active User",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: [
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#275298",
        ],
        data: [8200, 7800, 9500, 5500, 9200, 9690],
      },
    ],
  };

  const fetchAllData = async (page = 1, limit = 10) => {
    setIsLoading(true);
    try {
      const response = await tutorAssigned(userToken.token);
      const responseData = await response.json();
      if (response.ok && responseData.message.length > 0) {
        setOgResponse(responseData);
        setIsResponseData(responseData.message);

        const headers = Object.keys(responseData.message[0]);

        const assignmentColumns = [
          {
            field: "name",
            headerName: "Name",
          
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{params.row.name}</p>
              </div>
            ),
          },
          {
            field: "classRoomId",
            headerName: "Batch",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{params.row.batch}</p>
              </div>
            ),
          },
          {
            field: "scheduleDate",
            headerName: "Start Date",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">
                  {formatDate(params.row.scheduleDate)}
                </p>
              </div>
            ),
          },
          {
            field: "dueDate",
            headerName: "End Date",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{formatDate(params.row.dueDate)}</p>
              </div>
            ),
          },
          {
            field: "studentIds",
            headerName: "Students",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">{params.row?.studentIds}</p>
              </div>
            ),
          },
          {
            field: "schedule",
            headerName: "Status",
            flex: 1,
            renderCell: (params) => (
              <div>
                <p className="tabel-cell">
                  {params.row.schedule.charAt(0).toUpperCase() +
                    params.row.schedule.slice(1)}
                </p>
              </div>
            ),
          },
        ];
        assignmentColumns.push({
          field: "additionalFieldUpdate",
          headerName: "View",
          flex: 1,
   
          renderCell: (params) => {
            return (
              <div
                className="button visibility-icon"
                onClick={() => navigateHandler(params.row.id)}
              >
                <VisibilityIcon />
              </div>
            );
          },
        });

        const dynamicColumns = headers.map((header) => {
          if (header === "title") {
            return {
              field: header,
              headerName: "Batch",
              flex: 1,
            };
          } else if (header === "course") {
            return {
              field: header,
              headerName: header.charAt(0).toUpperCase() + header.slice(1),
              flex: 1,
              renderCell: (params) => (
                <div>
                  <p className="tabel-cell">{params.row.course?.name}</p>
                </div>
              ),
            };
          } else if (header === "startDate") {
            return {
              field: header,
              headerName: "Start Date",
              flex: 1,
              renderCell: (params) => (
                <div>
                  <p className="tabel-cell">{formatDate(params.row[header])}</p>
                </div>
              ),
            };
          } else if (header === "endDate") {
            return {
              field: header,
              headerName: "End Date",
              flex: 1,
              renderCell: (params) => (
                <div>
                  <p className="tabel-cell">{formatDate(params.row[header])}</p>
                </div>
              ),
            };
          } else {
            return {
              field: header,
              headerName: header.charAt(0).toUpperCase() + header.slice(1),
              flex: 1,
            };
          }
        });

        const columnOrder = ["title", "course", "startDate", "endDate"];

        const sortedColumns = dynamicColumns.sort((a, b) => {
          const aIndex = columnOrder.indexOf(a.field);
          const bIndex = columnOrder.indexOf(b.field);
          if (aIndex === -1) return 1;
          if (bIndex === -1) return -1;
          return aIndex - bIndex;
        });

        const columns = [...sortedColumns];

        columns.push({
          field: "additionalFieldUpdate",
          headerName: "Tutors",
          flex: 2,
          renderCell: (params) => (
            <div>
              {params.row.condition.map((cond) => (
                <p className="tabel-cell" key={cond?.tutor?._id}>
                  {cond?.tutor?.firstName}
                </p>
              ))}
            </div>
          ),
        });

        columns.push({
          field: "viewButton",
          headerName: "View",
          flex: 1,
     
          renderCell: (params) => {
            return (
              <div
                className="button visibility-icon"
                onClick={() => {
                  localStorage.setItem("isActive", "info");
                  navigate(`/batch-details?id=${params.row._id}`);
                }}
              >
                <VisibilityIcon />
              </div>
            );
          },
        });

        const rows = responseData.message.map((dataItem) => ({
          id: dataItem._id,
          ...dataItem,
        }));

        const assignments = responseData.assignments.map((dataItem) => ({
          id: dataItem._id,
          name: dataItem.name,
          description: dataItem.description,
          schedule: dataItem.schedule,
          batch: dataItem.classRoomId.title,
          scheduleDate: dataItem.scheduleDate,
          dueDate: dataItem.dueDate,
          studentIds: dataItem.studentIds.length,
        }));

        setColumnDefs(
          columns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "createdBy",
                "parentId",
                "createdAt",
                "updatedAt",
                "condition",
                "assignments",
                "description",
                "totalStudents",
                "isActive",
                "totalBatchAssignments"
              ].includes(item.field)
          )
        );
        setAssignmentDefs(assignmentColumns);
        setRowData(rows);
        setAssignmentRows(assignments);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  console.log("isResponseData", isResponseData);
  const newData = [
    {
      name: "Batches",
      icon: <CoPresentIcon style={{ color: "white" }} fontSize="large" />,
      count: isOgResponse?.message?.length ?? 0,
      iconColor: "#303972",
    },
    {
      name: "Students",
      icon: <FaceIcon style={{ color: "white" }} fontSize="large" />,
      count: isOgResponse?.totalStudents ?? 0,
      iconColor: "#FCC43E",
    },
    {
      name: " Total Assignments",
      count: isOgResponse?.totalAssignments ?? 0,
      icon: <AssignmentIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#4d44b5",
    },
    {
      name: "Draft Assignments",
      count: isOgResponse?.totalDrafts ?? 0,
      icon: <PendingActionsIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#FB7D5B",
    },
  ];

  const navigateHandler = (id) => {
    navigate(`/assignment-details?id=${id}`);
  };

  return (
    <Container>
      <Snackbar />
      <Col>
        <Row className="row-block mt-3 mx-0">
          {newData?.map((item, index) => {
            return (
              <Col md={6} lg={3} key={index} className="column-block">
                <Block data={item} activeSubscription={activeSubscription} />
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col className="mt-2" md={12}>
            <Row>
              <div className="dashboard d-flex align-items-center gap-1">
                <h6>Batches</h6>
              </div>
            </Row>
            <Row>
              <Col md={12} className="mt-2">
                <BasicTable rows={rowData} columns={columnDefs} />
              </Col>
            </Row>
          </Col>
          <Row></Row>
          <Col className="mt-2" md={12}>
            <Row>
              <div className="dashboard d-flex align-items-center gap-1">
                <h6>Assigments</h6>
              </div>
            </Row>
            <Row>
              <Col md={12} className="mt-2">
                <BasicTable rows={assignmentRows} columns={assignmentDefs} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default Tutordashboard;
