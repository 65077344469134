import React, { useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
function ParentLayout() {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="header-main">
      <div className={`header-layout ${isOpen ? "open" : ""}`} c>
        <Header setIsOpen={setIsOpen} isOpen={isOpen} />
      </div>
      <div className={`sidebar-layout ${isOpen ? "open" : ""}`}>
        <Sidebar />
      </div>
      <div className={`outlet ${isOpen ? "open" : ""}`}>
        <Outlet />
      </div>
      <div className={`footer-layout ${isOpen ? "open" : ""}`}>
        <Footer />
      </div>
    </div>
  );
}

export default ParentLayout;
