import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assests/icon/11+GA Logo 3x4.png";
import { activation } from "../../Service/BaseService/BaseService";
function ActivationSucess() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const getActivationCode = params.get("activationCode");

  const activationCode = async (id) => {
    try {
      const response = await activation(id);
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData);
      }
    } catch (err) {
      throw err;
    }
  };

  if (getActivationCode) {
    activationCode(getActivationCode);
  }
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={4}>
          <div className="contanier-type">
            <form>
              <div className="mb-3">
                <img src={logo} alt="Logo Small" />
                <h3>You Successfully activated your email.</h3>
                <p>Please click the link to continue.</p>
                <p>
                  <Link to={"/"} className="clk-login">
                    click to Login.
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ActivationSucess;
