import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Modal,
  Form,
  Tab,
  Pagination,
  Stack,
} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import upload from "../../Assests/icon/file.png";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import BasicDropdown from "../Dropdown/Dropdown";
import { CircleMinus } from "lucide-react";
import AdvanceTable from "../Table/AdvanceTable";
import BasicButton from "../Button/Button";
import {
  assignmentDownloadTutor,
  createAssignment,
  getAssignedStudents,
  getBatchDetails,
} from "../../Service/BaseService/BaseService";
import { successAlert } from "../Popup/Basicpopup";
import { notifyError, notifySucess } from "../Snackbar/Snackbar";
import AssignmentModal from "./AssignmentModal/AssignmentModal";
import Loader from "../Loader/Loader";
import InfoTab from "./InfoTab/InfoTab";
import { DataGrid } from "@mui/x-data-grid";
import { UploadBox } from "../UploadBox/UploadBox";

function BasicTab(props) {
  const infoId = props.info._id;
  const {
    dashboard,
    assignment,
    student,
    tutor,
    details,
    isActive,
    info,
    fetchStudentData,
  } = props;
  const [isModal, setIsModal] = useState(false);
  const [isAssignmentId, setIsAssignmentId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [showDraftSaved, setShowDraftSaved] = useState(false);
  const [file, setFile] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(null);
  const userDetails = localStorage.getItem("User");
  const accessToken = JSON.parse(userDetails);
  const [isStudent, setIsStudent] = useState(null);
  const [isAssignment, setIsAssignment] = useState(null);
  const [isFile, setIsFile] = useState([]);
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    description: "",
    dueDate: "",
    classRoomId: infoId,
    schedule: "",
    scheduleDate: "",
    studentIds: [],
    assignmentURL: null,
    Assignments: [],
    tutorId: tutor.row[0]?.id,
  });
  const [isData, setIsData] = useState(null);
  const [tutorList, setTutorList] = useState([]);
  const tutorData = (tutor) => {
    if (Array.isArray(tutor.row)) {
      setTutorList(tutor.row);
    } else {
      console.error("tutor.row is not an array");
    }
  };

  useEffect(() => {
    setIsData(props.info.title);
    tutorData(tutor);
    if (info._id) {
      getStudent(info._id);
    }
  }, [props.info, info._id, tutor]);

  const getStudent = async (infoId) => {
    setIsData(info.title);
    try {
      const { students, assignmentId } = await getAssignedStudents(
        infoId,
        userToken
      );
      setIsStudent(students);
      setIsAssignmentId(assignmentId);
    } catch (err) {
      console.error("Error fetching students:", err);
    }
  };

  const changeHandler = (type, ids, studentName) => {
    const updatedName = type === "add" ? studentName : form.name;
    let updatedStudentIds;
    if (type === "add") {
      updatedStudentIds = [...form.studentIds, ...ids];
    } else {
      updatedStudentIds = form.studentIds.filter((id) => !ids.includes(id));
    }

    const updatedStudents = isStudent.map((student) =>
      ids.includes(student._id)
        ? { ...student, isChecked: type === "add" }
        : student
    );
    setIsStudent(updatedStudents);
    setIsSelectAll(updatedStudents.every((student) => student.isChecked));
    setForm((prevForm) => ({
      ...prevForm,
      name: updatedName !== undefined ? updatedName : prevForm.name,
      studentIds: updatedStudentIds,
    }));

  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const buttonValue = e.nativeEvent.submitter.value;
    const scheme =
      buttonValue === "Submit" || buttonValue === "Update" ? "submit" : "draft";

    const updatedForm = {
      ...form,
      schedule:
        scheme === "draft" ? "draft" : form.scheduleDate ? "submit" : "",
      studentIds: isStudent
        .filter((item) => item.isChecked)
        .map((item) => item._id),
    };

    try {
      const formData = new FormData();
      formData.append("name", updatedForm.name || "");
      formData.append("dueDate", updatedForm.dueDate);
      formData.append("description", updatedForm.description);
      formData.append("scheduleDate", updatedForm.scheduleDate || "");
      formData.append("schedule", updatedForm.schedule);
      formData.append("studentIds", JSON.stringify(updatedForm.studentIds));
      formData.append("tutorId", updatedForm.tutorId || "");

      updatedForm.Assignments.forEach((file) => {
        formData.append("assignments", file);
      });

      formData.append("assignmentURL", updatedForm.assignmentURL || "null");

      const response = await createAssignment(
        userToken.token,
        info._id,
        formData
      );
      const responseData = await response.json();

      if (response.ok) {
        setForm({
          name: "",
          description: "",
          dueDate: "",
          classRoomId: info?._id || "",
          schedule: "",
          scheduleDate: "",
          studentIds: [],
          assignmentURL: null,
          Assignments: [],
          tutorId: tutor?.row[0]?.id || "",
        });
        setIsModal(false);

        if (buttonValue === "save as draft") {
          fetchStudentData();

          successAlert("Successfully saved as draft");
          modalCloseHandler();
        } else {
          fetchStudentData();
          successAlert(
            responseData.message || "Assignment created successfully"
          );
          modalCloseHandler();
        }
      } else {
        notifyError(responseData.message || "Failed to create assignment");
      }
    } catch (error) {
      console.error("Error creating assignment:", error);
      notifyError("Failed to create assignment");
    } finally {
      setIsLoading(false);
    }
  };

  const selectallHandler = (e) => {
    const updatedStudents = isStudent.map((student) => ({
      ...student,
      isChecked: e.target.checked,
    }));
    setIsStudent(updatedStudents);
    setIsSelectAll(e.target.checked);
    const allStudentIds = e.target.checked
      ? updatedStudents.map((student) => student._id)
      : [];
    setForm((prevForm) => ({
      ...prevForm,
      studentIds: allStudentIds,
    }));
  };

  const removeRowHandler = (index) => {
    const updatedFiles = [...isFile];
    updatedFiles.splice(index, 1);
    setIsFile(updatedFiles);
  };

  const modalCloseHandler = () => {
    setIsModal(false);
    setForm({
      name: "",
      description: "",
      dueDate: "",
      classRoomId: "",
      schedule: "",
      scheduleDate: "",
      studentIds: [],
      assignmentURL: null,
      Assignments: null,
      tutorId: "",
    });
    setIsFile([]);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles) {
      const updatedFiles = [...isFile, ...selectedFiles];
      setIsFile(updatedFiles);
      setForm((prev) => ({
        ...prev,
        Assignments: updatedFiles,
      }));
    }
  };

  const assignmentFile = async (id, name) => {
    try {
      const response = await assignmentDownloadTutor(userToken, id);
      if (!response.ok) {
        throw new Error("Failed to export data");
      }
      const contentDisposition = response.headers.get("Content-Disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      let fileName = "file";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifySucess("Export successful");
    } catch (err) {
      notifyError("Error on export");
    }
  };

  const changeValue = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const conditionRows =
    info?.condition?.map((cond, idx) => ({
      id: idx,
      subject: cond.subject.name,
      session: `${cond.starttime} - ${cond.endtime}`,
      tutor: cond.tutor?.firstName,
      course: info.course.name,
      createdBy: info.createdBy.firstName,
    })) || [];

  const conditionColumns = [
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "session", headerName: "Session", flex: 1 },
    { field: "tutor", headerName: "Tutor", flex: 1 },
    { field: "course", headerName: "Course", flex: 1, minWidth: 250 },
    { field: "createdBy", headerName: "Created By", flex: 1 },
  ];

  const handleClick = () => {
    document.getElementById("file-upload").click();
  };

  return (
    <>
      {isLoading && <Loader />}
      {isModal && (
        <UploadBox
          isAssignmentId={isAssignmentId}
          removeRowHandler={removeRowHandler}
          submitHandler={submitHandler}
          selectallHandler={selectallHandler}
          selectAll={true}
          changeHandler={changeHandler}
          student={isStudent}
          form={form}
          data={isData}
          tutor={tutorList}
          file={isFile}
          isModal={isModal}
          modalCloseHandler={() => setIsModal(false)}
          handleFileChange={handleFileChange}
          changeValue={changeValue}
          status={form.schedule}
        />
      )}
      {!isModal && (
        <Tabs
          defaultActiveKey="info"
          id="justify-tab-example"
          className="mb-3 tab-block"
          justify
        >
          <Tab eventKey="info" title="Info">
            <Row className="row-block mt-3 mx-0">
              <Col>
                <InfoTab info={info} />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="table-box mx-0 mt-3">
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <DataGrid
                      sx={{
                        ".MuiDataGrid-columnHeaderTitle": {
                          color: "#4D44B5",
                          fontFamily: "poppins,sans-serif",
                        },
                        ".MuiDataGrid-columnHeader--sortable": {
                          backgroundColor: "#E6EBEE",
                        },
                        ".MuiDataGrid-root .MuiDataGrid-row.Mui-hovered": {
                          backgroundColor: "transparent",
                        },
                        ".MuiDataGrid-row.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                        ".MuiDataGrid-cellContent": {
                          fontFamily: "poppins light,sans-serif",
                        },
                        ".MuiDataGrid-columnHeaderTitleContainer": {
                          backgroundColor: "#E6EBEE",
                        },
                        "&.MuiDataGrid-root": {
                          border: "none",
                        },
                        ".MuiDataGrid-cell:focus": {
                          outline: "none",
                          border: "none",
                        },
                        ".MuiDataGrid-cell:focus-within": {
                          outline: "none",
                          border: "none",
                        },
                      }}
                      rows={conditionRows}
                      columns={conditionColumns}
                      pageSize={5}
                      rowsPerPageOptions={[5, 10, 20]}
                      disableSelectionOnClick
                      hideFooter={true}
                      autoHeight={true}
                      disableRowSelectionOnClick={true}
                    />
                  </div>
                  <div className="d-flex align-items-baseline justify-content-between flex-row-reverse mt-3 mx-3">
                    <div>
                      <Stack spacing={2} className="mt-3">
                        <Pagination
                          sx={{
                            ".MuiPaginationItem-root.Mui-selected": {
                              backgroundColor: "#4D44B5",
                              color: "white",
                              border: "none",
                              borderRadius: "0.5rem",
                              fontFamily: "poppins,sans-serif",
                            },

                            ".MuiPaginationItem-root": {
                              border: "none",
                            },
                          }}
                          // count={response?.totalPages}
                          defaultPage={1}
                          variant="outlined"
                          shape="rounded"
                          // onChange={handlePageChange}
                        />
                      </Stack>
                    </div>
                    <div className="showentries">
                      <p>Showing</p>
                      <select
                        className="selectInput"
                        // value={isCount}
                        // onChange={handlePageChange}
                        name="show"
                      >
                        <option>10</option>
                        <option>20</option>
                        <option>30</option>
                      </select>
                      <p>
                        of
                        {/* {rows.length}  */}
                        total entries
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="assignment" title="Assignment">
            <Row className="mt-3">
              <Col>
                <div className="d-flex justify-content-end align-items-center">
                  <button
                    className="new-add-btn mb-3 border-0"
                    onClick={() => setIsModal(true)}
                  >
                    Add Assignment
                  </button>
                </div>
                <AdvanceTable rows={assignment.row} columns={assignment.col} />
              </Col>
            </Row>
            {details && (
              <>
                <Row className="mt-3">
                  <Col>
                    <h6 className="payment-due">Details</h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <AdvanceTable
                      rows={assignment.row}
                      columns={assignment.col}
                    />
                  </Col>
                </Row>
              </>
            )}
          </Tab>
          <Tab eventKey="student" title="Student">
            <Row className="mt-3">
              <Col>
                <AdvanceTable rows={student.row} columns={student.col} />
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="tutor" title="Tutor">
            <Row className="mt-3">
              <Col>
                <AdvanceTable rows={tutor.row} columns={tutor.col} />
              </Col>
            </Row>
          </Tab>
        </Tabs>
      )}
    </>
  );
}

export default BasicTab;
