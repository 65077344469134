import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Pagination, Stack } from "@mui/material";
import Loader from "../Loader/Loader";

function AdvanceTable(props) {
  const {
    columns,
    rows,
    isLoading,
    isPage,
    handlePageChange,
    isCount,
    response,
    checkbox,
    onRowSelectionModelChange,
  } = props;

  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatDateUK = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getUTCDate().toString().padStart(2, "0");
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const formattedRows = rows.map((row) => ({
    ...row,
    name: capitalizeFirstLetter(row.name),
    dueDate: formatDateUK(row.dueDate),
    createdAt: formatDateUK(row.createdAt),
    scheduleDate: formatDateUK(row.scheduleDate),
    schedule: capitalizeFirstLetter(row.schedule),
    description: capitalizeFirstLetter(row.description),
  }));

  const columnsWithMinWidth = columns.map((col) => ({
    ...col,
    minWidth: col.minWidth || 100, // Set a default minWidth if not specified
  }));

  return (
    <div className="table-box">
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div
            style={{
              width: "100%",
              overflowX: "auto", // Enable horizontal scrolling
            }}
          >
            <DataGrid
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  color: "#4D44B5",
                  fontFamily: "poppins,sans-serif",
                },
                ".MuiDataGrid-columnHeader--sortable": {
                  backgroundColor: "#E6EBEE",
                },
                ".MuiDataGrid-root .MuiDataGrid-row.Mui-hovered": {
                  backgroundColor: "transparent",
                },
                ".MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "transparent",
                },
                ".MuiDataGrid-cellContent": {
                  fontFamily: "poppins light,sans-serif",
                },
                ".MuiDataGrid-columnHeaderTitleContainer": {
                  backgroundColor: "#E6EBEE",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                  border: "none",
                },
                ".MuiDataGrid-cell:focus-within": {
                  outline: "none",
                  border: "none",
                },
              }}
              rows={formattedRows}
              columns={columnsWithMinWidth}
              hideFooter={true}
              autoHeight={true}
              rowHeight={60}
              loading={isLoading}
              checkboxSelection={checkbox}
              onRowSelectionModelChange={onRowSelectionModelChange}
              disableRowSelectionOnClick={true}
            />
          </div>
          <div className="d-flex align-items-baseline justify-content-between flex-row-reverse mt-3 mx-3">
            <div>
              <Stack spacing={2} className="mt-3">
                <Pagination
                  sx={{
                    ".MuiPaginationItem-root.Mui-selected": {
                      backgroundColor: "#4D44B5",
                      color: "white",
                      border: "none",
                      borderRadius: "0.5rem",
                      fontFamily: "poppins,sans-serif",
                    },

                    ".MuiPaginationItem-root": {
                      border: "none",
                    },
                  }}
                  count={response?.totalPages}
                  defaultPage={1}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
            <div className="showentries">
              <p>Showing</p>
              <select
                className="selectInput"
                value={isCount}
                onChange={handlePageChange}
                name="show"
              >
                <option>10</option>
                <option>20</option>
                <option>30</option>
              </select>
              <p>of {rows.length} total entries</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdvanceTable;
