import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
export const routes = [
  {
    link: "/dashboard",
    active: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon fontSize="small" />,
    route: [],
  },

  {
    name: "Assignments",
    link: "/assignment",
    active: "/assignment",
    route: [],
    icon: <HistoryIcon fontSize="small" />,
  },
    // {
  //   name: "Student Details",
  //   link: "#",
  //   active: "/records",
  //   route: [],
  //   icon: <FormatAlignLeftIcon fontSize="small" />,
  // },
  // {
  //   name: "Classroom",
  //   link: "#",
  //   route: [
  //     { name: "class-1", link: "#" },
  //     { name: "class-2", link: "#" },
  //     { name: "class-3", link: "#" },
  //   ],
  //   icon: <CoPresentIcon fontSize="small" />,
  // },
  // {
  //   link: "/tutor-records",
  //   name: "Tutor",
  //   icon: <PersonIcon fontSize="small" />,
  //   route: [],
  // },
  // {
  //   name: "Course",
  //   icon: <DescriptionIcon fontSize="small" />,
  //   route: [],
  // },
  // {
  //   name: "Class Room",
  //   icon: <CoPresentIcon fontSize="small" />,
  //   route: [],
  // },
  // {
  //   name: "Roles Management",
  //   icon: <ManageAccountsIcon fontSize="small" />,
  //   route: [
  //     { name: "User", link: "" },
  //     { name: "Role", link: "" },
  //     { name: "Permission", link: "" },
  //     { name: "Resourses", link: "" },
  //   ],
  // },

  // {
  //   icon: <DashboardOutlinedIcon fontSize="small" />,
  //   name: "Dashboard",
  //   link: "/dashboard",
  // },
  // {
  //   icon: <DocumentScannerOutlinedIcon fontSize="small" />,
  //   name: "Records",
  //   link: "/records",
  // },
  // {
  //   icon: <FolderOpenOutlinedIcon fontSize="small" />,
  //   name: "Tutor",
  //   link: "/Tutor",
  // },
  // {
  //   icon: <SettingsOutlinedIcon fontSize="small" />,
  //   name: "Supplier",
  //   link: "*",
  // },
  // {
  //   icon: <CastForEducationOutlinedIcon fontSize="small" />,
  //   name: "Seller",
  //   link: "*",
  // },
  // {
  //   icon: <GroupsOutlinedIcon fontSize="small" />,
  //   name: "FBA Calculator",
  //   link: "*",
  // },
  // {
  //   icon: <ForumOutlinedIcon fontSize="small" />,
  //   name: "Sales Estiamte",
  //   link: "*",
  // },
  // {
  //   icon: <FaChrome fontSize={16} />,
  //   name: "Bulk Analisis Tool",
  //   link: "*",
  // },
  // {
  //   icon: <SlSocialYoutube fontSize={16} />,
  //   name: "Bundles Ideas",
  //   link: "*",
  // },
  // {
  //   icon: <CiFacebook fontSize={16} />,
  //   name: "Facebook Group",
  //   link: "*",
  // },
  // {
  //   icon: <FaDiscord fontSize={16} />,
  //   name: "Discord",
  //   link: "*",
  // },
];
