import React, { useState } from "react";
import "./RegisterForm.css";
import { Col, Container, Row } from "react-bootstrap";
import { CirclePlus } from "lucide-react";
import CardBlock from "../../../../Components/CardBlock/CardBlock";
import { FaArrowUp } from "react-icons/fa6";
import PreviewBox from "../../../../Components/PreviewBox/PreviewBox";
function Adminfrom() {
  const form = {
    firstname: "",
    lastname: "",
    gender: "",
    dob: "",
  };
  const [isForm, setIsForm] = useState([form]);
  const [isCollapse, setIsCollapse] = useState(0);

  const addRowHandler = () => {
    setIsCollapse(isForm.length);
    setIsForm((prev) => {
      return [...prev, form];
    });
  };
  const removeRowHandler = (i) => {
    setIsForm((prev) => {
      return prev.filter((item, index) => index !== i);
    });
  };
  const collapseRowHandler = (i) => {
    setIsCollapse((prev) => {
      return prev === i ? null : i;
    });
  };
  const changeHandler = (e, i) => {
    setIsForm((prev) => {
      return prev.map((item, index) =>
        index === i ? { ...item, [e.target.name]: e.target.value } : item
      );
    });
  };
  const handleSubmit = (e) => {
    console.log(e);
    e.preventDefault();
  };
  const data = [
    {
      Name: "Munaroh Steffani",
      role: "Teacher",
      class: [
        {
          performance: "Mathematics",
        },
        {
          performance: "Science",
        },
        {
          performance: "Art",
        },
      ],
      profile: "Profile",
      chat: "Chat",
    },
    {
      Name: "Munaroh Steffani",
      role: "Teacher",
      class: [
        {
          performance: "Mathematics",
        },
        {
          performance: "Science",
        },
        {
          performance: "Art",
        },
      ],
      profile: "Profile",
      chat: "Chat",
    },
    {
      Name: "Munaroh Steffani",
      role: "Teacher",
      class: [
        {
          performance: "Mathematics",
        },
        {
          performance: "Science",
        },
        {
          performance: "Art",
        },
      ],
      profile: "Profile",
      chat: "Chat",
    },
    {
      Name: "Munaroh Steffani",
      role: "Teacher",
      class: [
        {
          performance: "Mathematics",
        },
        {
          performance: "Science",
        },
        {
          performance: "Art",
        },
      ],
      profile: "Profile",
      chat: "Chat",
    },
  ];
  return (
    <Container>
      <Row>
        <Col>
          <p className="dashboard-text">Tutor</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="tutor-block">
            <div className="d-flex justify-content-between align-items-baseline gap-2">
              <div className="add-new-input">
                <input className="search-inputbox"/>
              </div>
              <div className="add-new"> + New Tutor</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-3">
        {data.map((item, index) => {
          return (
            <Col md={3}>
              <PreviewBox data={item} />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
}

export default Adminfrom;
