import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import BasicButton from "../../../../../Components/Button/Button";
import "./index.css";
import FromBlock from "../../../../../Components/FromBlock/FromBlock";
import BasicDropdown from "../../../../../Components/Dropdown/Dropdown";
import BasicCheckbox from "../../../../../Components/Checkbox/Checkbox";
import { CircleMinus } from "lucide-react";
function SubjectList() {
  const form = {
    subject: "",
    duration: "",
  };
  const week = [
    { id: 1, name: "Sun", isChecked: true },
    { id: 2, name: "Mon", isChecked: true },
    { id: 3, name: "Tue", isChecked: false },
    { id: 4, name: "Wed", isChecked: false },
    { id: 5, name: "Thur", isChecked: false },
    { id: 6, name: "Fri", isChecked: false },
    { id: 7, name: "Sat", isChecked: false },
  ];
  const [isWeek, setIsWeek] = useState(week);
  const [isMultiple, setIsMultiple] = useState([form]);
  const addRowHandler = () => {
    setIsMultiple((prev) => {
      return [...prev, form];
    });
  };
  const changeHandler = (e, i) => {
    setIsMultiple((prev) => {
      return prev.map((item, index) => {
        return index === i
          ? { ...item, [e.target.name]: e.target.value }
          : item;
      });
    });
  };
  const removeRowHandler = (i) => {
    setIsMultiple((prev) => {
      return prev.filter((item, index) => index !== i);
    });
  };

  const checkboxHandler = (id) => {
    setIsWeek((prev) => {
      return prev.map((item, index) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      );
    });
  };
  return (
    <Container>
      <Row>
        <Col>
          <div className="profile-form">
            <h5>Add Subject</h5>
            <form>
              <div>
                <div>
                  <label>Title</label>
                  <input className="form-control" />
                </div>
                <div>
                  <label>Category</label>
                  <input className="form-control" />
                </div>
              </div>
              <div className="course-form">
                <label>Description</label>
                <textarea className="form-control"></textarea>
              </div>
              {/* {isMultiple.map((item, index) => {
                return (
                  <div key={index}>
                    <div>
                      <label>Subject</label>
                      <select
                        className="form-control select-block"
                        value={item.subject}
                        name="subject"
                        onChange={(e) => changeHandler(e, index)}
                      >
                        <option>Tamil</option>
                        <option>Maths</option>
                        <option>Science</option>
                      </select>
                    </div>
                    <div className="d-flex flex-row align-items-end gap-2">
                      <div style={{ position: "relative", flex: 1 }}>
                        <CircleMinus
                          className="minus-icon-remove"
                          onClick={() => removeRowHandler(index)}
                        />
                        <label>Duration</label>
                        <input
                          className="form-control"
                          name="duration"
                          value={item.duration}
                          onChange={(e) => changeHandler(e, index)}
                        />
                      </div>
                    </div>
                  </div>
                );
              })} */}
              {/* <div>
                <div className="d-flex flex-row justify-content-end">
                  <BasicButton
                    value={"+ Add"}
                    baseStyle="profile-save"
                    onClick={addRowHandler}
                  />
                </div>
              </div> */}
              <div className="save-border d-flex justify-content-end">
                <input
                  type="submit"
                  className="submit-save-btn"
                  value={"Save"}
                />
              </div>
            </form>
          </div>
          {/* <FromBlock data={isForm} changeHandler={changeHandler} /> */}
        </Col>
      </Row>
    </Container>
  );
}

export default SubjectList;
