import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./DeleteModal.css"; 

function DeleteModal({ showDeleteModal, setShowDeleteModal, deleteHandler }) {
  const handleClose = () => setShowDeleteModal(false);

  return (
    <Modal show={showDeleteModal} onHide={handleClose} >
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body className="text-center">
        <div className="modal-content-wrapper">
          <div className="icon-box">
            <i className="material-icons">&#xE5CD;</i>
          </div>
          <h4 className="delete-modal-title mt-2" >Are you sure?</h4>
          <p className="modal-text mt-2">
            Do you really want to delete these records?
          </p>
          <div className="button-group mt-3">
            <Button className="close-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="submit-save-btn"
              onClick={() => {
                deleteHandler();
                setShowDeleteModal(false);
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DeleteModal;
