import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notifySucess = (value) => toast.success(value);

export const notifyError = (value) => toast.error(value);

function Snackbar() {
  return (
    <div>
      <ToastContainer position="top-center" />
    </div>
  );
}

export default Snackbar;
