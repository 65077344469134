import React from "react";
function Block(props) {
  const { data } = props;
  return (
    <div className="block-element">
      <div>
        <div
          className="block-icon"
          style={{ background: data?.iconColor }}
        >
          {data?.icon}
        </div>
      </div>
      <div className="block-describe">
        <h5>{data?.name}</h5>
        <p>{data?.count}</p>
      </div>
    </div>
  );
}

export default Block;
