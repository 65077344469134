import React, { useEffect, useState, useRef } from "react";
import {
  getpaymentStatus,
  sendNotificationSchedule,
} from "../../../../Service/BaseService/BaseService";
import AdvanceTable from "../../../../Components/Table/AdvanceTable";
import "./index.css";
import { Button, Col, Container, Row } from "react-bootstrap";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../Components/Snackbar/Snackbar";
import Box from "../../../../Components/Box/Box";
import { useNavigate } from "react-router-dom";
import { successAlert } from "../../../../Components/Popup/Basicpopup";

function PaymentRecords() {
  const userToken = JSON.parse(localStorage.getItem("User"))?.token;
  const actionBtn = useRef(null);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPage, setIsPage] = useState(1);
  const [isCount, setIsCount] = useState(10);
  const [isResponseData, setIsResponseData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const actionData = [{ Notify: "Notify" }];

  useEffect(() => {
    fetchAllData();

    const handleClick = (event) => {
      if (actionBtn.current && !actionBtn.current.contains(event.target)) {
        btnClick(null);
      }
    };

    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const handleRowSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const btnClick = (id) => {
    setColumnDefs((prev) =>
      prev.map((item) =>
        item.field === "additionalFieldUpdate"
          ? {
            ...item,
            renderCell: (params) => (
              <div
                className="action-icon-box"
                onClick={() => btnClick(params.row.id)}
              >
                <i className="action-icon"></i>
                {params.row.id === id && (
                  <div ref={actionBtn}>
                    <Box
                      actionHandler={(actionType) =>
                        actionHandler(params.row._id, actionType)
                      }
                      actionData={actionData}
                    />
                  </div>
                )}
              </div>
            ),
          }
          : item
      )
    );
  };

  const actionHandler = (id, actionType) => {
    if (actionType === "Notify") {
      navigate(`/#`);
    }
  };

  const fetchAllData = async (page = 1, limit = 10) => {
    setIsLoading(true);
    try {
      const response = await getpaymentStatus(userToken, page, limit);
      const responseData = await response.json();
      if (response.ok && responseData.message.results.length > 0) {
        setIsResponseData(responseData.message);

        const headers = Object.keys(responseData.message.results[0]);

        const columns = headers.map((header) => {
          switch (header) {
            case "studentId":
              return {
                field: "studentId",
                headerName: "Student",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.studentId?.firstName}
                    </p>
                  </div>
                ),
              };
            case "paymentTerm":
              return {
                field: "paymentTerm",
                headerName: "Term",
                minWidth: 130,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.paymentTerm.charAt(0).toUpperCase() + params.row.paymentTerm.slice(1)}</p>
                  </div>
                ),
              };
            case "courseId":
              return {
                field: "courseId",
                headerName: "Course",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.courseId?.name}</p>
                  </div>
                ),
              };
            case "batchId":
              return {
                field: "batchId",
                headerName: "Batch",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.batchId[0]?.title}</p>
                  </div>
                ),
              };
            case "paidDate":
              return {
                field: "paidDate",
                headerName: "Paid Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.paidDate ?? "Not Paid"}
                    </p>
                  </div>
                ),
              };
            case "dueDate":
              return {
                field: "dueDate",
                headerName: "Due Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.dueDate ?? ""}
                    </p>
                  </div>
                ),
              };
            case "status":
              return {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1) ?? ""}
                    </p>
                  </div>
                ),
              };
            default:
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 180,
              };
          }
        });

        columns.push({
          field: "additionalFieldUpdate",
          headerName: "Action",
          flex: 1,
          width: 100,
          renderCell: (params) => (
            <div
              className="action-icon-box"
              onClick={() => btnClick(params.row.id)}
            >
              <i className="action-icon"></i>
            </div>
          ),
        });

        const studentColumnIndex = columns.findIndex(
          (col) => col.field === "studentId"
        );
        if (studentColumnIndex > 0) {
          const [studentColumn] = columns.splice(studentColumnIndex, 1);
          columns.unshift(studentColumn);
        }

        const rows = responseData.message.results.map((dataItem) => ({
          id: dataItem._id,
          ...dataItem,
        }));

        const desiredOrder = ["studentId", "courseId", "batchId", "paymentTerm", "amount", "dueDate", "paidDate", "status", "additionalFieldUpdate"];
        const orderedColumns = desiredOrder.map((key) => columns.find((col) => col.field === key));

        setColumnDefs(
          orderedColumns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "parentId",
                "createdAt",
                "updatedAt",
                "scheduleDate",
              ].includes(item.field)
          )
        );
        setRowData(rows);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };
  const handlePageChange = (e, value) => {
    if (e.target.name === "show") {
      const newCount = parseInt(e.target.value);
      setIsCount(newCount);
      fetchAllData(isPage, newCount);
    } else {
      setIsPage(value);
      fetchAllData(value, isCount);
    }
  };

  const scheduleNotifyPayment = async () => {
    setIsLoading(true);
    try {
      const data = { scheduleIds: selectedRows };
      const response = await sendNotificationSchedule(userToken, data);
      const responseData = await response.json();
      if (response.ok) {
        notifySucess(responseData.message);
      } else {
        notifyError(responseData.error);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="mt-2" style={{ height: "42px", overflow: "auto" }}>
            {selectedRows.length > 0 && (
              <Button className="new-add-btn" onClick={scheduleNotifyPayment}>
                Notify
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2">
            <AdvanceTable
              response={isResponseData}
              rows={rowData}
              columns={columnDefs}
              isLoading={isLoading}
              isPage={isPage}
              handlePageChange={handlePageChange}
              isCount={isCount}
              checkbox={true}
              onRowSelectionModelChange={handleRowSelectionModelChange}
            />
            <Snackbar />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default PaymentRecords;
