import React, { useEffect, useState, useRef, useMemo } from "react";
import { message } from "antd";
import { FaArrowUp } from "react-icons/fa6";
import {
  deleteStudent,
  fetchDataStudent,
  getById,
  updateData,
} from "../../../../../Service/BaseService/BaseService";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import "./index.css";
import { Col, Container, Row } from "react-bootstrap";
import BasicModal from "../../../../../Components/Modal/Modal";
import Snackbar from "../../../../../Components/Snackbar/Snackbar";
import Box from "../../../../../Components/Box/Box";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import defaultImage from "../../../../../Assests/icon/profile-picture.png";

import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";

function StudentRecords() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const actionBtn = useRef(null);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [rowDataDefault, setRowDataDefault] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPage, setIsPage] = useState(null);
  const [isCount, setIsCount] = useState(10);
  const [isModal, setIsModal] = useState(false);
  const [isGet, setIsGet] = useState([]);
  const [isFetchAll, setIsFetchAll] = useState(false);
  const [isResponseData, setIsResponseData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const actionData = [{ Edit: "Edit", Delete: "Delete", Profile: "View" }];

  useEffect(() => {
    fetchAllData();
    const handleClick = (event) => {
      if (actionBtn.current && !actionBtn.current.contains(event.target)) {
        btnClick(null);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isFetchAll]);

  const btnClick = (id) => {
    const additionalColumnUpdate = {
      field: "additionalFieldUpdate",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="action-icon-box"
            onClick={() => btnClick(params.row.id)}
          >
            <i className="action-icon "></i>
            {params.row.id === id ? (
              <div ref={actionBtn}>
                <Box
                  actionHandler={(actionType) =>
                    actionHandler(params.row._id, actionType, params.row)
                  }
                  actionData={actionData}
                />
              </div>
            ) : null}
          </div>
        );
      },
    };
    setColumnDefs((prev) => {
      return prev.map((item) => {
        return item.field === "additionalFieldUpdate"
          ? additionalColumnUpdate
          : item;
      });
    });
  };

  const actionHandler = (id, actionType, row) => {
    if (actionType === "Edit") {
      navigate(`/edit-student?id=${id}`, { state: { row } });
    } else if (actionType === "Delete") {
      setDeleteItemId(id);
      setShowDeleteModal(true);
    } else {
      navigate(`/student-details?id=${id}`);
    }
  };

  const fetchAllData = async (page, limit) => {
    setIsLoading(true);
    const request = {
      page: page ? page : 1,
      limit: limit ? limit : 10,
    };
    try {
      const response = await fetchDataStudent(userToken.token, request);
      const responseData = await response.json();
      console.log(responseData.message);
      if (response.ok && responseData.message.results.length > 0) {
        console.log(responseData.message);
        setIsResponseData(responseData.message);
        const headers = Object.keys(responseData.message.results[0]);
        const additionalColumnUpdate = {
          field: "additionalFieldUpdate",
          headerName: "Action",
          flex: 1,
          width: 100,
          renderCell: (params) => {
            return (
              <div
                className="action-icon-box"
                onClick={() => btnClick(params.row.id)}
              >
                <i className="action-icon"></i>
              </div>
            );
          },
        };

        const email = {
          field: "emailField",
          headerName: "Email",
          flex: 1,
          minWidth: 250,
          renderCell: (params) => {
            return (
              <div>
                <p className="tabel-cell">{params.row?.parent?.email}</p>
              </div>
            );
          },
        };

        const phone = {
          field: "phoneField",
          headerName: "Phone",
          flex: 1,
          width: 100,
          renderCell: (params) => {
            return (
              <div>
                <p className="tabel-cell">{params.row?.parent?.phone}</p>
              </div>
            );
          },
        };

        const columns = headers.map((header) => {
          if (header === "imageURL") {
            return {
              field: "imageURL",
              headerName: "",
              flex: 1,
              width: 80,
              renderCell: (params) => {
                return (
                  <div className="table-profile-picture">
                    <img
                      src={
                        params.row.imageURL ? params.row.imageURL : defaultImage
                      }
                      alt="profile-img"
                    />
                  </div>
                );
              },
            };
          } else if (header === "courses") {
            return {
              field: "courses",
              headerName: "Course",
              flex: 1,
              minWidth: 250,
              renderCell: (params) => {
                return (
                  <div>
                    <p className="tabel-cell">{params.row.courses[0]?.name}</p>
                  </div>
                );
              },
            };
          } else if (header === "firstName") {
            return {
              field: "firstName",
              flex: 1,
              headerName: "Name",
              minWidth: 100,
              renderCell: (params) => {
                return (
                  <div>
                    <p className="tabel-cell">{params.row.firstName} {params.row?.lastName}</p>
                  </div>
                );
              },
            };
          }
          else if (header === "parent") {
            return {
              field: "parent",
              flex: 1,
              headerName: "Parent",
              minWidth: 100,
              renderCell: (params) => {
                return (
                  <div>
                    <p className="tabel-cell">{params.row?.parent?.firstName ?? ""}</p>
                  </div>
                );
              },
            };
          }
          else if (header === "batches") {
            return {
              field: "batches",
              flex: 1,
              headerName: "Batch",
              minWidth: 100,
              renderCell: (params) => {
                const batches = params.row?.batches || [];
                const batchesString = batches.join(', ');
                return (
                  <div>
                    <p className="tabel-cell">{batchesString}</p>
                  </div>
                );
              },
            };
          }
          else {
            return {
              field: header,
              headerName: header.charAt(0).toUpperCase() + header.slice(1),
              flex: 1,
              width: 100,
            };
          }
        });

        columns.push(email, phone);
        if (additionalColumnUpdate) {
          columns.push(additionalColumnUpdate);
        }

        const rows = responseData.message.results.map((dataItem, index) => ({
          id: index,
          ...dataItem,
        }));

        const desiredOrder = ["imageURL", "firstName", "parent", "emailField", "phoneField", "courses", "batches", "additionalFieldUpdate"];
        const orderedColumns = desiredOrder.map((key) => columns.find((col) => col.field === key));

        setColumnDefs(
          orderedColumns.filter(
            (item, index) =>
              item.field !== "__v" &&
              item.field !== "_id" &&
              item.field !== "createdAt" &&
              item.field !== "updatedAt" &&
              item.field !== "currentSchool" &&
              item.field !== "isGurukulStudent" &&
              item.field !== "gender" &&
              item.field !== "dateOfBirth" &&
              item.field !== "schoolYear" &&
              item.field !== "academic"
          )
        );
        setRowDataDefault(rows);
        setRowData(rows);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
    setIsLoading(false);
  };

  const removeData = async (id) => {

    const response = await deleteStudent(userToken.token, id);
    const responseData = await response.json();
    try {
      if (!response.ok) {
        console.log(responseData);
      } else {
        message.success("Student deleted successfully");
        setIsFetchAll(!isFetchAll);
        setShowDeleteModal(false);
      }
    } catch (err) {
      console.error(err);

      throw err;
    }
  };

  const getIdData = async (id) => {
    const response = await getById(id);
    const responseData = await response.json();
    try {
      if (!response.ok) {
        console.log(responseData);
      } else {
        setIsGet(responseData);
        setIsModal(true);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const changeValue = async (e) => {
    setIsGet({ ...isGet, [e.target.name]: e.target.value });
  };

  const updateValue = async (id) => {
    const request = {
      id,
      isGet,
    };
    const response = await updateData(request);
    const responseData = await response.json();
    try {
      if (!response.ok) {
        console.log(responseData);
      } else {
        setIsModal(false);
        setIsFetchAll(!isFetchAll);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handlePageChange = async (e, value) => {
    if (e.target.name === "show") {
      setIsCount(parseInt(e.target.value));
      fetchAllData(null, parseInt(e.target.value));
    } else {
      setIsPage(value);
      fetchAllData(value, null);
    }
  };

  const searchHandler = (value) => {
    if (value === "") {
      setRowData(rowDataDefault);
    } else {
      setRowData((prev) => {
        return prev.filter((item) =>
          item.Assignments.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
  };
  const modalHandler = () => {
    // setIsModal(true);
    // navigate(`/add-student?record=student`);
  };
  const addNewHandler = () => {
    navigate(`/edit-student?record=student`);
  };
  return (
    <Container>
      <Row>
        <Col>
          <div className="product">
            <SearchBar onClick={addNewHandler} value={"Student"} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2">
            <AdvanceTable
              response={isResponseData}
              rows={rowData}
              columns={columnDefs}
              isLoading={isLoading}
              isPage={isPage}
              handlePageChange={handlePageChange}
              isCount={isCount}
            />
            <BasicModal
              isModal={isModal}
              isGet={isGet}
              changeValue={changeValue}
              setIsModal={setIsModal}
              updateValue={updateValue}
            />
            <Snackbar />
            <DeleteModal
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
              deleteHandler={() => removeData(deleteItemId)}
              name="Student"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default StudentRecords;
