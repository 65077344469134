import { MdOutlineDashboard, MdOutlineDocumentScanner } from "react-icons/md";
import { BsFillBoxSeamFill } from "react-icons/bs";
import { FaStore } from "react-icons/fa6";
import { IoSyncOutline } from "react-icons/io5";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoIosContact } from "react-icons/io";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import { FaChrome } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { SlSocialYoutube } from "react-icons/sl";
import { FaDiscord } from "react-icons/fa";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import Assignment from "@mui/icons-material/Assignment";
export const routes = [
  {
    link: "/dashboard",
    name: "Dashboard",
    active: "/dashboard",
    icon: <HomeIcon fontSize="small" />,
    route: [],
  },
  // {
  //   name: "Class Room",
  //   link: "/records",
  //   route: [],
  //   icon: <FormatAlignLeftIcon fontSize="small" />,
  // },
  {
    name: "Assignment",
    active: "/assignment",
    link: "/assignment",
    route: [],
    icon: <Assignment fontSize="small" />,
  },
  {
    name: "Batch",
    active: "/batch",
    link: "/batch",
    icon: <CoPresentIcon fontSize="small" />,
  },
  // {
  //   link: "/tutor-records",
  //   name: "Tutor",
  //   icon: <PersonIcon fontSize="small" />,
  //   route: [],
  // },
  // {
  //   name: "Course",
  //   icon: <DescriptionIcon fontSize="small" />,
  //   route: [],
  // },
  // {
  //   name: "Class Room",
  //   icon: <CoPresentIcon fontSize="small" />,
  //   route: [],
  // },
  // {
  //   name: "Roles Management",
  //   icon: <ManageAccountsIcon fontSize="small" />,
  //   route: [
  //     { name: "User", link: "" },
  //     { name: "Role", link: "" },
  //     { name: "Permission", link: "" },
  //     { name: "Resourses", link: "" },
  //   ],
  // },

  // {
  //   icon: <DashboardOutlinedIcon fontSize="small" />,
  //   name: "Dashboard",
  //   link: "/dashboard",
  // },
  // {
  //   icon: <DocumentScannerOutlinedIcon fontSize="small" />,
  //   name: "Records",
  //   link: "/records",
  // },
  // {
  //   icon: <FolderOpenOutlinedIcon fontSize="small" />,
  //   name: "Tutor",
  //   link: "/Tutor",
  // },
  // {
  //   icon: <SettingsOutlinedIcon fontSize="small" />,
  //   name: "Supplier",
  //   link: "*",
  // },
  // {
  //   icon: <CastForEducationOutlinedIcon fontSize="small" />,
  //   name: "Seller",
  //   link: "*",
  // },
  // {
  //   icon: <GroupsOutlinedIcon fontSize="small" />,
  //   name: "FBA Calculator",
  //   link: "*",
  // },
  // {
  //   icon: <ForumOutlinedIcon fontSize="small" />,
  //   name: "Sales Estiamte",
  //   link: "*",
  // },
  // {
  //   icon: <FaChrome fontSize={16} />,
  //   name: "Bulk Analisis Tool",
  //   link: "*",
  // },
  // {
  //   icon: <SlSocialYoutube fontSize={16} />,
  //   name: "Bundles Ideas",
  //   link: "*",
  // },
  // {
  //   icon: <CiFacebook fontSize={16} />,
  //   name: "Facebook Group",
  //   link: "*",
  // },
  // {
  //   icon: <FaDiscord fontSize={16} />,
  //   name: "Discord",
  //   link: "*",
  // },
];
