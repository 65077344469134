import React, { useState } from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
function AdminLayout() {
  const [isOpen, setIsOpen] = useState(true);
  const [isTitle, setIsTitle] = useState(null);
  const titleHandler = (value) => {
    setIsTitle(value);
  };
  console.log("isTitle:", isTitle);
  return (
    <div className="header-main">
      <div className={`header-layout ${isOpen ? "open" : ""}`}c>
        <Header setIsOpen={setIsOpen} isOpen={isOpen} isTitle={isTitle} />
      </div>
      <div className={`sidebar-layout ${isOpen ? "open" : "close"}`}>
        <Sidebar />
      </div>
      <div className={`outlet ${isOpen ? "open" : ""}`}>
        <Outlet titleHandler={titleHandler} />
      </div>
      <div className={`footer-layout ${isOpen ? "open" : ""}`}>
        <Footer />
      </div>
    </div>
  );
}

export default AdminLayout;
