import React, { useEffect, useRef, useState } from "react";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PersonIcon from "@mui/icons-material/Person";
import ProfileBox from "../../../Components/ProfileBox/ProfileBox";
import { useNavigate, useLocation } from "react-router-dom";

import { PiDotsNine } from "react-icons/pi";

function Header(props) {
  const profilebtn = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const trimmedPathname = pathname.substring(1);
  const capitalizedPathname =
    trimmedPathname.charAt(0).toUpperCase() + trimmedPathname.slice(1);
  const { setIsOpen, isOpen } = props;
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const ProfileactionData = [
    {
      profile: "Profile",
 
      signout: "SignOut",
    },
  ];
  const toggleHandler = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleClick = (event) => {
      if (profilebtn.current && !profilebtn.current.contains(event.target)) {
        setIsBoxOpen(false);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const boxHandler = () => {
    setIsBoxOpen(!isBoxOpen);
  };

  const actionHandler = (actionType) => {
    if (actionType === "signout") {
      localStorage.clear();
      navigate("/");
    } else if (actionType === "profile") {
      navigate("/profile");
    }
  };
  return (
    <div className="Header">
      <div>
        <div className="d-flex align-items-center gap-2">
          <div className="Header-menu">
            <MenuOpenOutlinedIcon
              style={{ color: "#4D44B5", cursor: "pointer" }}
              fontSize="medium"
              onClick={toggleHandler}
              className="Header-menu-icon"
            />
          </div>
          <div className="d-flex gap-2 align-items-center">
            <PiDotsNine style={{ fontSize: "40px" }} />
            <p className="dashboard-text">{capitalizedPathname}</p>
          </div>
        </div>

        <div className="d-flex gap-3 align-items-end">
          <NotificationsIcon fontSize="medium" style={{ color: "#4D44B5" }} />
          <div className="profile-menu" ref={profilebtn}>
            <PersonIcon
              fontSize="medium"
              style={{ color: "#4D44B5", cursor: "pointer" }}
              onClick={boxHandler}
            />
            {isBoxOpen && (
              <ProfileBox
                ProfileactionData={ProfileactionData}
                actionHandler={actionHandler}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
