import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

function Loader(props) {
  const { size, color } = props;
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // minHeight: "400px",
      }}
    >
      <CircularProgress
        size={size}
        style={{
          color: color ? color : "#4D44B5",
        }}
      />
    </Box>
  );
}

export default Loader;
