import React from "react";

function TutorRoute({ role, children }) {
  if (role === "TUTOR_USER") {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return <React.Fragment>Not Access</React.Fragment>;
}

export default TutorRoute;
