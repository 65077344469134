import { React, useState } from "react";
import { Form } from "react-bootstrap";
import { MdOutlineSearch } from "react-icons/md";

function SearchBar(props) {
  const { searchHandler, onClick, hide, value } = props;
  return (
    <div className="d-flex align-items-center justify-content-between tutor-block">
      <div className="d-flex align-items-center input-flex">
        <div className="search-icon-block">
          <MdOutlineSearch style={{ fontSize: "25px", color: "#3D3690" }} />
        </div>
        <input
          className="input-block"
          placeholder="Search here..."
          onChange={searchHandler}
        />
      </div>
      <div className="d-flex flex-row align-items-center gap-2">
        {/* <div>
          <Form.Select className="select-block-box">
            {props.value.map}
            <option>John Doe</option>
            <option>Jhon</option>
          </Form.Select>
        </div> */}
        {!hide && (
          <div onClick={onClick}>
            <div className="new-add-btn">+ New {value} </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchBar;
