import React, { useState } from "react";
import "./Upload.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from "sweetalert2";
function Upload() {
  const [isError, setIsError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const showAlert = () => {
    Swal.fire({
      title: "Assignment!",
      text: "Record succesfully uploaded.",
      icon: "success",
      confirmButtonText: "OK",
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setIsError(null);
      showAlert();
      setIsLoading(true);
    }
  };
  return (
    <div className="upload">
      <span className="text-danger">{isError}</span>
      <div>
        <label>
          <input
            type="file"
            className="default-file-input"
            accept=".pdf"
            onChange={handleFileChange}
          />
          <span className="browse-files">Click to select a file</span>
        </label>
      </div>
      <div>
        <CloudUploadIcon className="upload-icon" />
      </div>
      <p className="clicktoselect">Drag and drop file</p>
    </div>
  );
}

export default Upload;
