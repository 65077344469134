import { DataGrid } from "@mui/x-data-grid";
import React from "react";

const ParentTable = (props) => {
  const {
    columns,
    rows,
    isLoading,
    isPage,
    handlePageChange,
    isCount,
    response,
    checkbox,
    onRowSelectionModelChange,
  } = props;
  return (
    <div className="table-box">
      <div>
        <div
          style={{
            width: "100%",
          }}
        >
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                color: "#4D44B5",
                fontFamily: "poppins,sans-serif",
              },
              ".MuiDataGrid-columnHeader--sortable": {
                backgroundColor: "#E6EBEE",
              },
              ".MuiDataGrid-row.Mui-selected": {
                backgroundColor: "white",
              },
              ".MuiDataGrid-cellContent": {
                fontFamily: "poppins light,sans-serif",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              ".MuiDataGrid-cell:focus": {
                outline: "none",
                border: "none",
              },
              ".MuiDataGrid-cell:focus-within": {
                outline: "none",
                border: "none",
              },
            }}
            rows={rows}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
            rowHeight={60}
            loading={isLoading}
            checkboxSelection={checkbox}
            onRowSelectionModelChange={onRowSelectionModelChange}
            disableRowSelectionOnClick={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ParentTable;
