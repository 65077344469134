import React, { useEffect, useRef, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { IoCloseCircle } from "react-icons/io5";

function BasicDropdown(props) {
  const { data, selectAll, selectallHandler } = props;
  console.log(data);
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const values = data?.map((item, index) => {
    return (
      <ul className="dropdown-list">
        <li className="d-flex align-items-center gap-2">
          <input
            type="checkbox"
            onChange={(e) => props.changeHandler("add", item._id)}
            onClick={(e) => e.stopPropagation()}
            name="student"
            className="ui-checkbox"
            checked={item.isChecked}
          />
          <p>{item.firstName}</p>
        </li>
      </ul>
    );
  });

  const selected = data
    ?.filter((item, index) => item.isChecked)
    ?.map((item, index) => {
      return (
        <div className="title-tag">
          {item.firstName}&nbsp;
          <IoCloseCircle
            onClick={(e) => {
              e.stopPropagation();
              props.changeHandler("remove", item._id);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
      );
    });
  const dropdownHandler = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  return (
    <div className="multi-select" onClick={(e) => dropdownHandler(e)}>
      <div className="multi-select-tag">
        {selectAll ? (
          <div className="title-tag">select all</div>
        ) : 5 < selected?.length ? (
          <div className="title-tag">{selected?.length} student selecteds</div>
        ) : 0 < selected?.length ? (
          selected
        ) : (
          <div className="title-tag">All Students</div>
        )}
        <div
          style={{
            position: "absolute",
            right: "10px",
            top: "15px",
            display: "inline-block",
          }}
        >
          <FaAngleDown />
        </div>
      </div>
      {isOpen && (
        <div className="multi-select-dropdown" ref={dropdownRef}>
          <ul className="dropdown-list">
            <li className="d-flex align-items-center gap-2">
              <input
                type="checkbox"
                onChange={selectallHandler}
                onClick={(e) => e.stopPropagation()}
                name="student"
                className="ui-checkbox"
                checked={selectAll}
              />
              <p>Select All</p>
            </li>
          </ul>
          {values}
        </div>
      )}
    </div>
  );
}

export default BasicDropdown;
