import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import Block from "../../../../Components/Block/Block";
import ParentTable from "../../../../Components/Table/ParentTable";
import { FaArrowUp } from "react-icons/fa6";
import PreviewCard from "../../../../Components/PreviewCard/PreviewCard";
import AreaChartBasic from "../../../../Components/Chart/AreaChart";
import AdvanceTable from "../../../../Components/Table/AdvanceTable";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import defaultImage from "../../../../Assests/icon/profile-picture.png";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import FaceIcon from "@mui/icons-material/Face";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./Dashboard.css";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../Components/Snackbar/Snackbar";
import {
  assignmentDownload,
  assignmentView,
  getByStudent,
  getProfileData,
  getStudentAssignment,
} from "../../../../Service/BaseService/BaseService";
import { useNavigate } from "react-router-dom";
import { PdfViewer } from "../../../../Components/UploadBox/UploadBox";
function Parentdashboard() {
  const userDetails = localStorage.getItem("User");
  const accessToken = JSON.parse(userDetails);
  const studentDetails = localStorage.getItem("studentDetails");
  const studentId = JSON.parse(studentDetails);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const [columnDefs, setColumnDefs] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [isStudentData, setIsStudentData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isStatus, setIsStatus] = useState("");
  const [isFileId, setIsFileId] = useState("");
  const [isFileName, setIsFileName] = useState("");
  const [stud, setStud] = useState([]);
  const [pdfSrc, setPdfSrc] = useState(null);
  const [isViewAssignemnt, setIsViewAssignment] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(
    studentData.length > 0 ? studentData[0]._id : ""
  );
  const [isMetric, setIsMetric] = useState([]);
  const [isData, setIsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const visited = localStorage.getItem("visite");
    if (!visited) {
      notifySucess("Login Successful");
      setTimeout(() => {
        localStorage.setItem("visite", true);
      }, 2000);
    }
  }, []);

  const getProfile = async () => {
    try {
      const response = await getProfileData(accessToken.token);
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        setStudentData(responseData.message.Student);
        setIsStudentData(responseData.message);
      }
    } catch (err) {
      console.error("Error occurred while fetching profile:", err);
    }
  };

  const activeSubscription = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        label: "Active User",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: [
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#275298",
        ],
        data: [8200, 7800, 9500, 5500, 9200, 9690],
      },
    ],
  };

  const uploadHandler = (params) => {
    navigate(`/assignment-details`, { state: { params } });
    // setIsModal(true);
  };
  const newData = [
    {
      name: "Assigned",
      count: isMetric ? isMetric.assignedAssignmentsCount : 0,
      icon: <AssignmentIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#4d44b5",
    },
    {
      name: "Overdue",
      count: isMetric ? isMetric.overduePaymentsCount : 0,
      icon: <PendingActionsIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#FB7D5B",
    },
    {
      name: "Submitted",
      icon: <CloudUploadIcon style={{ color: "white" }} fontSize="large" />,
      count: isMetric ? isMetric.submittedAssignmentsCount : 0,
      iconColor: "#FCC43E",
    },
    {
      name: "Reviewed",
      icon: <CoPresentIcon style={{ color: "white" }} fontSize="large" />,
      count: isMetric ? isMetric.reviewedAssignmentsCount : 0,
      iconColor: "#303972",
    },
  ];
  const assignmentFile = async (id, name) => {
    console.log(id);
    try {
      const response = await assignmentDownload(accessToken.token, id);
      if (!response.ok) {
        throw new Error("Failed to export data");
      }
      const contentDisposition = response.headers.get("Content-Disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      let fileName = "file";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifySucess("Export successful");
    } catch (err) {
      notifyError("Error on export");
    }
  };

  const fetchStudentData = async (id) => {
    console.log("Fetching data for student ID:", id);
    try {
      const response = await getByStudent(accessToken.token, id);
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok) {
        setIsMetric(responseData.message.metrics);
        setIsData(responseData.message.student);
      } else {
        console.error("Failed to fetch student data:", responseData.message);
      }
    } catch (err) {
      console.error("Error fetching student data:", err);
    }
  };

  const handleSelectChange = async (event) => {
    const newSelectedStudent = event.target.value;
    setSelectedStudent(newSelectedStudent);
    console.log(newSelectedStudent);
    await fetchStudentData(newSelectedStudent);
    await fetchAllData(newSelectedStudent);
  };

  useEffect(() => {
    if (studentData.length > 0) {
      fetchStudentData(studentData[0]._id);
      fetchAllData(studentData[0]._id);
    }
  }, [studentData]);

  useEffect(() => {
    getProfile();
  }, []);

  const assignmentViewer = async (fileId, status, fileName) => {
    console.log(fileName);
    try {
      setIsStatus(status);
      const response = await assignmentView(accessToken.token, fileId);
      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const responseData = await response.json();

      const pdfContent = responseData.message;
      setIsFileId(fileId);
      setIsFileName(fileName);
      setIsViewAssignment(pdfContent);
      setIsModal(true);
    } catch (error) {
      console.error("Error fetching or displaying PDF:", error);
    }
  };

  const fetchAllData = async (id) => {
    console.log("Fetching data for student ID:", id);
    localStorage.setItem("studentid", JSON.stringify(id));

    setIsLoading(true);
    try {
      const response = await getStudentAssignment(accessToken.token, id);
      const responseData = await response.json();
      console.log("API response:", responseData);

      if (response.ok && responseData.message.length > 0) {
        const headers = Object.keys(responseData.message[0]);
        console.log("Headers:", headers);
        const rows = responseData.message
          .map((dataItem, index) => ({
            id: index,
            ...dataItem,
          }))
          .reverse();
        console.log("Rows:", rows);

        setRowData(rows);

        const additionalColumnUpdate = {
          field: "Download",
          headerName: "Assigned",
          width: 140,
          renderCell: (params) => {
            if (
              !params.row?.assignmentId ||
              params.row.assignmentId.length === 0
            ) {
              return null;
            }
            return (
              <div
                className="Btn"
                onClick={() =>
                  assignmentFile(
                    params.row?._id,
                    params.row?.assignmentId?.name
                  )
                }
              >
                <svg
                  className="svgIcon"
                  viewBox="0 0 384 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                </svg>
                <span className="icon2"></span>
              </div>
            );
          },
        };

        const additionalColumnSubmit = {
          field: "submit",
          headerName: "Submitted",
          width: 180,
          renderCell: (params) => (
            <div>
              {params.row?.uploadedFiles.map((file, index) => (
                <p
                  key={index}
                  className="assignment-name"
                  style={{ cursor: "pointer" }}
                  onClick={() => assignmentViewer(file._id, params.row?.status, (params.row?.assignmentId?.name + '_' + file.filename))}
                >
                  {file.filename}
                </p>
              ))}
            </div>
          ),
        };

        const additionalColumnEdit = {
          field: "Edit",
          headerName: "View",
          width: 100,
          renderCell: (params) => {
            const isDisabled = params.row.status === "in-review";
            return (
              <div
                className={`cssbuttons-io-button ${isDisabled ? 'disabled' : ''}`}
                onClick={isDisabled ? null : () => uploadHandler(params.row)}
              >
                <svg
                  className="svg-icon"
                  fill="none"
                  height="18"
                  viewBox="0 0 22 22"
                  width="18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#4D44B5"
                    strokeLinecap="round"
                    strokeWidth="2"
                    className="stroke-color"
                  >
                    <path d="m20 20h-16"></path>
                    <path
                      clipRule="evenodd"
                      d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                      fillRule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
            );
          },
        };        

        const formatDate = (dateString) => {
          const date = new Date(dateString);
          return date.toLocaleString("en-GB", {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
        };

        const columns = [
          ...headers.map((header) => {
            if (header === "assignmentId") {
              return {
                field: "assignmentId",
                headerName: "Name",
                flex: 1,
                width: 100,
                renderCell: (params) => (
                  <div>
                    <p className="assignment-name">
                      {params.row?.assignmentId?.name}
                    </p>
                  </div>
                ),
              };
            }
            else if (header === "scheduleDate") {
              return {
                field: "scheduleDate",
                headerName: "Schedule Date",
                flex: 1,
                width: 100,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row?.scheduleDate ? formatDate(params.row?.scheduleDate) : ''}
                    </p>
                  </div>
                ),
              };
            } else if (header === "dueDate") {
              return {
                field: "dueDate",
                headerName: "Due Date",
                flex: 1,
                width: 100,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {formatDate(params.row?.dueDate)}
                    </p>
                  </div>
                ),
              };
            } else if (header === "status") {
              return {
                field: "status",
                headerName: "Status",
                flex: 1,
                width: 100,
                renderCell: (params) => (
                  <div>
                    <p className="table-cell mt-3 textcolor">
                      {params.row.status.charAt(0).toUpperCase() +
                        params.row.status.slice(1)}
                    </p>
                  </div>
                ),
              };
            } else {
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 100,
              };
            }
          }),
          additionalColumnUpdate,
          additionalColumnSubmit,
          additionalColumnEdit,
        ];

        const desiredOrder = ["assignmentId", "scheduleDate", "dueDate", "status", "Download", "submit", "Edit"];
        const orderedColumns = desiredOrder.map((key) => columns.find((col) => col.field === key));

        setColumnDefs(
          orderedColumns.filter(
            (item) =>
              item.field !== "__v" &&
              item.field !== "_id" &&
              item.field !== "classId" &&
              item.field !== "createdAt" &&
              item.field !== "updatedBy" &&
              item.field !== "studentId" &&
              item.field !== "updatedAt" &&
              item.field !== "uploadedFiles" &&
              item.field !== "originalFile"
          )
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setIsLoading(false);
  };
  const modalHandler = () => {
    setIsModal(!isModal);
  };
  return (
    <>
      <Snackbar />
      <PdfViewer
        modal={isModal}
        modalHandler={modalHandler}
        isViewAssignment={isViewAssignemnt}
        isStatus={isStatus}
        fileId={isFileId}
        fileName={isFileName}
      />
      <Container>
        <Row className="justify-content-end">
          <Col md={2}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Select
                className="modal-input"
                name="classRoomId"
                value={selectedStudent}
                onChange={handleSelectChange}
                style={{ cursor: "pointer" }}
              >
                {studentData.map((student) => (
                  <option key={student._id} value={student._id}>
                    {student.firstName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-block mt-3 mx-0">
          {newData.map((item, index) => {
            return (
              <Col md={6} lg={3} key={index} className="column-block">
                <Block data={item} activeSubscription={activeSubscription} />
              </Col>
            );
          })}
        </Row>
        <Row className="mt-3">
          <Col>
            <div>
              <div className="profile-banner">
                <div className="d-flex justify-content-end align-items-end">
                  <div className="profile-banner1">
                    <svg
                      width="264"
                      height="59"
                      viewBox="0 0 264 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="30.435944"
                        width="263.592"
                        height="60.13"
                        rx="20"
                        fill="#FB7D5B"
                      ></rect>
                    </svg>
                  </div>
                  <div className="profile-banner2">
                    <svg
                      width="264"
                      height="59"
                      viewBox="0 0 264 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.0107422"
                        y="20.435944"
                        width="263.592"
                        height="100.13"
                        rx="20"
                        fill="#FCC43E"
                      ></rect>
                    </svg>
                  </div>
                </div>
                <div className="student-details">
                  <div>
                    <img
                      src={isData?.imageURL ? isData.imageURL : defaultImage}
                      alt="profile"
                      className="student-profile"
                    />
                  </div>
                </div>
              </div>
              <div className="profile-details">
                <div className="profile-detail-block">
                  <h4>
                    {isData?.firstName}&nbsp;{isData?.lastName}
                  </h4>
                  <span>Student</span>
                </div>
                <div className="d-flex">
                  <div className="profile-details-text">
                    <span>Parents Name</span>
                    <p className="gap-3">
                      {isStudentData?.firstName}&nbsp;
                      {isStudentData?.lastName}
                    </p>
                  </div>
                  <div className="profile-details-text">
                    <span>Address</span>
                    <p>
                      <i className="fas fa-map-marker-alt icon"></i>&nbsp;
                      &nbsp;{isStudentData?.address}
                    </p>
                    <p>
                      <i className="fas fa-envelope icon"></i>&nbsp; &nbsp;
                      {isStudentData?.email}
                    </p>
                    <p>
                      <i className="fas fa-phone icon"></i>&nbsp; &nbsp;
                      {isStudentData?.phone}
                    </p>
                  </div>
                  <div className="profile-details-text">
                    <span>Batch</span>
                    <p>{isData?.schoolYear}</p>
                  </div>

                  <div className="profile-details-text">
                    <span>Course</span>
                    <p>
                      {isData?.courses && isData.courses.length > 0
                        ? isData.courses[0].name
                        : "No course available"}
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="mt-2">
            <div className="dashboard d-flex align-items-center gap-1">
              <FaArrowUp className="up-arrow" />
              <h6>Recent Assignments</h6>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            <ParentTable
              rows={rowData}
              columns={columnDefs}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Parentdashboard;
