import React, { useEffect, useState } from "react";
import "./index.css";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import FaceIcon from "@mui/icons-material/Face";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Col, Container, Form, Row, Modal } from "react-bootstrap";
import defaultImage from "../../../../Assests/icon/profile-picture.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { message } from "antd";
import {
  changePasswordAPI,
  getByStudent,
  getProfileData,
} from "../../../../Service/BaseService/BaseService";

function Profile() {
  const user = localStorage.getItem("User");

  const userToken = JSON.parse(user);
  console.log(userToken.token);
  const [studentData, setStudentData] = useState({});
  const [students, setStudents] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleNew, setIsVisibleNew] = useState(false);
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
  });
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const response = await getProfileData(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        setStudentData(responseData.message);
        const studentList = responseData.message.Student || [];
        setStudents(studentList);

        // Fetch data for each student
        const studentIds = studentList.map((student) => student._id);
        const fetchedStudents = await Promise.all(
          studentIds.map(fetchStudentData)
        );
        setStudents(fetchedStudents.filter((student) => student !== null));
      } else {
        console.error("Failed to fetch profile data:", responseData.message);
      }
    } catch (err) {
      console.error("Error fetching profile data:", err);
    }
  };

  const fetchStudentData = async (studentId) => {
    try {
      const response = await getByStudent(userToken.token, studentId);
      const responseData = await response.json();

      if (response.ok) {
        return responseData.message.student;
      } else {
        console.error("Failed to fetch student data:", responseData.message);
        return null;
      }
    } catch (err) {
      console.error("Error fetching student data:", err);
      return null;
    }
  };
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    console.log("Switch value:", checked);
    if (type === "checkbox") {
      studentData((prevData) => ({
        ...prevData,
        isActive: checked,
      }));
    } else {
      studentData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    setPasswordData({ currentPassword: "", newPassword: "" });
  };

  const handleCurrentPasswordChange = (e) => setCurrentPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);

  const handleSaveChanges = ({ target }) => {
    const { name, value } = target;
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleCloseModal();
  };

  const handleSubmit = async () => {
    try {
      const data = await changePasswordAPI(passwordData, userToken.token);
      console.log("Password change successful:", data);
      if (data.isSuccess) {
        message.success(data.message);
      } else {
        message.error("Password change failed: " + data.message);
      }
    } catch (error) {
      console.error("Password change failed:", error.message);
      message.error("Password change failed: " + error.message);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    setIsVisibleNew(!isVisibleNew);
  };
  const toggleVisibilitys = () => {
    setIsVisibleNew(!isVisibleNew);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div>
            <div className="profile-banner">
              <div className="d-flex justify-content-end align-items-end col-12 mb-4">
                <div className="profile-banner1">
                  <svg
                    width="264"
                    height="59"
                    viewBox="0 0 264 59"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="30.435944"
                      width="263.592"
                      height="60.13"
                      rx="20"
                      fill="#FB7D5B"
                    ></rect>
                  </svg>
                </div>
                <div className="profile-banner2">
                  <svg
                    width="264"
                    height="59"
                    viewBox="0 0 264 59"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.0107422"
                      y="20.435944"
                      width="263.592"
                      height="100.13"
                      rx="20"
                      fill="#FCC43E"
                    ></rect>
                  </svg>
                </div>
              </div>

              <div className="student-details">
                <div>
                  <img
                    src={students?.imageURL ? students.imageURL : defaultImage}
                    alt="profile"
                    className="student-profile"
                  />
                </div>
              </div>
            </div>
            <div className="profile-details">
              <div className="profile-detail-block">
                <h4>
                  {studentData?.firstName}&nbsp;{studentData?.lastName}
                </h4>
                <span>Parent</span>
              </div>

              <div className="profile-describe-block">
                <div>
                  <div className="d-flex mt-0 ParentProfile">
                    <div className="">
                      <span className="profile-details-texts">Address</span>
                      <p className="profile-details-textcolor">
                        <i className="fas fa-map-marker-alt icon"></i>&nbsp;
                        &nbsp;
                        {studentData?.address}
                      </p>
                      <p className="profile-details-textcolor">
                        <i className="fas fa-envelope icon"></i>&nbsp; &nbsp;
                        {studentData?.email}
                      </p>
                      <p className="profile-details-textcolor">
                        <i className="fas fa-phone icon"></i>&nbsp; &nbsp;
                        {studentData?.phone}
                      </p>
                    </div>
                    <div>
                      <span className="profile-details-texts">Password</span>
                      <div>
                        <p>
                          {studentData?.password
                            ? "********"
                            : "No password set"}
                        </p>
                        <button
                          variant="primary"
                          onClick={handleShowModal}
                          className="submit-btn reduced-width"
                        >
                          Change Password
                        </button>
                      </div>

                      <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                          <Modal.Title>Change Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group controlId="formCurrentPassword">
                              <Form.Label className="modal-label">
                                Current Password
                              </Form.Label>

                              <div className="password-wrapper">
                                <Form.Group>
                                  <Form.Control
                                    type={isVisible ? "text" : "password"}
                                    placeholder="Enter current password"
                                    className="modal-inputCourseModal"
                                    value={passwordData.currentPassword}
                                    onChange={(e) =>
                                      setPasswordData({
                                        ...passwordData,
                                        currentPassword: e.target.value,
                                      })
                                    }
                                  />
                                  <div
                                    className="visibility"
                                    onClick={toggleVisibility}
                                  >
                                    {isVisible ? (
                                      <VisibilityIcon className="password-icon" />
                                    ) : (
                                      <VisibilityOffIcon className="password-icon" />
                                    )}
                                  </div>
                                </Form.Group>
                              </div>
                            </Form.Group>
                            <Form.Label className="modal-label">
                              New Password
                            </Form.Label>

                            <div className="password-wrapper">
                              <Form.Group>
                                <Form.Control
                                  type={isVisibleNew ? "text" : "password"}
                                  placeholder="Enter New Password"
                                  className="modal-inputCourseModal"
                                  value={passwordData.newPassword}
                                  onChange={(e) =>
                                    setPasswordData({
                                      ...passwordData,
                                      newPassword: e.target.value,
                                    })
                                  }
                                />
                                <div
                                  className="visibility"
                                  onClick={toggleVisibilitys}
                                >
                                  {isVisibleNew ? (
                                    <VisibilityIcon className="password-icon" />
                                  ) : (
                                    <VisibilityOffIcon className="password-icon" />
                                  )}
                                </div>
                              </Form.Group>
                            </div>
                          </Form>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            variant="primary"
                            onClick={handleSubmit}
                            className="submit-btn"
                          >
                            Save Changes
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          {students.map((student) => (
            <div key={student._id} className="col-12 mb-4">
              <div className="profile-banner">
                <div className="d-flex justify-content-end align-items-end">
                  <div className="profile-banner1">
                    <svg
                      width="264"
                      height="59"
                      viewBox="0 0 264 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="30.435944"
                        width="263.592"
                        height="60.13"
                        rx="20"
                        fill="#FB7D5B"
                      ></rect>
                    </svg>
                  </div>
                  <div className="profile-banner2">
                    <svg
                      width="264"
                      height="59"
                      viewBox="0 0 264 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.0107422"
                        y="20.435944"
                        width="263.592"
                        height="100.13"
                        rx="20"
                        fill="#FCC43E"
                      ></rect>
                    </svg>
                  </div>
                </div>
                <div className="student-details">
                  <div>
                    <img
                      src={student.imageURL ? student.imageURL : defaultImage}
                      alt="profile"
                      className="student-profile"
                    />
                  </div>
                </div>
              </div>

              <div className="profile-details">
                <div className="profile-detail-block">
                  <h4>
                    {student?.firstName}&nbsp;{student?.lastName}
                  </h4>
                  <span>Student</span>
                </div>
                <div className="profile-describe-block ">
                  <div>
                    <div className="d-flex  mt-0 profile-container">
                      {/* <div className="profile-details-text">
                        <span>Student Id</span>
                        <p>{student._id}</p>
                      </div> */}
                      <div className="profile-details-text">
                        <span>Batch</span>
                        <p>{student.schoolYear}</p>
                      </div>
                      <div className="profile-details-text">
                        <span>Courses</span>
                        {student.courses &&
                          student.courses.map((course) => (
                            <p key={course._id}>{course.name}</p>
                          ))}
                      </div>
                      <div className="profile-details-text">
                        <span>Created At</span>
                        <p>{formatDate(student.createdAt)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
