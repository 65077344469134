import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Dropdown,
} from "react-bootstrap";
import BasicButton from "../../../../../Components/Button/Button";
import "./index.css";
import { CircleMinus } from "lucide-react";
import {
  getSubjectData,
  postCourse,
} from "../../../../../Service/BaseService/BaseService";
import { successAlert } from "../../../../../Components/Popup/Basicpopup";
import { useNavigate } from "react-router-dom";

function Course() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const navigate = useNavigate();

  useEffect(() => {
    getSubject();
  }, []);

  const week = [
    { id: 1, name: "Sun", isChecked: true },
    { id: 2, name: "Mon", isChecked: true },
    { id: 3, name: "Tue", isChecked: false },
    { id: 4, name: "Wed", isChecked: false },
    { id: 5, name: "Thur", isChecked: false },
    { id: 6, name: "Fri", isChecked: false },
    { id: 7, name: "Sat", isChecked: false },
  ];

  const form = {
    name: "",
    shortDescription: "",
    longDescription: "",
    code: "CW101",
    isActive: false,
    courseDuration: "",
    courseDurationUnit: "",
    fee: "",
    monthlyFee: "",
    subjects: [{ _id: "", duration: "" }],
  };

  const [isWeek, setIsWeek] = useState(week);
  const [isInput, setIsInput] = useState(form);
  const [isSubject, setIsSubject] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState("Duration");
  const [errors, setErrors] = useState({});

  const addRowHandler = () => {
    setIsInput((prev) => {
      return {
        ...prev,
        subjects: [...prev.subjects, { _id: "", duration: "" }],
      };
    });
  };

  const changeHandler = (e, i) => {
    setIsInput((prev) => {
      if (0 <= i) {
        return {
          ...prev,
          subjects: prev.subjects.map((item, index) =>
            index === i ? { ...item, [e.target.name]: e.target.value } : item
          ),
        };
      } else {
        if (e.target.name === "fee" || e.target.name === "courseDuration") {
          return {
            ...prev,
            [e.target.name]:
              e.target.value === ""
                ? e.target.value.trim()
                : parseInt(e.target.value),
          };
        }
        return {
          ...prev,
          [e.target.name]: e.target.value,
        };
      }
    });
  };

  const removeRowHandler = (i) => {
    setIsInput((prev) => {
      return {
        ...prev,
        subjects: prev.subjects.filter((item, index) => index !== i),
      };
    });
  };

  const checkboxHandler = (id) => {
    setIsWeek((prev) => {
      return prev.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      );
    });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }

    const formData = new FormData();
    formData.append("file", file);
    profileUpdate(formData);
  };

  const profileUpdate = async (formData) => {
    try {
      await fetch("https://648950bc5fa58521caaf68f7.mockapi.io/Records", {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        code: isInput.code,
        courseDuration: isInput.courseDuration.toString(),
        courseDurationUnit: isInput.courseDurationUnit,
        fee: isInput.fee,
        isActive: isInput.isActive.toString(),
        longDescription: isInput.longDescription,
        monthlyFee: isInput.monthlyFee,
        name: isInput.name,
        shortDescription: isInput.shortDescription,
        subjects: isInput.subjects,
      };

      const response = await postCourse(userToken.token, dataToSend);
      const responseData = await response.json();
      if (response.ok) {
        navigate("/course");
        successAlert(responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getSubject = async () => {
    try {
      const response = await getSubjectData(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        setIsSubject(responseData.message);
      }
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleDurationSelect = (eventKey) => {
    setSelectedDuration(eventKey.toLowerCase());
    setIsInput((prev) => ({
      ...prev,
      courseDurationUnit: eventKey.toLowerCase(),
    }));
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="profile-form">
            <h5>Add course</h5>
            <Form onSubmit={submitHandler}>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      value={isInput.name}
                      name="name"
                      onChange={changeHandler}
                      className="modal-inputCourseModal"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="course-form">
                  <Form.Group>
                    <Form.Label>Short Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={isInput.shortDescription}
                      name="shortDescription"
                      onChange={changeHandler}
                      className="modal-inputCourseModal"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="course-form">
                  <Form.Group>
                    <Form.Label>Long Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={isInput.longDescription}
                      name="longDescription"
                      onChange={changeHandler}
                      className="modal-inputCourseModal"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex flex-row gap-2">
                <Col style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.Label>One-Time Fee</Form.Label>
                    <InputGroup className="mb-3 no-border">
                      <InputGroup.Text className="submit-save-btn rounded-left Add-course-label">
                        £
                      </InputGroup.Text>
                      <Form.Control
                        id="oneTimeFee"
                        value={isInput.fee}
                        name="fee"
                        onChange={changeHandler}
                        className="modal-inputCourseModal"
                        required
                        style={{ position: "relative", zIndex: "1" }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>

                <Col style={{ flex: 1 }}>
                  <Form.Group>
                    <Form.Label>Monthly Fee</Form.Label>
                    <InputGroup className="mb-3 no-border">
                      <InputGroup.Text className="submit-save-btn rounded-left Add-course-label">
                        £
                      </InputGroup.Text>
                      <Form.Control
                        id="monthlyFee"
                        value={isInput.monthlyFee}
                        name="monthlyFee"
                        onChange={changeHandler}
                        className="modal-inputCourseModal"
                        required
                        style={{ position: "relative", zIndex: "1" }}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Duration</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        value={isInput.courseDuration}
                        name="courseDuration"
                        onChange={changeHandler}
                        style={{ position: "relative", zIndex: "1" }}
                        required
                      />
                      <Dropdown onSelect={handleDurationSelect}>
                        <Dropdown.Toggle
                          variant="light"
                          id="dropdown-basic"
                          className="submit-save-btn rounded-right Add-course-label-fee  modal-inputCourseModal"
                          value={selectedDuration}

                          required
                          style={{ zIndex: "1", marginLeft: "-2px" }}
                        >
                          {selectedDuration}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="Months">
                            Months
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="Weeks">Weeks</Dropdown.Item>
                          <Dropdown.Item eventKey="Days">Days</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mt-2">
                    <Form.Label>Active Status</Form.Label>
                    <div className="d-flex mt-2 align-items-center">
                      <label className="switch">
                        <input
                          type="checkbox"
                          className="form-switch active-toggle "
                          checked={isInput.isActive}
                          onChange={(e) =>
                            setIsInput({
                              ...isInput,
                              isActive: e.target.checked,
                            })
                          }
                        />
                        <span className="slider"></span>
                      </label>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {isInput.subjects.map((item, index) => (
                <Row key={index}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Subject</Form.Label>
                      <Form.Select
                        value={item._id}
                        name="_id"
                        onChange={(e) => changeHandler(e, index)}
                        className="modal-inputCourseModal"
                        required
                      >
                        <option value="">Select a Subject</option>
                        {isSubject?.map((subject) => (
                          <option key={subject._id} value={subject._id}>
                            {subject.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col className="d-flex flex-row align-items-end gap-2">
                    <div style={{ position: "relative", flex: 1 }}>
                      <CircleMinus
                        className="minus-icon-remove"
                        onClick={() => removeRowHandler(index)}
                      />
                      <Form.Group>
                        <Form.Label>Duration in Hours</Form.Label>
                        <Form.Control
                          name="duration"
                          value={item.duration}
                          className="modal-inputCourseModal"
                          onChange={(e) => changeHandler(e, index)}
                          required
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              ))}

              <Row>
                <Col className="d-flex flex-row justify-content-end">
                  <BasicButton
                    value={"+ Add subject"}
                    baseStyle="profile-save"
                    onClick={addRowHandler}
                  />
                </Col>
              </Row>

              <Row>
                <Col className="save-border ">
                  <div className="d-flex justify-content-end mt-2">
                    <Button type="submit" className="submit-save-btn">
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Course;
