import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import { RiSparkling2Fill } from "react-icons/ri";
import { CircleMinus } from "lucide-react";
import BasicButton from "../../../Components/Button/Button";
import BasicCheckbox from "../../../Components/Checkbox/Checkbox";
import BasicDropdown from "../../../Components/Dropdown/Dropdown";
import BasicTable from "../../../Components/Table/BasicTable";
import { FaArrowUp } from "react-icons/fa6";
import {
  createClass,
  fetchDataStudent,
  fetchDataTutor,
  getByClass,
  getClassStudent,
  getCourse,
  getUnallocate,
  putClassData,
} from "../../../Service/BaseService/BaseService";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { successAlert } from "../../../Components/Popup/Basicpopup";
import BasicModal from "../../../Components/Modal/Modal";
import Snackbar, { notifyError } from "../../../Components/Snackbar/Snackbar";
let rowData = [];
let columnData = [];
function InfoTab({ info }) {
  const navigate = useNavigate();
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const courseId = search.get("id");
  console.log(info);
  const formData = {
    title: info?.info?.title || "",
    course: info?.info?.course.name,
    students: "",
    description: info?.info?.description,
    startDate: info?.info?.startDate,
    endDate: info?.info?.endDate,
    condition: [
      {
        starttime: info?.info?.condition[0]?.starttime,
        endtime: info?.info?.condition[0]?.endtime,
        tutor: info?.info?.condition[0]?.tutor.firstName,
        subject: info?.info?.condition[0]?.subject.name,
      },
    ],
    isActive: info?.info?.isActive,
  };
  const [isRows, setIsRow] = useState([]);
  const [isColumns, setIsColumns] = useState([]);
  const [isForm, setIsForm] = useState(formData);

  const [subject, setIsSubject] = useState(null);
  const [isTutor, setIsTuror] = useState([]);
  const [isSelectedId, setIsSelectedId] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const course = [];

  //   useEffect(() => {
  //     if (courseId) {
  //       getStudentData(courseId);
  //     }
  //     getCourseData();
  //   }, []);
  const addRowHandler = () => {
    setIsForm((prev) => {
      return {
        ...prev,
        condition: [
          ...prev.condition,
          {
            starttime: "",
            endtime: "",
            subject: "",
            tutor: [],
          },
        ],
      };
    });
  };

  const changeHandler = (e, i) => {
    setIsForm((prev) => {
      let updatedForm;

      switch (e.target.name) {
        case "subject":
        case "starttime":
        case "endtime":
        case "tutor":
          updatedForm = {
            ...prev,
            condition: prev.condition.map((item, index) =>
              index === i ? { ...item, [e.target.name]: e.target.value } : item
            ),
          };
          break;
        case "isActive":
          updatedForm = {
            ...prev,
            [e.target.name]: e.target.checked,
          };
          break;
        default:
          if (e.target.name === "course") {
            const updateSubject = course.find(
              (item, index) => item._id === e.target.value
            );
            setIsSubject(updateSubject);
          }
          updatedForm = { ...prev, [e.target.name]: e.target.value };
          break;
      }
      if (e.target.name === "startDate" || e.target.name === "endDate") {
        console.log(`Updated ${e.target.name}: ${e.target.value}`);
      }

      return updatedForm;
    });
  };
  const removeRowHandler = (i) => {
    setIsForm((prev) => {
      return {
        ...prev,
        condition: prev.condition.filter((item, index) => index !== i),
      };
    });
  };

  const getTutorData = async () => {
    try {
      const response = await fetchDataTutor(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        setIsTuror(responseData.tutors);
      }
    } catch (err) {
      throw err;
    }
  };
  const getCourseData = async () => {
    try {
      const response = await getCourse(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        getTutorData();

        setIsSubject(responseData.message[0]);
        getUnallocateStudentData(responseData.message[0]._id);
        if (courseId) {
          getByIdClass(courseId, responseData.message);
          getStudentData(`classId=${courseId}`);
        } else {
          getStudentData();
        }
      }
    } catch (err) {
      throw err;
    }
  };
  const getByIdClass = async (id, data) => {
    try {
      const response = await getByClass(userToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        const updated = data.find(
          (item, _) => item._id === responseData.message.course
        );
        setIsForm(responseData.message);
        setIsForm((prev) => {
          let newState = { ...prev };
          if (responseData.message.startDate) {
            newState.startDate = responseData.message.startDate.split("T")[0];
          }
          if (responseData.message.endDate) {
            newState.endDate = responseData.message.endDate.split("T")[0];
          }
          return newState;
        });

        setIsSubject(updated);
      }
    } catch (err) {
      throw err;
    }
  };

  const getUnallocateStudentData = async (id) => {
    try {
      const response = await getUnallocate(userToken.token, id);
      const responseData = await response.json();
      if (response.ok && responseData.message.length > 0) {
        const headers = Object.keys(responseData.message[0]);
        columnData = headers.map((header) => ({
          field: header,
          headerName: header.charAt(0).toUpperCase() + header.slice(1),
          flex: 1,
          width: 180,
        }));
        rowData = responseData.message.map((dataItem, index) => ({
          id: dataItem._id,
          ...dataItem,
        }));
        columnData = columnData.filter(
          (item, index) =>
            item.field !== "__v" &&
            item.field !== "_id" &&
            item.field !== "parent" &&
            item.field !== "createdAt" &&
            item.field !== "updatedAt"
        );
      } else {
        columnData = [];
        rowData = [];
      }
    } catch (err) {
      throw err;
    }
  };
  const searchHandler = (e) => {
    // console.log(e.target.value);
    // if (e.target.value) {

    rowData.filter((item, index) => {
      return item.firstName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    // }
  };

  const getStudentData = async (id) => {
    try {
      const response = await getClassStudent(userToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        const headers = responseData.message.headers;

        // const additionalColumnEdit = {
        //   field: "Edit",
        //   headerName: "Edit",
        //   width: 90,
        //   editable: true,
        //   flex: 1,
        //   renderCell: (params) => {
        //     return (
        //       <button class="button" onClick={() => editHandler(params.row.id)}>
        //         <svg
        //           class="svg-icon"
        //           fill="none"
        //           height="24"
        //           viewBox="0 0 24 24"
        //           width="24"
        //           xmlns="http://www.w3.org/2000/svg"
        //         >
        //           <g stroke="#4D44B5" stroke-linecap="round" stroke-width="2">
        //             <path d="m20 20h-16"></path>
        //             <path
        //               clip-rule="evenodd"
        //               d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
        //               fill-rule="evenodd"
        //             ></path>
        //           </g>
        //         </svg>
        //       </button>
        //     );
        //   },
        // };
        const additionalColumnRemove = {
          field: "Remove",
          headerName: "Remove",
          width: 90,
          flex: 1,
          renderCell: (params) => {
            return (
              <div
                className="tooltipbox"
                onClick={() => removeHandler(params.row.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                  height="25"
                  width="25"
                >
                  <path
                    fill="#4D44B5"
                    d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            );
          },
        };
        const columns = [
          ...headers.map((header) => ({
            field: header,
            headerName: header.charAt(0).toUpperCase() + header.slice(1),
            flex: 1,
            width: 180,
          })),
          additionalColumnRemove,
        ];
        if (responseData.assignedStudents) {
          const rows = responseData.assignedStudents[0].studentId.map(
            (dataItem, index) => ({
              id: dataItem._id,
              ...dataItem,
            })
          );
          setIsRow(rows);
        } else {
          setIsRow([]);
        }

        setIsColumns(
          columns.filter(
            (item, index) =>
              item.field !== "__v" &&
              item.field !== "_id" &&
              item.field !== "parent" &&
              item.field !== "createdAt" &&
              item.field !== "updatedAt"
          )
        );
      } else {
        setIsRow([]);
        setIsColumns([]);
      }
    } catch (err) {
      throw err;
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedForm = {
      ...isForm,
      students: isRows.map((item) => item.id),
    };
    setIsForm(updatedForm);
    if (!updatedForm.course) {
      return notifyError("course not Added");
    }
    if (!updatedForm.condition.some((item) => item.subject)) {
      return notifyError("subject not Added");
    }
    if (!updatedForm.condition.some((item) => item.tutor.length)) {
      return notifyError("tutor not Added");
    }

    if (!courseId) {
      try {
        const response = await createClass(userToken.token, updatedForm);
        const responseData = await response.json();
        if (response.ok) {
          successAlert(responseData.message);
          navigate("/Batch");
        } else {
          console.error(responseData);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        const response = await putClassData(
          userToken.token,
          courseId,
          updatedForm
        );
        const responseData = await response.json();
        if (response.ok) {
          successAlert(responseData.message);
          navigate("/Batch");
        } else {
          console.error(responseData);
        }
      } catch (err) {
        // Handle the error
        console.error(err);
      }
    }
  };

  const modalHandler = () => {
    setIsModal(!isModal);
  };
  const editHandler = (id) => {
    setIsModal(true);
  };

  const handleSelectionChange = (id) => {
    const updated = rowData.filter((item, index) => id.includes(item.id));
    setIsSelectedId(updated);
  };

  const saveHandler = () => {
    setIsRow((prev) => {
      return [
        ...prev,
        ...isSelectedId.map((item, index) => {
          return { id: item.id, ...item };
        }),
      ];
    });
  };

  const removeHandler = (i) => {
    setIsRow((prev) => {
      return prev.filter((item, index) => item.id !== i);
    });
  };

  const back = () => {
    window.history.back();
  };
  const formattedStartDate = formData.startDate
    ? formData.startDate.slice(0, 10)
    : "";
  const formattedEndDate = formData.endDate
    ? formData.endDate.slice(0, 10)
    : "";

  return (
    <Container>
      <Snackbar />
      <Row>
        <Col>
          <form onSubmit={submitHandler}>
            <div className="profile-form">
              <h5>Add Batch</h5>
              <div>
                <div>
                  <label>Title</label>
                  <input
                    className="form-control select-block"
                    name="title"
                    required
                    value={formData.title}
                    onChange={changeHandler}
                  />
                </div>
                <div>
                  <label>Course</label>
                  <select
                    className="form-control select-block"
                    value={formData.course}
                    name="course"
                    onChange={changeHandler}
                  >
                    <option value="">{formData.course} </option>
                  </select>
                </div>
              </div>
              <div className="course-form">
                <label>Description</label>
                <textarea
                  required
                  className="form-control"
                  name="description"
                  value={formData.description}
                  onChange={changeHandler}
                ></textarea>
              </div>
              <div>
                <div>
                  <label>Start Date</label>
                  <input
                    className="form-control"
                    name="startDate"
                    type="date"
                    required
                    value={formattedStartDate}
                    onChange={changeHandler}
                  />
                </div>
                <div>
                  <label>End Date</label>
                  <input
                    className="form-control"
                    name="endDate"
                    type="date"
                    required
                    value={formattedEndDate}
                    onChange={changeHandler}
                  />
                </div>
              </div>

              {formData.condition.map((item, index) => (
                <div className="flex-row-reverse" key={index}>
                  <div className="d-flex flex-row align-items-end gap-2">
                    <div style={{ position: "relative", flex: 1 }}>
                      <CircleMinus
                        className="minus-icon-remove"
                        onClick={() => removeRowHandler(index)}
                      />
                      <div className="d-flex flex-row gap-2 flex-wrap">
                        <div style={{ flex: 1 }}>
                          <label>Start time</label>
                          <input
                            value={formData.condition[index].starttime}
                            required
                            type="time"
                            className="form-control select-block"
                            name="starttime"
                            onChange={(e) => changeHandler(e, index)}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label>End time</label>
                          <input
                            required
                            type="time"
                            className="form-control select-block"
                            value={formData.condition[index].endtime}
                            name="endtime"
                            onChange={(e) => changeHandler(e, index)}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <label>Subject</label>
                          <select
                            className="form-control"
                            value={formData.condition[index].subject}
                            name="subject"
                            onChange={(e) => changeHandler(e, index)}
                          >
                            <option>{formData.condition[index].subject}</option>
                          </select>
                        </div>
                        <div style={{ flex: 1 }}>
                          <label>Tutor</label>
                          <select
                            className="form-control"
                            value={formData.condition[index].tutor}
                            name="tutor"
                            onChange={(e) => changeHandler(e, index)}
                          >
                            <option>{formData.condition[index].tutor}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div className="d-flex justify-content-end">
                <Form.Group>
                  <Form.Label>
                    Active Status
                    <label
                      className="switch"
                      style={{ top: "5px", right: "-10px" }}
                    >
                      <input
                        type="checkbox"
                        name="isActive"
                        className="form-switch active-toggle"
                        checked={isForm.isActive}
                        onChange={(e) => changeHandler(e)}
                      />
                      <span className="slider"></span>
                    </label>
                  </Form.Label>
                </Form.Group>
                <BasicButton
                  value={"+ Add Subject"}
                  baseStyle="profile-save"
                  onClick={addRowHandler}
                />
                <BasicModal
                  saveHandler={saveHandler}
                  searchHandler={searchHandler}
                  course={course}
                  isModal={isModal}
                  setIsModal={setIsModal}
                  rows={rowData}
                  columns={columnData}
                  handleSelectionChange={handleSelectionChange}
                />
              </div> */}
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default InfoTab;
