import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import BasicButton from "../../../../../Components/Button/Button";
import "./index.css";
import { FaArrowUp } from "react-icons/fa6";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import {
  getAssignmentDetails,
  assignmentDownload,
} from "../../../../../Service/BaseService/BaseService";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../../Components/Snackbar/Snackbar";

function ClassRoomDescripition() {
  localStorage.setItem("isActive", "assignment");
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const navigate = useNavigate();
  const location = useLocation();
  const getParams = new URLSearchParams(location.search);
  const getId = getParams.get("id");
  const [assignmetRowData, setAssignmentRowData] = useState([]);
  const [assignmentName, setAssignmentName] = useState([]);
  const [assignmentColumnDefs, setAssignmentColumnDefs] = useState([]);
  const [customTitle, setCustomTitle] = useState("");

  useEffect(() => {
    if (getId) {
      getAssignment(getId);
    }
  }, []);

  useEffect(() => {
    if (assignmentName) {
      const dashboardTextElements = document.getElementsByClassName('dashboard-text');
      for (let element of dashboardTextElements) {
        element.innerHTML = assignmentName;
      }
    }
  }, [assignmentName]);

  const assignmentFile = async (id, name) => {
    console.log(id);
    try {
      const response = await assignmentDownload(userToken.token, id);
      if (!response.ok) {
        throw new Error("Failed to export data");
      }
      const contentDisposition = response.headers.get("Content-Disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      let fileName = "file";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifySucess("Export successful");
    } catch (err) {
      notifyError("Error on export");
    }
  };

  const getAssignment = async (id) => {
    try {
      const response = await getAssignmentDetails(
        userToken.token,
        `assignmentId=${id}`
      );
      const responseData = await response.json();
      if (response.ok) {
        const headers = Object.keys(responseData.assignments[0]);
        const columns = headers.map((header) => {
          switch (header) {
            case "assignmentId":
              return {
                field: "assignmentId",
                headerName: "Assignment Name",
                flex: 1,
                minWidth: 150,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.assignmentId?.name}
                    </p>
                  </div>
                ),
              };
            case "studentId":
              return {
                field: "studentId",
                headerName: "Student",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.studentId?.firstName}
                    </p>
                  </div>
                ),
              };
            case "createdAt":
              return {
                field: "createdAt",
                headerName: "Submitted Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.createdAt.split("T")[0]}
                    </p>
                  </div>
                ),
              };
            case "dueDate":
              return {
                field: "dueDate",
                headerName: "Due Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.dueDate.split("T")[0]}
                    </p>
                  </div>
                ),
              };
            case "status":
              return {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.status.charAt(0).toUpperCase() + params.row.status.slice(1)}
                    </p>
                  </div>
                ),
              };
            case "originalFile":
              return {
                field: "originalFile",
                headerName: "Assigned",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    {params.row?.originalFile.map((file, index) => (
                      <p
                        key={index}
                        className="assignment-name"
                        style={{ cursor: "pointer" }}
                        onClick={() => assignmentFile(params.row?._id)}
                      >
                        {file.filename}
                      </p>
                    ))}
                  </div>
                ),
              };
            case "uploadedFiles":
              return {
                field: "uploadedFiles",
                headerName: "Submitted",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.uploadedFiles?.map((item) => item.filename)}
                    </p>
                  </div>
                ),
              };
            default:
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 180,
              };
          }
        });
        columns.push({
          field: "additionalFieldUpdate",
          headerName: "Editor",
          width: 100,
          renderCell: (params) => {
            return (
              <div
                class="button"
                onClick={() =>
                  navigate(
                    `/assignment-editor?id=${params.row.uploadedFiles[0]._id}`,
                    {
                      state: {
                        title: params.row.assignmentId?.name,
                        name: params.row.studentId?.firstName,
                      },
                    }
                  )
                }
              >
                <svg
                  class="svg-icon"
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#4D44B5"
                    stroke-linecap="round"
                    stroke-width="2"
                    className="stroke-color"
                  >
                    <path d="m20 20h-16"></path>
                    <path
                      clip-rule="evenodd"
                      d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
            );
          },
        });
        const rows = responseData.assignments.map((dataItem) => ({
          id: dataItem._id,
          ...dataItem,
        }));

        const desiredOrder = [
          // "assignmentId",
          "studentId",
          "createdAt",
          "dueDate",
          "status",
          "originalFile",
          "uploadedFiles",
          "additionalFieldUpdate",
        ];
        const orderedColumns = desiredOrder.map((key) =>
          columns.find((col) => col.field === key)
        );

        setAssignmentColumnDefs(
          orderedColumns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "parent",
                "updatedBy",
                "scheduleDate",
                "parentId",
                "updatedAt",
                "classId",
              ].includes(item.field)
          )
        );
        setAssignmentRowData(rows);
        setAssignmentName(rows[0].assignmentId.name)
      }
    } catch (err) {
      console.error(err);
    }
  };
  const back = () => {
    window.history.back();
  };
  return (
    <Container>
      <Row>
        <Col>
          <BasicButton
            baseStyle="submit-save-btn"
            value="Back"
            onClick={back}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <AdvanceTable
            rows={assignmetRowData}
            columns={assignmentColumnDefs}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ClassRoomDescripition;
