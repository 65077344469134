import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ParentTable from "../../../Components/Table/ParentTable";
import { FaArrowUp } from "react-icons/fa6";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import defaultImage from "../../../Assests/icon/profile-picture.png";
import Block from "../../../Components/Block/Block";
import BasicButton from "../../../Components/Button/Button";
import "./index.css";
import Snackbar, {
    notifyError,
    notifySucess,
} from "../../../Components/Snackbar/Snackbar";
import {
    getByStudent,
    getStudentAssignment,
} from "../../../Service/BaseService/BaseService";
import { useNavigate } from "react-router-dom";


function StudentProfileBoard() {

    const location = useLocation();
    const { id } = location.state;

    const userDetails = localStorage.getItem("User");
    const accessToken = JSON.parse(userDetails);
    const [rowData, setRowData] = useState([]);
    const navigate = useNavigate();
    const [columnDefs, setColumnDefs] = useState([]);
    const [isModal, setIsModal] = useState(false);
    const [isMetric, setIsMetric] = useState([]);
    const [isData, setIsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchStudentData(id);
        fetchAllData(id);
    }, []);

    const activeSubscription = {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
        dataUnit: "USD",
        stacked: true,
        datasets: [
            {
                label: "Active User",
                barPercentage: 0.7,
                categoryPercentage: 0.7,
                backgroundColor: [
                    "#EDE9F1",
                    "#EDE9F1",
                    "#EDE9F1",
                    "#EDE9F1",
                    "#EDE9F1",
                    "#275298",
                ],
                data: [8200, 7800, 9500, 5500, 9200, 9690],
            },
        ],
    };

    const newData = [
        {
            name: "Assigned",
            count: isMetric ? isMetric.assignedAssignmentsCount : 0,
            icon: <AssignmentIcon style={{ color: "white" }} fontSize="large" />,
            iconColor: "#4d44b5",
        },
        {
            name: "Overdue",
            count: isMetric ? isMetric.overduePaymentsCount : 0,
            icon: <PendingActionsIcon style={{ color: "white" }} fontSize="large" />,
            iconColor: "#FB7D5B",
        },
        {
            name: "Submitted",
            icon: <CloudUploadIcon style={{ color: "white" }} fontSize="large" />,
            count: isMetric ? isMetric.submittedAssignmentsCount : 0,
            iconColor: "#FCC43E",
        },
        {
            name: "Reviewed",
            icon: <CoPresentIcon style={{ color: "white" }} fontSize="large" />,
            count: isMetric ? isMetric.reviewedAssignmentsCount : 0,
            iconColor: "#303972",
        },
    ];

    const fetchStudentData = async (id) => {
        console.log("Fetching data for student ID:", id);
        try {
            const response = await getByStudent(accessToken.token, id);
            const responseData = await response.json();
            console.log(responseData);
            if (response.ok) {
                setIsMetric(responseData.message.metrics);
                setIsData(responseData.message.student);
            } else {
                console.error("Failed to fetch student data:", responseData.message);
            }
        } catch (err) {
            console.error("Error fetching student data:", err);
        }
    };

    const fetchAllData = async (id) => {
        console.log("Fetching data for student ID:", id);

        setIsLoading(true);
        try {
            const response = await getStudentAssignment(accessToken.token, id);
            const responseData = await response.json();
            console.log("API response:", responseData);

            if (response.ok && responseData.message.length > 0) {
                const headers = Object.keys(responseData.message[0]);
                console.log("Headers:", headers);
                const rows = responseData.message
                    .map((dataItem, index) => ({
                        id: index,
                        ...dataItem,
                    }))
                    .reverse();
                console.log("Rows:", rows);

                setRowData(rows);

                const formatDate = (dateString) => {
                    const date = new Date(dateString);
                    return date.toLocaleString("en-GB", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    });
                };

                const columns = [
                    ...headers.map((header) => {
                        if (header === "assignmentId") {
                            return {
                                field: "assignmentId",
                                headerName: "Name",
                                flex: 1,
                                width: 100,
                                renderCell: (params) => (
                                    <div>
                                        <p className="assignment-name">
                                            {params.row?.assignmentId?.name}
                                        </p>
                                    </div>
                                ),
                            };
                        }
                        else if (header === "scheduleDate") {
                            return {
                                field: "scheduleDate",
                                headerName: "Schedule Date",
                                flex: 1,
                                width: 100,
                                renderCell: (params) => (
                                    <div>
                                        <p className="tabel-cell">
                                            {params.row?.scheduleDate ? formatDate(params.row?.scheduleDate) : ''}
                                        </p>
                                    </div>
                                ),
                            };
                        } else if (header === "dueDate") {
                            return {
                                field: "dueDate",
                                headerName: "Due Date",
                                flex: 1,
                                width: 100,
                                renderCell: (params) => (
                                    <div>
                                        <p className="tabel-cell">
                                            {formatDate(params.row?.dueDate)}
                                        </p>
                                    </div>
                                ),
                            };
                        } else if (header === "status") {
                            return {
                                field: "status",
                                headerName: "Status",
                                flex: 1,
                                width: 100,
                                renderCell: (params) => (
                                    <div>
                                        <p className="table-cell mt-3 textcolor">
                                            {params.row.status.charAt(0).toUpperCase() +
                                                params.row.status.slice(1)}
                                        </p>
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                field: header,
                                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                                flex: 1,
                                width: 100,
                            };
                        }
                    }),
                ];

                const desiredOrder = ["assignmentId", "scheduleDate", "dueDate", "status"];
                const orderedColumns = desiredOrder.map((key) => columns.find((col) => col.field === key));

                setColumnDefs(
                    orderedColumns.filter(
                        (item) =>
                            item.field !== "__v" &&
                            item.field !== "_id" &&
                            item.field !== "classId" &&
                            item.field !== "createdAt" &&
                            item.field !== "updatedBy" &&
                            item.field !== "studentId" &&
                            item.field !== "updatedAt" &&
                            item.field !== "uploadedFiles" &&
                            item.field !== "originalFile"
                    )
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setIsLoading(false);
    };

    const back = () => {
        localStorage.setItem("isActive", "student");
        window.history.back();
    };

    return (
        <>
            <Snackbar />
            <Container>
                <Row>
                    <Col>
                        <BasicButton
                            baseStyle="submit-save-btn"
                            value="Back"
                            onClick={back}
                        />
                    </Col>
                </Row>
                <Row className="row-block mt-3 mx-0">
                    {newData.map((item, index) => {
                        return (
                            <Col md={6} lg={3} key={index} className="column-block">
                                <Block data={item} activeSubscription={activeSubscription} />
                            </Col>
                        );
                    })}
                </Row>
                <Row className="mt-3">
                    <Col>
                        <div>
                            <div className="profile-banner">
                                <div className="d-flex justify-content-end align-items-end">
                                    <div className="profile-banner1">
                                        <svg
                                            width="264"
                                            height="59"
                                            viewBox="0 0 264 59"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                y="30.435944"
                                                width="263.592"
                                                height="60.13"
                                                rx="20"
                                                fill="#FB7D5B"
                                            ></rect>
                                        </svg>
                                    </div>
                                    <div className="profile-banner2">
                                        <svg
                                            width="264"
                                            height="59"
                                            viewBox="0 0 264 59"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <rect
                                                x="0.0107422"
                                                y="20.435944"
                                                width="263.592"
                                                height="100.13"
                                                rx="20"
                                                fill="#FCC43E"
                                            ></rect>
                                        </svg>
                                    </div>
                                </div>
                                <div className="student-details">
                                    <div>
                                        <img
                                            src={isData?.imageURL ? isData.imageURL : defaultImage}
                                            alt="profile"
                                            className="student-profile"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="profile-details">
                                <div className="profile-detail-block">
                                    <h4>
                                        {isData?.firstName}&nbsp;{isData?.lastName}
                                    </h4>
                                    <span>Student</span>
                                </div>
                                <div className="d-flex">
                                    <div className="profile-details-text">
                                        <span>Parents Name</span>
                                        <p className="gap-3">
                                            {isData?.parent?.firstName}&nbsp;
                                            {isData?.parent?.lastName}
                                        </p>
                                    </div>
                                    <div className="profile-details-text">
                                        <span>Address</span>
                                        <p>
                                            <i className="fas fa-map-marker-alt icon"></i>&nbsp;
                                            &nbsp;{isData?.parent?.address}
                                        </p>
                                        <p>
                                            <i className="fas fa-envelope icon"></i>&nbsp; &nbsp;
                                            {isData?.parent?.email}
                                        </p>
                                        <p>
                                            <i className="fas fa-phone icon"></i>&nbsp; &nbsp;
                                            {isData?.parent?.phone}
                                        </p>
                                    </div>
                                    <div className="profile-details-text">
                                        <span>Batch</span>
                                        <p>{isData?.schoolYear}</p>
                                    </div>

                                    <div className="profile-details-text">
                                        <span>Course</span>
                                        <p>
                                            {isData?.courses && isData.courses.length > 0
                                                ? isData.courses[0].name
                                                : "No course available"}
                                        </p>
                                    </div>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="mt-2">
                        <div className="dashboard d-flex align-items-center gap-1">
                            <FaArrowUp className="up-arrow" />
                            <h6>Recent Assignments</h6>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="mt-2">
                        <ParentTable
                            rows={rowData}
                            columns={columnDefs}
                            isLoading={isLoading}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default StudentProfileBoard;
