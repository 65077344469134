import { Pagination, Stack } from "@mui/material";
import React from "react";

function Basicpagination(props) {
  const { isPage, handlePageChange } = props;
  return (
    <Stack spacing={2} className="mt-3">
      <Pagination
        sx={{
          ".MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "#4D44B5",
            color: "white",
            border: "none",
            borderRadius: "0.5rem",
            fontFamily: "poppins,sans-serif",
          },
          ".MuiPaginationItem-root": {
            border: "none",
          },
        }}
        count={1}
        defaultPage={isPage}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
      />
    </Stack>
  );
}

export default Basicpagination;
