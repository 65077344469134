import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import "./index.css";
import logo from "../../../../../Assests/icon/Seminar-pana.png";
import { useNavigate } from "react-router-dom";
import Basicpagination from "../../../../../Components/Pagination/Basicpagination";
import { getAllClass } from "../../../../../Service/BaseService/BaseService";
function ClassRoom() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const navigate = useNavigate();
  const [isData, setIsData] = useState([]);

  useEffect(() => {
    getClass();
  }, []);

  const addClass = (id) => {
    if (id) {
      return navigate(`/add-batch?id=${id}`);
    } else {
      return navigate(`/add-batch`);
    }
  };
  const getClass = async () => {
    try {
      const response = await getAllClass(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        setIsData(responseData.message);
        console.log(responseData);
      }
    } catch (err) {
      throw err;
    }
  };
  return (
    <Container>
      <Row>
        <Col>
          <div className="product">
            <SearchBar onClick={() => addClass(null)} value={"Batch"} />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        {isData?.map((item, index) => {
          return (
            <Col md={3} className="mb-2" key={index}>
              <div className="class-card">
                <div className="batch-icon"></div>
                <div className="class-card-box" >
                  <img
                    src={logo}
                    alt="profile"
                    onClick={() => addClass(item._id)}
                    style={{ cursor: "pointer" }}
                  />
                  <p className="item-title">{item.title}</p>
                  <span>{item.description}</span>
                  {/* {item.condition.map((item, index) => {
                    return (
                      <div className="class-describe" key={index}>
                        <span>Subject: {item.subject.name}</span>
                        <span>Start time: {item.starttime}</span>
                        <span>End time: {item.endtime}</span>
                        <span>Tutor: {item.tutor?.firstName ?? ""}</span>
                      </div>
                    );
                  })} */}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col>
          <div className="mt-2 d-flex justify-content-end">
            <Basicpagination />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ClassRoom;
