import React from "react";
import logo from "../../Assests/icon/60111.jpg";

function PreviewBox(props) {
  const { data } = props;

  return (
    <div className="previewBox">
      <div className="d-flex flex-column align-items-center gap-2">
        <img
          src={logo}
          alt="logo"
          style={{ borderRadius: "50%" }}
          className="tutor-profile"
        />
        <p>{data.Name}</p>
        <span>{data.role}</span>
        <div className="d-flex gap-2">
          {data.class.map((item, index) => {
            return (
              <div className="performance" key={index}>
                {item.performance}
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex justify-content-center gap-3 mt-3">
        <div className="profile-card-btn-prf">{data.profile}</div>
        <div className="profile-card-btn-rem">Remove</div>
      </div>
    </div>
  );
}

export default PreviewBox;
