// Assuming Snackbar component is implemented correctly

import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Block from "../../../../Components/Block/Block";
import BasicTable from "../../../../Components/Table/BasicTable";
import { FaArrowUp } from "react-icons/fa6";
import PreviewCard from "../../../../Components/PreviewCard/PreviewCard";
import AreaChartBasic from "../../../../Components/Chart/AreaChart";
import AdvanceTable from "../../../../Components/Table/AdvanceTable";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import DescriptionIcon from "@mui/icons-material/Description";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import Box from "../../../../Components/Box/Box";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../Components/Snackbar/Snackbar";

import {
  getLastestStudent,
  sendNotificationSchedule,
  getpaymentStatus,
  getMetrics,
} from "../../../../Service/BaseService/BaseService";
import { useNavigate } from "react-router-dom";

function Admindashboard() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(localStorage.getItem("User"))?.token;
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPage, setIsPage] = useState(1);
  const [isCount, setIsCount] = useState(10);
  const [isResponseData, setIsResponseData] = useState(null);
  const [isMetrics, setMetrics] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const actionBtn = useRef(null);
  const actionData = [{ Notify: "Notify" }];

  useEffect(() => {
    const visited = localStorage.getItem("visite");
    if (!visited) {
      notifySucess("Login Successful");
      setTimeout(() => {
        localStorage.setItem("visite", true);
      }, 2000);
    }
    fetchAllData();
    fetchMetrics();
  }, []);
  const activeSubscription = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        label: "Active User",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: [
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#275298",
        ],
        data: [8200, 7800, 9500, 5500, 9200, 9690],
      },
    ],
  };

  const fetchMetrics = async () => {
    try {
      const response = await getMetrics(userToken);
      const responseData = await response.json();
      if (response.ok) {
        setMetrics(responseData.message);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fetchAllData = async (page = 1, limit = 10) => {
    setIsLoading(true);
    try {
      const response = await getpaymentStatus(userToken, page, limit, 'pending');
      const responseData = await response.json();
      if (response.ok && responseData.message.results.length > 0) {
        setIsResponseData(responseData.message);

        const columns = [
          {
            field: "studentName",
            headerName: "Student",
            width: 90,
            flex: 1,
          },
          {
            field: "batch",
            headerName: "Batch",
            width: 90,
            flex: 1,
          },
          {
            field: "course",
            headerName: "Course",
            width: 90,
            flex: 1,
          },
          {
            field: "dueAmount",
            headerName: "Amount",
            width: 90,
            flex: 1,
            align: 'center',
          },
          {
            field: "dueDate",
            headerName: "Due Date",
            width: 90,
            flex: 1,
          },
          {
            field: "status",
            headerName: "Status",
            width: 90,
            flex: 1,
          },
        ];

        const rows = responseData.message.results.map((dataItem, index) => ({
          id: dataItem._id,
          studentName: dataItem?.studentId?.firstName,
          batch: dataItem?.batchId[0]?.title,
          course: dataItem?.courseId?.name,
          dueAmount: dataItem?.amount,
          dueDate: dataItem?.dueDate,
          status: dataItem?.status.charAt(0).toUpperCase() + dataItem?.status.slice(1),
          action: dataItem?.action,
        }));

        setColumnDefs(columns);
        setRowData(rows);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };


  const handleRowSelectionModelChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handlePageChange = (e, value) => {
    if (e.target.name === "show") {
      const newCount = parseInt(e.target.value);
      setIsCount(newCount);
      fetchAllData(isPage, newCount);
    } else {
      setIsPage(value);
      fetchAllData(value, isCount);
    }
  };

  const newData = [
    {
      name: "Courses",
      icon: <DescriptionIcon style={{ color: "white" }} fontSize="large" />,
      count: isMetrics?.courses ?? 0,
      iconColor: "#FCC43E",
    },
    {
      name: "Batches",
      icon: <CoPresentIcon style={{ color: "white" }} fontSize="large" />,
      count: isMetrics?.batches ?? 0,
      iconColor: "#303972",
    },
    {
      name: "Tutors",
      count: isMetrics?.tutors ?? 0,
      icon: <PersonIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#FB7D5B",
    },
    {
      name: "Students",
      count: isMetrics?.students ?? 0,
      icon: <SchoolIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#4d44b5",
    },
  ];
  const scheduleNotifyPayment = async () => {
    setIsLoading(true);
    try {
      const data = {
        scheduleIds: selectedRows,
      };
      const response = await sendNotificationSchedule(userToken, data);
      const responseData = await response.json();
      if (response.ok) {
        notifySucess(responseData.message);
        setSelectedRows([]);
        // setTableKey((prevKey) => prevKey + 1);
      } else {
        notifyError(responseData.error);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const schedulePaid = async () => {
    setIsLoading(true);
    try {
      // const data = {
      //   scheduleIds: selectedRows,
      // };
      // const response = await sendNotificationSchedule(userToken, data);
      // const responseData = await response.json();
      // if (response.ok) {
      //   notifySucess(responseData.message);
      //   setSelectedRows([]);
      //   // setTableKey((prevKey) => prevKey + 1);
      // } else {
      //   notifyError(responseData.error);
      // }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <Snackbar />
      <Container>
        <Row className="row-block mt-3 mx-0">
          {newData.map((item, index) => (
            <Col md={6} lg={3} key={index} className="column-block">
              <Block data={item} activeSubscription={activeSubscription} />
            </Col>
          ))}
        </Row>

        <Row className="mt-3 justify-content-end align-items-center d-flex">
          <Col className="mt-2">
            <div className="d-flex  gap-1">
              <FaArrowUp className="up-arrow" />
              <h6>Payment In Due</h6>
            </div>
          </Col>
          <Col className="mt-2  justify-content-end align-items-center d-flex">
            <Col className="justify-content-end align-items-center d-flex" style={{ paddingRight: "10px" }}>
              <input
                type="submit"
                className="submit-save-btn"
                value={"Mark as Paid"}
                onClick={() => schedulePaid()}
                disabled={!selectedRows.length}
                style={{ filter: selectedRows.length ? "none" : "brightness(75%)" }}
              />
            </Col>
            <Col md="4">
              <input
                type="submit"
                className="submit-save-btn"
                value={"Send Payment Reminder"}
                onClick={() => scheduleNotifyPayment()}
                disabled={!selectedRows.length}
                style={{ filter: selectedRows.length ? "none" : "brightness(75%)" }}
              />
            </Col>
          </Col>
        </Row>

        <Row>
          <Col className="mt-2" >
            <AdvanceTable
              response={isResponseData}
              rows={rowData}
              columns={columnDefs}
              isLoading={isLoading}
              isPage={isPage}
              handlePageChange={handlePageChange}
              isCount={isCount}
              checkbox={true}
              onRowSelectionModelChange={handleRowSelectionModelChange}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Admindashboard;
