import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import BasicTable from "../../../../../Components/Table/BasicTable";
import { FaArrowUp } from "react-icons/fa6";
import FaceIcon from "@mui/icons-material/Face";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Block from "../../../../../Components/Block/Block";
import BasicTab from "../../../../../Components/Tab/Tab";
import {
  getAssignmentDetails,
  getBatchDetails,
  getClassStudent,
} from "../../../../../Service/BaseService/BaseService";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DetailsTable,
  UploadBox,
} from "../../../../../Components/UploadBox/UploadBox";
function ClassRoomDetails() {
  const user = localStorage.getItem("User");
  const userToken = JSON?.parse(user);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [infoData, setInfoData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [studentRowData, setStudentRowData] = useState([]);
  const [studentColumnDefs, setStudentColumnDefs] = useState([]);
  const [tutorRowData, setTutorRowData] = useState([]);
  const [tutorColumnDefs, setTutorColumnDefs] = useState([]);
  const [assignmetRowData, setAssignmentRowData] = useState([]);
  const [assignmentColumnDefs, setAssignmentColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDetails, setIsDetails] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [rowIds, setRowIds] = useState(null);

  const [isActive, setIsActive] = useState(
    localStorage.getItem("isActive") || "info"
  );
  useEffect(() => {
    localStorage.setItem('rowIds', JSON.stringify(rowIds));
  }, [rowIds]);
  const location = useLocation();
  const getParams = new URLSearchParams(location.search);
  const id = getParams.get("id");

  useEffect(() => {
    // fetchAllData();
    fetchStudentData();
  }, []);
  const handleSelectionChange = (id) => {
    const updated = rowData.filter((item, index) => id.includes(item.id));
  };
  const navigateHandler = (id) => {
    navigate(`/assignment-details?id=${id}`);
  };
  const navigateHandlerProfile = () => {
    navigate(`/TutorInfo`);
  };

  const fetchAllData = async (page, limit) => {
    console.log("its this api call happens")
    setIsLoading(true);
    // const request = {
    //   page: page ? page : 1,
    //   limit: limit ? limit : 10,
    // };
    try {
      const response = await getAssignmentDetails(userToken.token);
      const responseData = await response.json();
      if (response.ok && responseData.message.length > 0) {

        const headers = Object.keys(responseData.message[0]);

        const additionalColumnEdit = {
          field: "Remove",
          headerName: "Remove",
          width: 80,
          // flex: 1,
          renderCell: (params) => {
            return (
              <div
                className="tooltipbox"
              // onClick={() => removeHandler(params.row._id)}
              >
                <svg
                  className="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  height="25"
                  width="25"
                >
                  <path
                    d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            );
          },
        };
        const additionalColumnRemove = {
          field: "Edit",
          headerName: "Edit",
          width: 80,
          // flex: 1,
          renderCell: (params) => {
            return (
              <div
                class="button"
                onClick={() =>
                  navigate(
                    `/assignment-editor?id=${params.row.uploadedFiles[0]._id}`
                  )
                }
              >
                <svg
                  class="svg-icon"
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#4D44B5"
                    stroke-linecap="round"
                    stroke-width="2"
                    className="stroke-color"
                  >
                    <path d="m20 20h-16"></path>
                    <path
                      clip-rule="evenodd"
                      d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
            );
          },
        };
        // const additionalColumnUpdate = {
        //   field: "additionalFieldUpdate",
        //   headerName: "Action",
        //   flex: 1,
        //   width: 100,
        //   renderCell: (params) => {
        //     return (
        //       <div
        //         className="action-icon-box"
        //         onClick={() => btnClick(params.row.id)}
        //       >
        //         <i className="action-icon"></i>
        //       </div>
        //     );
        //   },
        // };

        const columns = headers?.map((header) => {
          if (header === "classId") {
            return {
              field: "classId",
              headerName: "Assignment Name",
              flex: 1,
              width: 180,
              renderCell: (params) => {
                return (
                  <div>
                    <p className="tabel-cell">
                      {params?.row?.assignmentId?.name}
                    </p>
                  </div>
                );
              },
            };
          } else {
            return {
              field: header,
              headerName: header.charAt(0).toUpperCase() + header.slice(1),
              flex: 1,
              width: 180,
            };
          }
        });

        if (additionalColumnEdit && additionalColumnRemove) {
          // columns.push(additionalColumnDownload);
          columns.push(additionalColumnRemove);
          columns.push(additionalColumnEdit);
        }

        const rows = responseData.message?.map((dataItem, index) => ({
          id: index,
          ...dataItem,
        }));

        setColumnDefs(
          columns.filter(
            (item, index) =>
              item.field !== "__v" &&
              item.field !== "_id" &&
              item.field !== "assignmentURL" &&
              item.field !== "createdAt" &&
              item.field !== "assignmentId" &&
              item.field !== "studentId" &&
              item.field !== "originalFile" &&
              item.field !== "uploadedFiles" &&
              item.field !== "updatedBy" &&
              item.field !== "classRoomId" &&
              item.field !== "studentIds" &&
              item.field !== "updatedAt" &&
              item.field !== "createdBy"
          )
        );
        setRowData(rows);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
    setIsLoading(false);
  };
  const getAssignment = async (id) => {
    try {
      const response = await getAssignmentDetails(
        userToken.token,
        `assignmentId=${id}`
      );
      const responseData = await response.json();
      if (response.ok) {
        setIsModal(true);
        const headers = Object.keys(responseData.assignments[0]);
        const columns = headers.map((header) => {
          switch (header) {
            case "assignmentId":
              return {
                field: "assignmentId",
                headerName: "Assignment Name",
                flex: 1,
                minWidth: 150,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.assignmentId?.name}
                    </p>
                  </div>
                ),
              };
            case "studentId":
              return {
                field: "studentId",
                headerName: "student",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.studentId?.firstName}
                    </p>
                  </div>
                ),
              };
            case "dueDate":
              return {
                field: "dueDate",
                headerName: "Due Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.dueDate.split("T")[0]}
                    </p>
                  </div>
                ),
              };
            case "originalFile":
              return {
                field: "originalFile",
                headerName: "File",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.originalFile?.map((item) => item.filename)}
                    </p>
                  </div>
                ),
              };
            case "uploadedFiles":
              return {
                field: "uploadedFiles",
                headerName: "Uploaded File",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row.uploadedFiles?.map((item) => item.filename)}
                    </p>
                  </div>
                ),
              };
            default:
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 180,
              };
          }
        });
        columns.push({
          field: "additionalFieldUpdate",
          headerName: "Editor",
          width: 100,
          renderCell: (params) => {
            return (
              <div
                class="button"
                onClick={() =>
                  navigate(
                    `/assignment-editor?id=${params.row.uploadedFiles[0]._id}`
                  )
                }
              >
                <svg
                  class="svg-icon"
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#4D44B5"
                    stroke-linecap="round"
                    stroke-width="2"
                    className="stroke-color"
                  >
                    <path d="m20 20h-16"></path>
                    <path
                      clip-rule="evenodd"
                      d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
            );
          },
        });
        const rows = responseData.assignments.map((dataItem) => ({
          id: dataItem._id,
          ...dataItem,
        }));

        setAssignmentColumnDefs(
          columns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "parent",
                "updatedBy",
                "scheduleDate",
                "parentId",
                "createdAt",
                "updatedAt",
                "classId",
              ].includes(item.field)
          )
        );
        setAssignmentRowData(rows);
      }
    } catch (err) {
      console.error(err);
    }
  };
  const fetchStudentData = async () => {
    setIsLoading(true);
    try {
      const response = await getBatchDetails(userToken.token, id);
      const responseData = await response.json();
      if (
        response.ok &&
        responseData.message?.assignedStudents[0]?.studentId?.length > 0
      ) {
        const headers = Object.keys(
          responseData.message.assignedStudents[0].studentId[0]
        );
        const columns = headers.map((header) => {
          return {
            field: header,
            headerName: header.charAt(0).toUpperCase() + header.slice(1),
            flex: 1,
            width: 180,
          };
          // }
        });
        const parentColumns = [
          {
            field: "name",
            headerName: "Parent Name",
            flex: 1,
            width: 200,
          },
          {
            field: "email",
            headerName: "Parent Email",
            flex: 1,
            width: 200,
          },
          {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            width: 200,
          },
          {
            field: "additionalFieldUpdate",
            headerName: "View",
            flex: 1,
            width: 100,
            renderCell: (params) => {
              return (
                <div
                  class="button visibility-icon"
                  onClick={() =>
                    navigate("/studentInfo", { state: { id: params.row.id } })
                  }
                >
                  <VisibilityIcon />
                </div>
              );
            },
          },
        ];
        columns.push(...parentColumns);

        const rows = responseData.message.assignedStudents[0].studentId?.map(
          (dataItem) => ({
            id: dataItem._id,
            ...dataItem,
            name: dataItem?.parent?.firstName ?? "",
            email: dataItem?.parent?.email ?? "",
            phone: dataItem?.parent?.phone,
          })
        );

        setStudentColumnDefs(
          columns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "parent",
                "parentId",
                "createdAt",
                "updatedAt",
                "imageURL",
              ].includes(item.field)
          )
        );
        setStudentRowData(rows);
      }
      if (response.ok && responseData.message?.assignments?.length > 0) {
        const headers = Object.keys(responseData?.message?.assignments[0]);
        const columns = headers.map((header) => {
          switch (header) {
            case "scheduleDate":
              return {
                field: "scheduleDate",
                headerName: "Assigned Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.scheduleDate}</p>
                  </div>
                ),
              };
            case "dueDate":
              return {
                field: "dueDate",
                headerName: "Due Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.dueDate}</p>
                  </div>
                ),
              };
            case "schedule":
              return {
                field: "schedule",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.schedule}</p>
                  </div>
                ),
              };
            case "name":
              return {
                field: "name",
                headerName: "Name",
                minWidth: 100,
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.name}</p>
                  </div>
                ),
              };
            case "totalAssigned":
              return {
                field: "totalAssigned",
                headerName: "#Assigned",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.totalAssigned}</p>
                  </div>
                ),
              };
            case "totalSubmitted":
              return {
                field: "totalSubmitted",
                headerName: "#Submitted",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.totalSubmitted}</p>
                  </div>
                ),
              };
            case "totalReviewed":
              return {
                field: "totalReviewed",
                headerName: "#Reviewed",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.totalReviewed}</p>
                  </div>
                ),
              };
            case "totalInReview":
              return {
                field: "totalInReview",
                headerName: "#In-review",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">
                      {params.row?.totalInReview ?? 0}
                    </p>
                  </div>
                ),
              };
            default:
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 180,
              };
          }
        });
        columns.push({
          field: "additionalFieldUpdate",
          headerName: "View",
          flex: 1,
          width: 100,
          renderCell: (params) => {
            return (
              <div
                class="button visibility-icon"
                onClick={() => navigateHandler(params.row._id)}
              >
                <VisibilityIcon />
              </div>
            );
          },
        });
        const rows = responseData?.message?.assignments?.map((dataItem) => ({
          id: dataItem._id,
          ...dataItem,
        }));

        const desiredOrder = [
          "name",
          "description",
          "scheduleDate",
          "dueDate",
          "schedule",
          "totalAssigned",
          "totalSubmitted",
          "totalReviewed",
          "totalInReview",
          "additionalFieldUpdate",
        ];
        const orderedColumns = desiredOrder.map((key) =>
          columns.find((col) => col.field === key)
        );

        setColumnDefs(
          orderedColumns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "classRoomId",
                "parentId",
                "createdAt",
                "updatedAt",
                "createdBy",
                "updatedBy",
                "studentIds",
                "assignmentURL",
                "imageURL",
              ].includes(item.field)
          )
        );
        setRowData(rows);
      }
      if (response.ok && responseData.message?.condition?.length > 0) {
        const headers = Object.keys(responseData?.message?.condition[0]?.tutor);
        const columns = headers.map((header) => {
          switch (header) {
            case "firstName":
              return {
                field: "firstName",
                headerName: "First Name",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row?.firstName}</p>
                  </div>
                ),
              };
            case "course":
              return {
                field: "course",
                headerName: "Course",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.course?.name}</p>
                  </div>
                ),
              };
            case "condition":
              return {
                field: "condition",
                headerName: "Condition",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.condition?.name}</p>
                  </div>
                ),
              };
            default:
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 180,
              };
          }
        });

        columns.push({
          field: "additionalFieldUpdate",
          headerName: "View",
          flex: 1,
          width: 100,
          renderCell: (params) => {
            return (
              <div
                className="button visibility-icon"
                onClick={navigateHandlerProfile}
              >
                <VisibilityIcon />
              </div>
            );
          },
        });

        const rows = (responseData.message.condition || [])
          .map((item) => item.tutor || {})
          .map((dataItem) => ({
            id: dataItem._id || '',
            ...dataItem,
          }));

        setTutorColumnDefs(
          columns.filter(
            (item) =>
              ![
                "__v",
                "_id",
                "password",
                "activationCode",
                "createdBy",
                "isActive",
                "role",
                "status",
                "__t",
                "parentId",
                "gender",
                "dateOfBirth",
                "qualifications",
                "createdAt",
                "updatedAt",
              ].includes(item.field)
          )
        );
        setTutorRowData(rows);
        rows.forEach((row) => {
          if (row._id) {
            setRowIds(row._id);
          }
        });
      }
      if (response.ok && responseData.message?.info) {
        setInfoData(responseData.message);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const newData = [
    {
      name: "Assignment",
      count: "15+",
      icon: <AssignmentIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#4d44b5",
    },
    {
      name: "Pending",
      count: "10",
      icon: <PendingActionsIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#FB7D5B",
    },
    {
      name: "Upload",
      icon: <CloudUploadIcon style={{ color: "white" }} fontSize="large" />,
      count: "20",
      iconColor: "#FCC43E",
    },
    {
      name: "Student",
      icon: <FaceIcon style={{ color: "white" }} fontSize="large" />,
      count: "45",
      iconColor: "#303972",
    },
  ];
  return (
    <Container>
      <Row>
        <Col md={12}>
          <BasicTab
            isActive={isActive}
            dashboard={newData}
            student={{ row: studentRowData, col: studentColumnDefs }}
            tutor={{ row: tutorRowData, col: tutorColumnDefs }}
            assignment={{ row: rowData, col: columnDefs }}
            info={infoData}
            fetchStudentData={fetchStudentData}
          />
          <DetailsTable
            handleSelectionChange={handleSelectionChange}
            isModal={isModal}
            setIsModal={setIsModal}
            assignment={{ row: assignmetRowData, col: assignmentColumnDefs }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ClassRoomDetails;
