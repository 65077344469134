import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./RegisterForm.css";
import BasicButton from "../../../../Components/Button/Button";
import profile from "../../../../Assests/icon/profile-picture.png";
import { FaCamera } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import CardBlock from "../../../../Components/CardBlock/CardBlock";
function TutorProfile() {
  const location = useLocation();
  const getParams = new URLSearchParams(location.search);
  const getId = getParams.get("id");
  const [isCollapse, setIsCollapse] = useState(0);
  const isForm = {
    name: "",
    surname: "",
    gender: "",
    birth: "",
    academic: "",
    currentSchool: "",
  };
  const [isInput, setIsInput] = useState([isForm]);
  const addRowHandler = () => {
    const visible = isInput.length;
    setIsCollapse(visible);
    setIsInput((prev) => [...prev, isForm]);
  };
  const removeRowHandler = (i) => {
    setIsInput((prev) => {
      return prev.filter((item, index) => index !== i);
    });
  };
  const collapseRowHandler = (i) => {
    setIsCollapse((prev) => {
      if (prev === i) {
        return null;
      }
      return i;
    });
  };
  return (
    <Container>
      <Row>
        <Col md={12}>
          <form>
            <div>
              <div className="profile-form">
                <h5>Add Profile</h5>
                <div className="tutor-profile-form">
                  <div>
                    <div>
                      <img
                        src={profile}
                        alt="profile"
                        className="tutor-profile-img"
                      />
                      <div className="camera-icon-block">
                        <label>
                          <input
                            type="file"
                            className="hide-input"
                            // onChange={props.uploadImageHandler}
                          />
                          <span>
                            <FaCamera className="camera-icon" />
                          </span>
                        </label>
                      </div>
                    </div>
                    <p>{`Name :`} </p>
                    <span>Course : </span>
                    <span>{`D.O.B : `}</span>
                    <span>{`Mobile No : `}</span>
                  </div>
                  <div>
                    <div className="registration-setup">
                      <div>
                        <label>Name</label>
                        <input className="form-control" />
                      </div>
                      <div>
                        <label>Surname</label>
                        <input className="form-control" />
                      </div>
                    </div>
                    <div className="registration-setup">
                      <div>
                        <label>Gender</label>
                        <select
                          className="form-control select-block"
                          name="gender"
                          required
                        >
                          <option>Male</option>
                          <option>Female</option>
                          <option>Others</option>
                        </select>
                      </div>
                      <div>
                        <label>Birth</label>
                        <input className="form-control" />
                      </div>
                    </div>
                    <div className="registration-setup">
                      <div>
                        <label>Phone</label>
                        <input className="form-control" />
                      </div>
                      <div>
                        <label>Email address</label>
                        <input className="form-control" />
                      </div>
                    </div>
                    <div className="registration-setup">
                      <div>
                        <label>City</label>
                        <input className="form-control" />
                      </div>
                      <div>
                        <label>Address</label>
                        <input className="form-control" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="save-border p-3">
                  <BasicButton
                    value={getId ? "Update" : "Save"}
                    baseStyle="profile-save"
                  />
                </div>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default TutorProfile;
