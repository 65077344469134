import React, { useEffect, useState, useRef } from "react";
import { message } from "antd";
import {
  fetchDataTutor,
  getById,
  updateTutor,
  deleteTutor
} from "../../../../../Service/BaseService/BaseService";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import "./index.css";
import { Col, Container, Modal, Row, Form, Button } from "react-bootstrap";
import BasicModal from "../../../../../Components/Modal/Modal";
import Snackbar from "../../../../../Components/Snackbar/Snackbar";
import Box from "../../../../../Components/Box/Box";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import Basicpagination from "../../../../../Components/Pagination/Basicpagination";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";

function TutorRecords() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const actionBtn = useRef(null);
  const navigate = useNavigate();
  const [isTutor, setIsTutor] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isPage, setIsPage] = useState(1);
  const [isCount, setIsCount] = useState(10);
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGet, setIsGet] = useState({
    tutor: { firstName: "", email: "", createdAt: "", password: "", phone: "" },
  });
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  useEffect(() => {
    getTutorData();
    const handleClick = (event) => {
      if (actionBtn.current && !actionBtn.current.contains(event.target)) {
        btnClick(null);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const getTutorData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchDataTutor(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        setIsTutor(responseData.tutors);
        const headers = Object.keys(responseData.tutors[0]);

        const additionalColumnUpdate = {
          field: "additionalFieldUpdate",
          headerName: "Action",
          flex: 1,
          width: 100,
          renderCell: (params) => {
            return (
              <div
                className="action-icon-box"
                onClick={() => btnClick(params.row.id)}
              >
                <i className="action-icon"></i>
              </div>
            );
          },
        };

        const columns = headers?.map((header) => {
          switch (header) {
            case "firstName":
              return {
                field: "firstName",
                headerName: "Name",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.firstName}</p>
                  </div>
                ),
              };
            case "email":
              return {
                field: "email",
                headerName: "Email",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row?.email}</p>
                  </div>
                ),
              };
            case "status":
              return {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row?.status.charAt(0).toUpperCase() + params.row.status.slice(1)}</p>
                  </div>
                ),
              };
            case "subjects":
              return {
                field: "subjects",
                headerName: "Subjects",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    {params.row?.subjects?.map((subject, index) => (
                      <p key={index} className="tabel-cell">{subject.name}</p>
                    ))}
                  </div>
                ),
              };
            default:
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 180,
              };
          }
        });
        if (additionalColumnUpdate) {
          columns.push(additionalColumnUpdate);
        }

        const rows = responseData.tutors.map((dataItem, index) => ({
          id: index,
          ...dataItem,
        }));

        const desiredOrder = ["firstName", "email", "status", "subjects", "additionalFieldUpdate"];
        const orderedColumns = desiredOrder.map((key) => columns.find((col) => col.field === key));

        setColumnDefs(
          orderedColumns.filter(
            (item) =>
              item.field !== "__v" &&
              item.field !== "_id" &&
              item.field !== "__t" &&
              item.field !== "createdAt" &&
              item.field !== "updatedAt" &&
              item.field !== "activationCode" &&
              item.field !== "password" &&
              item.field !== "isActive" &&
              item.field !== "createdBy" &&
              item.field !== "qualifications" &&
              item.field !== "role"
          )
        );
        setRowData(rows);

      }
    } catch (err) {
      console.error("Error fetching tutor data:", err);
    }
    setIsLoading(false);
  };

  const btnClick = (id) => {
    const additionalColumnUpdate = {
      field: "additionalFieldUpdate",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="action-icon-box"
            onClick={() => btnClick(params.row.id)}
          >
            <i className="action-icon "></i>
            {params.row.id === id ? (
              <div ref={actionBtn}>
                <Box
                  actionHandler={(actionType) =>
                    actionHandler(params.row._id, actionType, params.row)
                  }
                  actionData={[{ Edit: "Edit", Delete: "Delete" }]}
                />
              </div>
            ) : null}
          </div>
        );
      },
    };
    setColumnDefs((prev) => {
      return prev.map((item) => {
        return item.field === "additionalFieldUpdate"
          ? additionalColumnUpdate
          : item;
      });
    });
  };

  const actionHandler = async (id, actionType) => {
    if (actionType === "Edit") {
      getIdData(id, userToken.token);
      setEditModalVisible(true); // Open edit modal
    } else if (actionType === "Delete") {
      setDeleteItemId(id);
      setShowDeleteModal(true);
    }
  };

  const removetutor = async (id) => {
    try {
      const result = await deleteTutor(id, userToken.token);
      if (result) {
        message.success("Tutor deleted successfully");
        setShowDeleteModal(false);
        getTutorData();
      } else {
        message.error("Failed to delete tutor");
      }
    } catch (err) {
      console.error("Error deleting Tutor:", err);
    }
  };

  const getIdData = async (id, token) => {
    try {
      const response = await getById(id, token);
      if (response) {
        setIsGet(response);
        setIsModal(true);
      }
    } catch (err) {
      console.error("Error getting tutor by ID:", err);
    }
  };

  const changeValue = (e) => {
    setIsGet({ ...isGet, tutor: { ...isGet.tutor, [e.target.name]: e.target.value } });
    console.log("changes", e.target);
  };

  const updateValue = async () => {
    try {
      const data = {
        email: isGet.tutor.email,
        firstName: isGet.tutor.firstName,
        gender: isGet.tutor.gender,
        phone: isGet.tutor.phone,
        dateOfBirth: isGet.tutor.dateOfBirth,
      };


      const response = await updateTutor(userToken.token, data);
      const responseData = await response.json();
      if (response.ok) {
        message.success("Tutor updated successfully");
        setEditModalVisible(false);
        getTutorData();
      } else {
        message.error("Failed to update tutor");
      }
    } catch (err) {
      console.error("Error updating tutor:", err);
    }
  };

  const handlePageChange = (e, value) => {
    if (e.target.name === "show") {
      setIsCount(parseInt(e.target.value));
    } else {
      setIsPage(value);
    }
  };

  const addNewHandler = () => {
    navigate(`/add-tutor?record=tutor`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="product">
            <SearchBar onClick={addNewHandler} value={"Tutor"} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-2">
            <AdvanceTable
              rows={rowData}
              columns={columnDefs}
              isLoading={isLoading}
            />
            <Basicpagination
              showHandler={handlePageChange}
              rowsPerPage={isCount}
              page={isPage}
              total={rowData.length}
            />
          </div>
        </Col>
      </Row>
      {isModal && (
        <BasicModal
          isShow={isModal}
          handleClose={() => setIsModal(false)}
          changeValue={changeValue}
          updateValue={updateValue}
          data={isGet.tutor} // Pass the tutor object directly
        />
      )}
      {editModalVisible && (
        <Modal
          show={editModalVisible}
          onHide={() => setEditModalVisible(false)}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Tutor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col">
                  <Form.Group controlId="formName">
                    <Form.Label className="modal-label">Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="firstName"
                      value={isGet.tutor.firstName}
                      onChange={changeValue}
                      className="modal-label modal-inputCourseModal"
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="formGender">
                    <Form.Label className="modal-label">Gender</Form.Label>
                    <Form.Control
                      as="select"
                      name="gender"
                      value={isGet.tutor.gender ?? "Select"}
                      onChange={changeValue}
                      className="modal-label modal-inputCourseModal"
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </Form.Control>
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Form.Group controlId="formPhone">
                    <Form.Label className="modal-label">Phone</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter phone number"
                      name="phone"
                      value={isGet.tutor.phone}
                      onChange={changeValue}
                      className="modal-label modal-inputCourseModal"
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="formDOB">
                    <Form.Label className="modal-label">
                      Date of Birth
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="createdAt"
                      value={isGet.tutor?.dateOfBirth ? formatDate(isGet.tutor?.dateOfBirth) : ""}
                      onChange={changeValue}
                      className="modal-label modal-inputCourseModal"
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group controlId="formEmail">
                    <Form.Label className="modal-label">Email </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={isGet.tutor.email}
                      onChange={changeValue}
                      className="modal-label modal-inputCourseModal"
                      disabled={true}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group controlId="formPassword">
                    <Form.Label className="modal-label">Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={isGet.tutor.password}
                      onChange={changeValue}
                      className="modal-label modal-inputCourseModal"
                      disabled={true}
                    />
                  </Form.Group>
                </div>
              </div>
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setEditModalVisible(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={updateValue}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Snackbar />
      <DeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        deleteHandler={() => removetutor(deleteItemId)}
        name="Tutor"
      />
    </Container>
  );
}

export default TutorRecords;

