import React, { useEffect, useState, useRef, useMemo } from "react";
import { FaArrowUp } from "react-icons/fa6";
import {
  deleteData,
  fetchData,
  getById,
  updateData,
} from "../../../../Service/BaseService/BaseService";
import AdvanceTable from "../../../../Components/Table/AdvanceTable";
import "./Product.css";
import { Col, Container, Row } from "react-bootstrap";
import BasicModal from "../../../../Components/Modal/Modal";
import Snackbar from "../../../../Components/Snackbar/Snackbar";
import Box from "../../../../Components/Box/Box";
import SearchBar from "../../../../Components/SearchBar/SearchBar";
import { MdOutlineSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Tutorrecord() {
  const userDetails = localStorage.getItem("User");
  const accessToken = JSON.parse(userDetails);
  const actionBtn = useRef(null);
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [rowDataDefault, setRowDataDefault] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isPage, setIsPage] = useState(1);
  const [isCount, setIsCount] = useState(10);
  const [isModal, setIsModal] = useState(false);
  const [isGet, setIsGet] = useState([]);
  const [isFetchAll, setIsFetchAll] = useState(false);
  const actionData = [
    { Edit: "Edit", Delete: "Delete", Dupliacte: "Dupliacte" },
  ];

  useEffect(() => {
    const handleClick = (event) => {
      if (actionBtn.current && !actionBtn.current.contains(event.target)) {
        btnClick(null);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const btnClick = (id) => {
    const additionalColumnUpdate = {
      field: "additionalFieldUpdate",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (params) => {
        return (
          <div
            className="action-icon-box"
            onClick={() => btnClick(params.row.id)}
          >
            <i className="action-icon "></i>
            {params.row.id === id ? (
              <div ref={actionBtn}>
                <Box
                  actionHandler={(actionType) =>
                    actionHandler(params.row.id, actionType)
                  }
                  actionData={actionData}
                />
              </div>
            ) : null}
          </div>
        );
      },
    };
    setColumnDefs((prev) => {
      return prev.map((item) => {
        return item.field === "additionalFieldUpdate"
          ? additionalColumnUpdate
          : item;
      });
    });
  };

  const actionHandler = (id, actionType) => {
    if (actionType === "Edit") {
      getIdData(id);
      // navigate(`/add-tutor?id=${id}`);
    } else if (actionType === "Delete") {
      removeData(id);
    }
  };

  const fetchAllData = async () => {
    try {
      const response = await fetchData(isPage, isCount);
      const responseData = await response.json();
      console.log(responseData);
      if (response.ok && responseData.length > 0) {
        const headers = Object.keys(responseData[0]);
        const capitalizedArray = headers.map((str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        });
        const additionalColumnUpdate = {
          field: "additionalFieldUpdate",
          headerName: "Action",
          flex: 1,
          width: 100,
          renderCell: (params) => {
            return (
              <div
                className="action-icon-box"
                onClick={() => btnClick(params.row.id)}
              >
                <i className="action-icon"></i>
              </div>
            );
          },
        };

        const columns = [
          ...headers.map((header) => ({
            field: header,
            headerName: header,
            flex: 1,
            width: 180,
          })),
          additionalColumnUpdate,
        ];

        const rows = responseData.map((dataItem, index) => ({
          id: index,
          ...dataItem,
        }));

        setColumnDefs(columns.filter((item, index) => item.field !== "id"));
        setRowDataDefault(rows);
        setRowData(rows);
        return rows;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const removeData = async (id) => {
    const response = await deleteData(id);
    const responseData = await response.json();
    try {
      if (!response.ok) {
        console.log(responseData);
      } else {
        setIsFetchAll(!isFetchAll);
      }
    } catch (err) {
      console.error(err);

      throw err;
    }
  };

  const getIdData = async (id) => {
    const response = await getById(id);
    const responseData = await response.json();
    try {
      if (!response.ok) {
        console.log(responseData);
      } else {
        setIsGet(responseData);
        setIsModal(true);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const changeValue = async (e) => {
    setIsGet({ ...isGet, [e.target.name]: e.target.value });
  };

  const updateValue = async (id) => {
    const request = {
      id,
      isGet,
    };
    const response = await updateData(request);
    const responseData = await response.json();
    try {
      if (!response.ok) {
        console.log(responseData);
      } else {
        setIsModal(false);
        setIsFetchAll(!isFetchAll);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handlePageChange = (e, value) => {
    if (e.target.name === "show") {
      setIsCount(parseInt(e.target.value));
    } else {
      setIsPage(value);
    }
  };

  const searchHandler = (value) => {
    if (value === "") {
      setRowData(rowDataDefault);
    } else {
      setRowData((prev) => {
        return prev.filter((item) =>
          item.Assignments.toLowerCase().includes(value.toLowerCase())
        );
      });
    }
  };
  const modalHandler = () => {
    setIsModal(true);
    // navigate(`/add-tutor`);
  };

  return (
    <Container>
      <Row>
        <Col>
          <div className="product">
            <div className="d-flex align-items-center justify-content-between tutor-block">
              <div className="d-flex align-items-center input-flex">
                <div className="search-icon-block">
                  <MdOutlineSearch
                    style={{ fontSize: "25px", color: "#3D3690" }}
                  />
                </div>
                <input className="input-block" placeholder="Search here..." />
              </div>
              <div onClick={modalHandler}>
                <div className="new-add-btn">+ Upload Assignment</div>
              </div>
            </div>
            <div className="mt-2">
              <AdvanceTable
                rows={rowData}
                columns={columnDefs}
                isPage={isPage}
                handlePageChange={handlePageChange}
                isCount={isCount}
              />
              <BasicModal
                isModal={isModal}
                isGet={isGet}
                changeValue={changeValue}
                setIsModal={setIsModal}
                updateValue={updateValue}
              />
              <Snackbar />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Tutorrecord;
