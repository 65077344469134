import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import MoneyIcon from "@mui/icons-material/Money";
import DescriptionIcon from "@mui/icons-material/Description";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssignmentIcon from "@mui/icons-material/Assignment";
import MenuBookIcon from "@mui/icons-material/MenuBook";
export const routes = [
  {
    link: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon fontSize="small" />,
    route: [],
    active: "/dashboard",
  },

  {
    link: "/tutor",
    name: "Tutor",
    icon: <PersonIcon fontSize="small" />,
    route: [],
    active: "/tutor",
  },
  {
    link: "/course",
    name: "Course",
    icon: <DescriptionIcon fontSize="small" />,
    active: "/course",
    route: [],
  },
  {
    active: "/batch",
    link: "/batch",
    name: "Batch",
    icon: <CoPresentIcon fontSize="small" />,
    route: [],
  },
  {
    name: "Student",
    active: "/student",
    link: "/student",
    route: [],
    icon: <SchoolIcon fontSize="small" />,
  },

  // {
  //   link: "/subject",
  //   name: "Subject",
  //   icon: <MenuBookIcon fontSize="small" />,
  //   active: "/subject",
  //   route: [],
  // },
  // {
  //   active: "/classroom",
  //   link: "/classroom",
  //   name: "Class Room",
  //   icon: <CoPresentIcon fontSize="small" />,
  //   route: [],
  // },
  {
    // active: "/classroom",
    active: "/assignment",
    link: "/assignment",
    name: "Assignment",
    icon: <AssignmentIcon fontSize="small" />,
    route: [],
  },
  {
    name: "Payment",
    active: "/payment",
    link: "/payment",
    route: [],
    icon: <MoneyIcon fontSize="small" />,
  },
  {
    name: "Setting",
    icon: <SettingsIcon fontSize="small" />,
    route: [
      { name: "User", link: "#" },
      { name: "Role", link: "#" },
      { name: "Permission", link: "#" },
      { name: "Resourses", link: "#" },
      { name: "Subject", link: "/subject" },
      // { name: "Course", link: "/course" },
    ],
  },

  // {
  //   name: "Roles Management",
  //   icon: <ManageAccountsIcon fontSize="small" />,
  //   route: [
  //     { name: "User", link: "" },
  //     { name: "Role", link: "" },
  //     { name: "Permission", link: "" },
  //     { name: "Resourses", link: "" },
  //   ],
  // },
  // {
  //   // active: "/classroom",
  //   name: "Setting",
  //   icon: <SettingsIcon fontSize="small" />,
  //   route: [],
  // },

  // {
  //   icon: <DashboardOutlinedIcon fontSize="small" />,
  //   name: "Dashboard",
  //   link: "/dashboard",
  // },
  // {
  //   icon: <DocumentScannerOutlinedIcon fontSize="small" />,
  //   name: "Records",
  //   link: "/records",
  // },
  // {
  //   icon: <FolderOpenOutlinedIcon fontSize="small" />,
  //   name: "Tutor",
  //   link: "/Tutor",
  // },
  // {
  //   icon: <SettingsOutlinedIcon fontSize="small" />,
  //   name: "Supplier",
  //   link: "*",
  // },
  // {
  //   icon: <CastForEducationOutlinedIcon fontSize="small" />,
  //   name: "Seller",
  //   link: "*",
  // },
  // {
  //   icon: <GroupsOutlinedIcon fontSize="small" />,
  //   name: "FBA Calculator",
  //   link: "*",
  // },
  // {
  //   icon: <ForumOutlinedIcon fontSize="small" />,
  //   name: "Sales Estiamte",
  //   link: "*",
  // },
  // {
  //   icon: <FaChrome fontSize={16} />,
  //   name: "Bulk Analisis Tool",
  //   link: "*",
  // },
  // {
  //   icon: <SlSocialYoutube fontSize={16} />,
  //   name: "Bundles Ideas",
  //   link: "*",
  // },
  // {
  //   icon: <CiFacebook fontSize={16} />,
  //   name: "Facebook Group",
  //   link: "*",
  // },
  // {
  //   icon: <FaDiscord fontSize={16} />,
  //   name: "Discord",
  //   link: "*",
  // },
];
