import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import upload from "../../../../../Assests/icon/file.png";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import "./index.css";
import { CircleMinus } from "lucide-react";
import {
  assignmentDownload,
  assignmentSubmission,
  getByIdAssignment,
  assignmentView,
} from "../../../../../Service/BaseService/BaseService";
import { useLocation, useNavigate } from "react-router-dom";
import { successAlert } from "../../../../../Components/Popup/Basicpopup";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../../Components/Snackbar/Snackbar";
import { PdfViewer } from "../../../../../Components/UploadBox/UploadBox";

function AssignmentDetails() {
  const userDetails = localStorage.getItem("User");
  const accessToken = JSON.parse(userDetails);
  const [filename, setFilename] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isViewAssignemnt, setIsViewAssignment] = useState(false);
  const location = useLocation();
  const { state } = location;
  const { params } = state || {};
  const navigate = useNavigate();

  const [isFormData, setIsFormData] = useState({
    scheme: "",
    uploadedFiles: [],
    Assignments: null,
    AssignmentSubmissionId: "",
    description: "",
  });
  const [isFile, setIsFile] = useState([]);

  useEffect(() => {
    setIsFormData((prev) => ({
      ...prev,
      ...params,
      uploadedFiles: params.uploadedFiles,
    }));
    setIsFile(params.uploadedFiles);
  }, [params]);

  const getById = async (id) => {
    try {
      const response = await getByIdAssignment(accessToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        setIsFormData((prev) => ({
          ...prev,
          uploadedFiles: responseData.message.assignmentURL,
        }));
      }
    } catch (err) {
      notifyError("Failed to fetch assignment details");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const updatedFiles = [...isFile, file];
      setIsFile(updatedFiles);
      const formData = new FormData();
      formData.append("schema", isFormData.schema);
      updatedFiles.forEach((file, index) => {
        formData.append(`assignments[${index}]`, file);
      });
      setIsFormData((prev) => ({
        ...prev,
        Assignments: updatedFiles,
      }));
    }
  };

  const removeRowHandler = (i) => {
    const updateArray = isFile.filter((_, index) => index !== i);
    setIsFile(updateArray);
    setIsFormData((prev) => ({
      ...prev,
      ...params,
      uploadedFiles: updateArray,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const buttonValue = e.nativeEvent.submitter.value;
    const scheme = buttonValue === "submit" ? "submit" : "save";
    const updatedForm = {
      ...isFormData,
      schema: scheme,
    };
    setIsFormData(updatedForm);

    const formData = new FormData();
    formData.append("schema", updatedForm.schema);
    if (updatedForm.uploadedFiles && updatedForm.uploadedFiles.length > 0) {
      formData.append(
        "uploadedFiles",
        JSON.stringify(updatedForm.uploadedFiles)
      );
    } else {
      formData.append("uploadedFiles", JSON.stringify([]));
    }
    if (updatedForm.Assignments && updatedForm.Assignments.length > 0) {
      updatedForm.Assignments.forEach((file) => {
        formData.append("assignments", file);
      });
    } else {
      formData.append("assignments", null);
    }

    try {
      const response = await assignmentSubmission(
        accessToken.token,
        updatedForm._id,
        formData
      );
      const responseData = await response.json();
      if (response.ok) {
        navigate("/assignment");
        successAlert(responseData.message);
      } else {
        notifyError(responseData.message);
      }
    } catch (err) {
      console.error("Error submitting assignment:", err);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("en-GB", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour12: true,
    });
  };

  const assignmentFile = async (id, name) => {
    console.log(id);
    console.log(name);
    try {
      const response = await assignmentDownload(
        accessToken.token,
        isFormData._id
      );
      if (!response.ok) {
        throw new Error("Failed to export data");
      }
      const contentDisposition = response.headers.get("Content-Disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      let fileName = "file";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifySucess("Export successful");
    } catch (err) {
      notifyError("Error on export");
    }
  };
  const assignmentViewer = async (fileId) => {
    console.log(fileId);
    try {
      const response = await assignmentView(accessToken.token, fileId);
      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const responseData = await response.json();

      const pdfContent = responseData.message;

      setIsViewAssignment(pdfContent);
      setIsModal(true);
    } catch (error) {
      console.error("Error fetching or displaying PDF:", error);
    }
  };
  const modalHandler = () => {
    setIsModal(!isModal);
  };

  const handleClick = () => {
    document.getElementById('file-upload').click();
  };

  const handleBack = (e) => {
    e.preventDefault();
    window.history.back();
  };

  return (
    <Container>
      <Snackbar />
      <PdfViewer
        modal={isModal}
        modalHandler={modalHandler}
        isViewAssignment={isViewAssignemnt}
      />
      <Row>
        <Col>
          <form onSubmit={submitHandler}>
            <div>
              <div className="profile-form">
                <h5>Assignment</h5>
                <div>
                  <div>
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control attached-files-input"
                      value={isFormData?.assignmentId?.name || ""}
                      readOnly
                    />
                  </div>
                  <div>
                    <label>Status</label>
                    <input
                      type="text"
                      value={isFormData?.status || ""}
                      className="form-control attached-files-input"
                      readOnly
                    />
                  </div>
                </div>
                <div>
                  <div className="profile-form">
                    <label>Description</label>
                    <textarea
                      value={isFormData?.assignmentId?.description || ""}
                      className="form-control attached-files-input"
                      readOnly
                    />
                  </div>
                </div>
                <div className="profile-form">
                  <div>
                    <label htmlFor="title">Schedule Date</label>
                    <input
                      className="form-control attached-files-input"
                      name="title"
                      id="title"
                      value={formatDate(isFormData?.scheduleDate)}
                      readOnly
                    />
                  </div>
                  <div>
                    <label htmlFor="course">Due Date</label>
                    <input
                      className="form-control attached-files-input"
                      value={formatDate(isFormData?.dueDate)}
                      readOnly
                    />
                  </div>
                </div>

                <div className="profile-form">
                  <div className="attached-files-container">
                    <label
                      htmlFor="uploadedFiles"
                      className="attached-files-label"
                    >
                      Assigned Documents
                    </label>
                    <div className="attached-files-input-wrapper">
                      <input
                        className="form-control attached-files-input"
                        value={
                          (isFormData.originalFile &&
                            isFormData.originalFile[0] &&
                            (isFormData.originalFile[0].name ||
                              isFormData.originalFile[0].filename)) ||
                          ""
                        }
                        readOnly
                      />

                      <button
                        className="btn btn-outline-secondary download-button submit-save-btn"
                        type="button"
                        onClick={() => {
                          if (
                            isFormData.originalFile &&
                            isFormData.originalFile.length > 0
                          ) {
                            isFormData.originalFile.forEach((file) => {
                              console.log(file.filename);
                              assignmentFile(file.filename);
                            });
                          } else {
                            console.error(
                              "Uploaded files data is missing or empty."
                            );
                          }
                        }}
                      >
                        <i className="fa fa-download"></i>
                      </button>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="registration-setup flex-column gap-0">
                  <label>Attachments</label>
                  <div className="upload-block flex-row p-5" onClick={handleClick}>
                    <div>
                      <label className="upload-label d-flex align-items-center">
                        <img
                          style={{ cursor: "pointer" }}
                          src={upload}
                          alt="upload"
                          className="upload-icon"
                        />
                        <Form.Control
                          id="file-upload"
                          className="modal-input file-upload"
                          type="file"
                          accept=".pdf"
                          onChange={handleFileChange}
                          name="title"
                        />
                        <span className="span-text">Click here to upload</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="registration-setup">
                  <div>
                    <label>Attached Documents</label>
                    <div className="d-flex gap-2 flex-wrap">
                      {isFile?.map((item, index) => (
                        <div
                          key={index}
                          className="reactfiles-cards"
                          style={{ cursor: "pointer" }}
                          onClick={() => assignmentViewer(item._id)}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="tools">
                              <div className="circle">
                                <span className="red box"></span>
                              </div>
                              <div className="circle">
                                <span className="yellow box"></span>
                              </div>
                              <div className="circle">
                                <span className="green box"></span>
                              </div>
                            </div>
                            <CircleMinus
                              className="minus-icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeRowHandler(index);
                              }}
                            />
                          </div>
                          <div className="card__content mt-2">
                            <div className="d-flex flex-row align-items-center gap-2">
                              <DescriptionOutlinedIcon className="file-icon" />
                              {item.name || item.filename}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="save-border">
                  <div className="d-flex flex-row justify-content-start gap-2">
                    <div>
                      <button
                        onClick={handleBack}
                        className="submit-save-btn"
                        type="button"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-row justify-content-end gap-2">
                    <div>
                      <input
                        type="submit"
                        className="submit-save-btn"
                        value={"submit"}
                      />
                    </div>
                    <div>
                      <input
                        type="submit"
                        className="submit-save-btn-draft"
                        value={"save as draft"}
                        hidden={isFormData?.status === 'in-review' || isFormData?.status === 'reviewed'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default AssignmentDetails;
