import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import { UploadBox } from "../../../../../Components/UploadBox/UploadBox";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import "./index.css";
import {
  assignmentDownload,
  assignmentDownloadTutor,
  createAssignment,
  fetchDataStudent,
  getAssignment,
  getByIdAssignment,
  getClass,
  getClassStudent,
  removeAssignment,
  studentRegister,
  updateAssignment,
  getProfileData
} from "../../../../../Service/BaseService/BaseService";
import { successAlert } from "../../../../../Components/Popup/Basicpopup";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../../Components/Snackbar/Snackbar";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../../../../Components/Modal/DeleteModal";

function TutorAssignment() {
  const user = localStorage.getItem("User");
  const userToken = JSON?.parse(user);
  const [isModal, setIsModal] = useState(false);
  const [isFile, setIsFile] = useState([]);
  const [isData, setIsData] = useState(null);
  const [isTutor, setIsTutor] = useState(null);
  const [isStudent, setIsStudent] = useState();
  const [isTotalStudent, setIsTotalStudent] = useState();
  const [isSelectAll, setIsSelectAll] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [rowDataDefault, setRowDataDefault] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignmentId, setIsAssignment] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const navigate = useNavigate();
  const addAssignment = () => {
    setIsModal(true);
  };

  useEffect(() => {
    fetchAllData();
    fetchData();
    tutorProfile();
  }, []);

  const form = {
    name: "",
    description: "",
    dueDate: "",
    classRoomId: "",
    scheduleDate: "",
    schedule: "",
    studentIds: [],
    assignmentURL: null,
    Assignments: null,
    tutorId: ""
  };

  const [isFormData, setIsFormData] = useState(form);

  const fetchData = async () => {
    try {
      const response = await getClass(userToken.token);
      const responseData = await response.json();
      if (response.ok) {
        setIsData(responseData.message);
      }
    } catch (err) { }
  };

  const tutorProfile = async () => {
    try {
      const response = await getProfileData(userToken.token);
      if (response.ok) {
        const responseData = await response.json();
        const { _id, firstName } = responseData.message;
        setIsTutor([{ _id, firstName }]);
      } else {
        console.error("Failed to fetch profile data:", response.statusText);
      }
    } catch (err) { }
  };

  const getStudent = async (id, assignmentId) => {
    try {
      const response = await getClassStudent(userToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData?.assignedStudents[0]);
        setIsStudent(
          responseData?.assignedStudents[0]?.studentId?.map((item) => ({
            ...item,
            isChecked: false,
          }))
        );
        if (assignmentId) {
          getByAssignmentData(assignmentId);
          setIsAssignment(assignmentId);
        }
      }
    } catch (err) {
      throw err;
    }
  };
  console.log(isStudent);
  const removeRowHandler = (i) => {
    let update = [...isFile];
    update = update.filter((item, index) => index !== i);
    setIsFile(update);
    setIsFormData((prev) => {
      return {
        ...prev,
        assignmentURL: update,
        Assignments: update,
      };
    });
  };
  const getByAssignmentData = async (id) => {
    try {
      const response = await getByIdAssignment(userToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        setIsFormData(responseData.message);
        setIsFormData((prev) => {
          return {
            ...prev,
            Assignments: responseData.message.assignmentURL,
          };
        });
        setIsFile(responseData.message.assignmentURL);
        setIsStudent((prev) => {
          let update = prev.map((item, index) => {
            return {
              ...item,
              isChecked: responseData.message.studentIds.includes(item._id),
            };
          });
          checkHandler(update);
          return update;
        });
      }
    } catch (err) {
      throw err;
    }
  };
  const checkHandler = (update) => {
    setIsSelectAll((prev) => {
      return update.every((item) => item.isChecked);
    });
    setIsModal(true);
  };

  const changeHandler = (type, ids) => {
    let updatedStudents = [...isStudent];

    if (type === "add") {
      updatedStudents = updatedStudents.map((student) =>
        ids.includes(student._id)
          ? { ...student, isChecked: true }
          : student
      );
    } else {
      updatedStudents = updatedStudents.map((student) =>
        ids.includes(student._id)
          ? { ...student, isChecked: false }
          : student
      );
    }

    setIsStudent(updatedStudents);
    setIsSelectAll(updatedStudents.every((student) => student.isChecked));
  };

  const selectallHandler = (e) => {
    let updateArray = [...isStudent];
    if (e.target.checked) {
      updateArray = updateArray?.map((item, index) => {
        return { ...item, isChecked: true };
      });
    } else {
      updateArray = updateArray?.map((item, index) => {
        return { ...item, isChecked: false };
      });
    }
    setIsStudent(updateArray);
    setIsSelectAll((prev) => {
      return updateArray?.every((item) => item.isChecked);
    });
  };

  const removeHandler = async (id) => {
    try {
      setDeleteItemId(id);
      setShowDeleteModal(true);
    } catch (err) { }
  };

  const deleteAssignment = async (id) => {
    try {
      const response = await removeAssignment(userToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        notifySucess(responseData.message);
        setShowDeleteModal(false);
        fetchAllData();
      } else {
        notifyError(responseData.error);
      }
    } catch (err) {
      console.log("Error Removing Assignment", err);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let updateAssignemnt = [...isFile];
      updateAssignemnt = [...updateAssignemnt, file];
      setIsFile(updateAssignemnt);
      setIsFormData((prev) => {
        return {
          ...prev,
          Assignments: updateAssignemnt,
        };
      });
    }
    // profileUpdate(formData);
  };

  //   const profileUpdate = async (formData) => {
  //     try {
  //       fetch("https://648950bc5fa58521caaf68f7.mockapi.io/Records", {
  //         method: "POST",
  //         body: formData,
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       })
  //         .then((response) => response.json())
  //         .then((data) => console.log(data))
  //         .catch((error) => console.error("Error:", error));
  //     } catch (err) {}
  //   };
  const columns = [
    { field: "name", headerName: "Student Name", width: 100, flex: 1 },
    { field: "classroom", headerName: "Classroom", width: 100, flex: 1 },
    {
      field: "assignmentCount",
      headerName: "Assignment Count",
      width: 100,
      flex: 1,
    },
    { field: "course", headerName: "Course", width: 100, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            className={
              params.row.status === "Completed" ? "good-status" : "bad-status"
            }
          >
            <p>{params.row.status}</p>
          </div>
        );
      },
    },
    {
      field: "Download",
      headerName: "Download",
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <div class="Btn">
            {/* <a href="/path/to/your/pdf/file.pdf" download> */}
            <svg
              class="svgIcon"
              viewBox="0 0 384 512"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
            </svg>
            <span class="icon2"></span>
            {/* </a> */}
          </div>
        );
      },
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <div class="button">
            <svg
              class="svg-icon"
              fill="none"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                stroke="#4D44B5"
                stroke-linecap="round"
                stroke-width="2"
                className="stroke-color"
              >
                <path d="m20 20h-16"></path>
                <path
                  clip-rule="evenodd"
                  d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                  fill-rule="evenodd"
                ></path>
              </g>
            </svg>
          </div>
        );
      },
    },
  ];
  const rows = [
    {
      id: 1,
      name: "John Doe",
      classroom: "101",
      assignmentCount: 5,
      status: "Completed",
      course: "Creative Writing -1",
    },
    {
      id: 2,
      name: "Jane Smith",
      classroom: "102",
      assignmentCount: 3,
      status: "Pending",
      course: "Creative Writing-2",
    },
    {
      id: 3,
      name: "Sam Brown",
      classroom: "103",
      assignmentCount: 2,
      status: "Completed",
      course: "Creative Writing-1",
    },
    {
      id: 4,
      name: "Alice Johnson",
      classroom: "104",
      assignmentCount: 4,
      status: "Pending",
      course: "Creative Writing-2",
    },
    {
      id: 5,
      name: "Robert Wilson",
      classroom: "105",
      assignmentCount: 6,
      status: "Completed",
      course: "Creative Writing-3",
    },
  ];
  const changeValue = (e) => {
    setIsFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    if (e.target.name === "classRoomId") {
      return getStudent(`classId=${e.target.value}`, null);
    }
  };
  const assignmentFile = async (id, name) => {
    try {
      const response = await assignmentDownloadTutor(userToken.token, id);
      if (!response.ok) {
        throw new Error("Failed to export data");
      }
      const contentDisposition = response.headers.get("Content-Disposition");
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      let fileName = "file";
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, "");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      notifySucess("Export successful");
    } catch (err) {
      notifyError("Error on export");
    }
  };

  const fetchAllData = async (page, limit) => {
    setIsLoading(true);
    // const request = {
    //   page: page ? page : 1,
    //   limit: limit ? limit : 10,
    // };
    try {
      const response = await getAssignment(userToken.token);
      const responseData = await response.json();
      if (response.ok && responseData.message.length > 0) {
        console.log(responseData.message);
        // setIsResponseData(responseData.message);
        const headers = Object.keys(responseData.message[0]);
        console.log("response headers", headers);
        const additionalColumnDownload = {
          field: "Download",
          headerName: "Download",
          maxWidth: 100,
          // flex: 1,
          renderCell: (params) => {
            return (
              <div
                class="Btn"
                onClick={() =>
                  assignmentFile(params.row._id, params.row.assignmentId?.name)
                }
              >
                {/* <a href="/path/to/your/pdf/file.pdf" download> */}
                <svg
                  class="svgIcon"
                  viewBox="0 0 384 512"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path>
                </svg>
                <span class="icon2"></span>
                {/* </a> */}
              </div>
            );
          },
        };
        const additionalColumnEdit = {
          field: "Remove",
          headerName: "Remove",
          width: 80,
          // flex: 1,
          renderCell: (params) => {
            return (
              <div
                className="tooltipbox"
                onClick={() => removeHandler(params.row._id)}
              >
                <svg
                  className="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  height="25"
                  width="25"
                >
                  <path
                    d="M8.78842 5.03866C8.86656 4.96052 8.97254 4.91663 9.08305 4.91663H11.4164C11.5269 4.91663 11.6329 4.96052 11.711 5.03866C11.7892 5.11681 11.833 5.22279 11.833 5.33329V5.74939H8.66638V5.33329C8.66638 5.22279 8.71028 5.11681 8.78842 5.03866ZM7.16638 5.74939V5.33329C7.16638 4.82496 7.36832 4.33745 7.72776 3.978C8.08721 3.61856 8.57472 3.41663 9.08305 3.41663H11.4164C11.9247 3.41663 12.4122 3.61856 12.7717 3.978C13.1311 4.33745 13.333 4.82496 13.333 5.33329V5.74939H15.5C15.9142 5.74939 16.25 6.08518 16.25 6.49939C16.25 6.9136 15.9142 7.24939 15.5 7.24939H15.0105L14.2492 14.7095C14.2382 15.2023 14.0377 15.6726 13.6883 16.0219C13.3289 16.3814 12.8414 16.5833 12.333 16.5833H8.16638C7.65805 16.5833 7.17054 16.3814 6.81109 16.0219C6.46176 15.6726 6.2612 15.2023 6.25019 14.7095L5.48896 7.24939H5C4.58579 7.24939 4.25 6.9136 4.25 6.49939C4.25 6.08518 4.58579 5.74939 5 5.74939H6.16667H7.16638ZM7.91638 7.24996H12.583H13.5026L12.7536 14.5905C12.751 14.6158 12.7497 14.6412 12.7497 14.6666C12.7497 14.7771 12.7058 14.8831 12.6277 14.9613C12.5495 15.0394 12.4436 15.0833 12.333 15.0833H8.16638C8.05588 15.0833 7.94989 15.0394 7.87175 14.9613C7.79361 14.8831 7.74972 14.7771 7.74972 14.6666C7.74972 14.6412 7.74842 14.6158 7.74584 14.5905L6.99681 7.24996H7.91638Z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </div>
            );
          },
        };
        const additionalColumnRemove = {
          field: "Edit",
          headerName: "Edit",
          width: 80,
          // flex: 1,
          renderCell: (params) => {
            return (
              <div
                class="button"
                onClick={() => {
                  getStudent(
                    `classId=${params.row.classRoomId}`,
                    params.row._id
                  );
                }}
              >
                <svg
                  class="svg-icon"
                  fill="none"
                  height="24"
                  viewBox="0 0 24 24"
                  width="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#4D44B5"
                    stroke-linecap="round"
                    stroke-width="2"
                    className="stroke-color"
                  >
                    <path d="m20 20h-16"></path>
                    <path
                      clip-rule="evenodd"
                      d="m14.5858 4.41422c.781-.78105 2.0474-.78105 2.8284 0 .7811.78105.7811 2.04738 0 2.82843l-8.28322 8.28325-3.03046.202.20203-3.0304z"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </div>
            );
          },
        };


        const columns = headers?.map((header) => {
          switch (header) {
            case "schedule":
              return {
                field: "schedule",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.schedule}</p>
                  </div>
                ),
              };
            case "scheduleDate":
              return {
                field: "scheduleDate",
                headerName: "Start Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.scheduleDate}</p>
                  </div>
                ),
              };
            case "dueDate":
              return {
                field: "dueDate",
                headerName: "Due Date",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.dueDate}</p>
                  </div>
                ),
              };
            case "totalStudents":
              return {
                field: "totalStudents",
                headerName: "Total Students",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row.totalStudents}</p>
                  </div>
                ),
              };
            case "createdBy":
              return {
                field: "createdBy",
                headerName: "Created By",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row?.createdBy?.firstName}</p>
                  </div>
                ),
              };
            case "tutorId":
              return {
                field: "tutorId",
                headerName: "Tutor",
                flex: 1,
                renderCell: (params) => (
                  <div>
                    <p className="tabel-cell">{params.row?.tutorId?.firstName}</p>
                  </div>
                ),
              };
            default:
              return {
                field: header,
                headerName: header.charAt(0).toUpperCase() + header.slice(1),
                flex: 1,
                width: 180,
              };
          }
        });

        if (additionalColumnEdit && additionalColumnRemove) {
          columns.push(additionalColumnDownload);
          columns.push(additionalColumnRemove);
          columns.push(additionalColumnEdit);
        }

        const rows = responseData.message?.map((dataItem, index) => ({
          id: index,
          ...dataItem,
        }));

        const desiredOrder = [
          "name",
          "description",
          "scheduleDate",
          "dueDate",
          "schedule",
          "totalStudents",
          "createdBy",
          "Download",
          "Edit",
          "Remove",
        ];
        const orderedColumns = desiredOrder.map((key) =>
          columns.find((col) => col.field === key)
        );

        setColumnDefs(
          orderedColumns.filter(
            (item, index) =>
              item.field !== "__v" &&
              item.field !== "_id" &&
              item.field !== "assignmentURL" &&
              item.field !== "createdAt" &&
              item.field !== "updatedBy" &&
              item.field !== "classRoomId" &&
              item.field !== "studentIds" &&
              item.field !== "updatedAt" 
          )
        );
        setRowDataDefault(rows);
        setRowData(rows);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
    setIsLoading(false);
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    const buttonValue = e.nativeEvent.submitter.value;

    const scheme =
      buttonValue === "Submit" || buttonValue === "Update" ? "submit" : "draft";
    const updatedForm = {
      ...isFormData,
      schedule: scheme,
      studentIds: isStudent
        ?.filter((item) => item.isChecked)
        ?.map((item) => item._id),
    };
    setIsFormData(updatedForm);

    if (!updatedForm.classRoomId) {
      return notifyError("class not selected");
    }

    const formData = new FormData();
    formData.append("name", updatedForm?.name);
    formData.append("dueDate", updatedForm.dueDate);
    formData.append("description", updatedForm.description);
    formData.append(
      "scheduleDate",
      updatedForm.scheduleDate === null ? "" : updatedForm.scheduleDate
    );
    formData.append("schedule", updatedForm.schedule);
    formData.append("tutorId", updatedForm.tutorId);
    formData.append("studentIds", JSON.stringify(updatedForm.studentIds));
    if (isAssignmentId) {
      formData.append(
        "assignmentURL",
        JSON.stringify(
          updatedForm.assignmentURL.map((item) => ({
            filename: item.filename,
            filepath: item.filepath,
            _id: item._id,
          }))
        )
      );
    } else {
      formData.append(
        "assignmentURL",
        JSON.stringify(updatedForm.assignmentURL)
      );
    }
    if (updatedForm.Assignments && updatedForm.Assignments.length > 0) {
      updatedForm.Assignments.filter((file) => file instanceof File).forEach(
        (file) => {
          formData.append("assignments", file, file?.name);
        }
      );
    } else {
      formData.append("assignments", null);
    }

    if (!isAssignmentId) {
      try {
        const response = await createAssignment(
          userToken.token,
          updatedForm.classRoomId,
          formData
        );
        const responseData = await response.json();
        if (response.ok) {
          setIsFormData(form);
          console.log(form);
          modalCloseHandler();
          setIsAssignment(null);
          successAlert(responseData.message);
          fetchAllData();
        } else {
          notifyError(responseData.message);
        }
      } catch (err) { }
    } else {
      try {
        const response = await updateAssignment(
          userToken.token,
          isAssignmentId,
          formData
        );
        const responseData = await response.json();
        if (response.ok) {
          setIsFormData(form);
          modalCloseHandler();
          setIsAssignment(null);
          successAlert(responseData.message);
          fetchAllData();
          // navigate("/assignment");
        } else {
          notifyError(responseData.message);
        }
      } catch (err) { }
    }
  };
  const modalCloseHandler = () => {
    setIsAssignment(null);
    setIsModal(false);
    setIsFile([]);
    setIsStudent((prev) => {
      return prev?.map((item, index) => ({ ...item, isChecked: false }));
    });
    setIsSelectAll(null);
    setIsFormData(form);
  };

  const searchHandler = (e) => {
    const value = e.target.value;
    if (value !== "") {
      const filteredData = rowDataDefault.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setRowData(filteredData);
    } else {
      setRowData(rowDataDefault);
    }
  };
  return (
    <Container>
      <Snackbar />
      {isModal && (
        <UploadBox
          isAssignmentId={isAssignmentId}
          removeRowHandler={removeRowHandler}
          submitHandler={submitHandler}
          selectallHandler={selectallHandler}
          selectAll={isSelectAll}
          changeHandler={changeHandler}
          student={isStudent}
          form={isFormData}
          data={isData}
          file={isFile}
          tutor={isTutor}
          isModal={isModal}
          modalCloseHandler={modalCloseHandler}
          handleFileChange={handleFileChange}
          changeValue={changeValue}
          status={isFormData.schedule}
        />
      )}
      {!isModal && (
        <>
          <Row>
            <Col>
              <div className="product">
                <SearchBar
                  onClick={addAssignment}
                  value={"Assignment"}
                  searchHandler={searchHandler}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <AdvanceTable
                rows={rowData}
                columns={columnDefs}
                isLoading={isLoading}
              />
            </Col>
          </Row>
          <DeleteModal
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleteHandler={() => deleteAssignment(deleteItemId)}
            name="Assignment"
          />
        </>
      )}
    </Container>
  );
}

export default TutorAssignment;
