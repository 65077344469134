import React, { useEffect, useState } from "react";
import "./index.css";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import { Col, Container, Row } from "react-bootstrap";
import AdvanceTable from "../../../../../Components/Table/AdvanceTable";
import { FaArrowUp } from "react-icons/fa6";
import { getByStudent } from "../../../../../Service/BaseService/BaseService";
import { useLocation } from "react-router-dom";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import FaceIcon from "@mui/icons-material/Face";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import defaultImage from "../../../../../Assests/icon/profile-picture.png";

function Studentdetails() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const getId = search.get("id");
  const [isData, setIsData] = useState(null);
  useEffect(() => {
    if (getId) {
      fetchAllData();
    }
  }, []);
  const fetchAllData = async () => {
    try {
      const response = await getByStudent(userToken.token, getId);
      const responseData = await response.json();
      if (response.ok) {
        setIsData(responseData?.message?.student);
      }
    } catch (err) {}
  };

  return (
    <Container>
      <Row>
        <Col>
          <div>
            <div className="profile-banner">
              <div className="d-flex justify-content-end align-items-end">
                <div className="profile-banner1">
                  <svg
                    width="264"
                    height="59"
                    viewBox="0 0 264 59"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.564056"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FB7D5B"
                    ></rect>
                  </svg>
                </div>
                <div className="profile-banner2">
                  <svg
                    width="264"
                    height="109"
                    viewBox="0 0 264 109"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.0107422"
                      y="0.6521"
                      width="263.592"
                      height="275.13"
                      rx="20"
                      fill="#FCC43E"
                    ></rect>
                  </svg>
                </div>
              </div>
              <div className="student-details">
                <div>
                  <img
                    src={isData?.imageURL ? isData?.imageURL : defaultImage}
                    alt="profile"
                    className="student-profile"
                  />
                </div>
              </div>
            </div>
            <div className="profile-details">
              <div className="profile-detail-block">
                <h4>{isData?.firstName}</h4>
                <span>Student</span>
              </div>
              <div className="profile-describe-block">
                <div>
                  <div className="d-flex gap-2">
                    <div className="profile-badge">
                      <PermIdentityOutlinedIcon className="profile-details-icon" />
                    </div>
                    <div className="profile-details-text">
                      <span>Parents:</span>
                      <p>{isData?.parent?.firstName}</p>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div className="profile-badge">
                      <ChromeReaderModeIcon className="profile-details-icon" />
                    </div>
                    <div className="profile-details-text">
                      <span>Course</span>
                      <p>{isData?.courses[0]?.name}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex gap-2">
                    <div className="profile-badge">
                      <FaceIcon className="profile-details-icon" />
                    </div>
                    <div className="profile-details-text">
                      <span>School Year</span>
                      <p>{isData?.schoolYear}</p>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div className="profile-badge">
                      <CalendarMonthIcon className="profile-details-icon" />
                    </div>
                    <div className="profile-details-text">
                      <span>Academic</span>
                      <p>{isData?.academic}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Studentdetails;
