import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../Loader/Loader";
import { Pagination, Stack } from "@mui/material";

function BasicTable(props) {
  const { columns, rows, isLoading, checkbox, height, handleSelectionChange } =
    props;
  const handleRowSelectionChange = handleSelectionChange
    ? (params) => {
        handleSelectionChange(params);
      }
    : null;

  return (
    <div className="table-box">
      <div style={{ width: "100%" }}>
        <React.Fragment>
          <DataGrid
            sx={{
              ".MuiDataGrid-columnHeaderTitle": {
                color: "#4D44B5",
                fontFamily: "poppins,sans-serif",
                backgroundColor: "#E6EBEE",
              },
              ".MuiDataGrid-columnHeader--sortable": {
                backgroundColor: "#E6EBEE",
              },
              ".MuiDataGrid-columnHeader--alignCenter": {
                backgroundColor: "#E6EBEE",
              },
              ".MuiDataGrid-cellContent": {
                fontFamily: "poppins light,sans-serif",
              },
              ".MuiDataGrid-root .MuiDataGrid-row.Mui-hovered": {
                backgroundColor: "transparent",
              },
              ".MuiDataGrid-row.Mui-selected": {
                backgroundColor: "white",
              },
              ".MuiDataGrid-columnHeaderTitleContainer": {
                backgroundColor: "#E6EBEE",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              ".MuiDataGrid-cell:focus": {
                outline: "none",
                border: "none", 
              },
              ".MuiDataGrid-cell:focus-within": {
                outline: "none",
                border: "none", 
              },
            }}
            rows={rows}
            columns={columns}
            hideFooter={true}
            autoHeight={true}
            checkboxSelection={checkbox}
            onRowSelectionModelChange={handleRowSelectionChange}
            rowHeight={60}
            loading={isLoading}
          />
        </React.Fragment>
      </div>
    </div>
  );
}

export default BasicTable;
