import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import ParentTable from "../../../Components/Table/ParentTable";
import { FaArrowUp } from "react-icons/fa6";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentIcon from "@mui/icons-material/Assignment";
import defaultImage from "../../../Assests/icon/profile-picture.png";
import Block from "../../../Components/Block/Block";
import BasicButton from "../../../Components/Button/Button";

import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../Components/Snackbar/Snackbar";
import {
  getByStudent,
  getStudentAssignment,
} from "../../../Service/BaseService/BaseService";
import { useNavigate } from "react-router-dom";

function TutorProfileBoard() {
  const location = useLocation();

  const user = localStorage.getItem("User");
  const userToken = JSON?.parse(user);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  const [columnDefs, setColumnDefs] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isMetric, setIsMetric] = useState([]);
  const [isData, setIsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tutor, setTutor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } =
    location.state && location.state.id ? location.state : { id: null };

  useEffect(() => {
    const fetchTutorDetails = async () => {
      const rowIds = localStorage.getItem("rowIds");
      const cleanRowIds = rowIds.replace("tremove", "");
      try {
        const parsedData = JSON.parse(cleanRowIds);
        const response = await fetch(
          `https://api.gurukulacademy.uk/api/user/tutors/${parsedData}`,
          {
            headers: {
              Authorization: `Bearer ${userToken.token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log(data);
        setTutor(data);
        setIsData(data.tutor);
        console.log(parsedData);
      } catch (error) {
        console.error("Error fetching tutor details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTutorDetails();
  }, [userToken.token]);

  const activeSubscription = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    dataUnit: "USD",
    stacked: true,
    datasets: [
      {
        label: "Active User",
        barPercentage: 0.7,
        categoryPercentage: 0.7,
        backgroundColor: [
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#EDE9F1",
          "#275298",
        ],
        data: [8200, 7800, 9500, 5500, 9200, 9690],
      },
    ],
  };

  const newData = [
    {
      name: "Assigned",
      count: isMetric ? isMetric.assignedAssignmentsCount : 0,
      icon: <AssignmentIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#4d44b5",
    },
    {
      name: "Overdue",
      count: isMetric ? isMetric.overduePaymentsCount : 0,
      icon: <PendingActionsIcon style={{ color: "white" }} fontSize="large" />,
      iconColor: "#FB7D5B",
    },
    {
      name: "Submitted",
      icon: <CloudUploadIcon style={{ color: "white" }} fontSize="large" />,
      count: isMetric ? isMetric.submittedAssignmentsCount : 0,
      iconColor: "#FCC43E",
    },
    {
      name: "Reviewed",
      icon: <CoPresentIcon style={{ color: "white" }} fontSize="large" />,
      count: isMetric ? isMetric.reviewedAssignmentsCount : 0,
      iconColor: "#303972",
    },
  ];

  const back = () => {
    localStorage.setItem("isActive", "student");
    window.history.back();
  };

  return (
    <>
      <Snackbar />
      <Container>
        <Row>
          <Col>
            <BasicButton
              baseStyle="submit-save-btn"
              value="Back"
              onClick={back}
            />
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <div>
              <div className="profile-banner">
                <div className="d-flex justify-content-end align-items-end">
                  <div className="profile-banner1">
                    <svg
                      width="264"
                      height="59"
                      viewBox="0 0 264 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        y="30.435944"
                        width="263.592"
                        height="60.13"
                        rx="20"
                        fill="#FB7D5B"
                      ></rect>
                    </svg>
                  </div>
                  <div className="profile-banner2">
                    <svg
                      width="264"
                      height="59"
                      viewBox="0 0 264 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.0107422"
                        y="20.435944"
                        width="263.592"
                        height="100.13"
                        rx="20"
                        fill="#FCC43E"
                      ></rect>
                    </svg>
                  </div>
                </div>
                <div className="student-details">
                  <div>
                    <img
                      src={isData?.imageURL ? isData.imageURL : defaultImage}
                      alt="profile"
                      className="student-profile"
                    />
                  </div>
                </div>
              </div>
              <div className="profile-details">
                <div className="profile-detail-block">
                  <h4>
                    {isData?.firstName}&nbsp;{isData?.lastName}
                  </h4>
                </div>
                <div className="d-flex">
                  <div className="profile-details-text">
                    <span>Tutor</span>
                    <p className="gap-3">{isData?.firstName}&nbsp;</p>
                  </div>
                  <div className="profile-details-text">
                    <span>Address</span>
                    <p>
                      <i className="fas fa-map-marker-alt icon"></i>&nbsp;
                      &nbsp;{isData?.address}
                    </p>
                    <p>
                      <i className="fas fa-envelope icon"></i>&nbsp; &nbsp;
                      {isData?.email}
                    </p>
                    <p>
                      <i className="fas fa-phone icon"></i>&nbsp; &nbsp;
                      {isData?.phone}
                    </p>
                  </div>
                  <div className="profile-details-text p-0">
                    <span>Course</span>
                    <p>
                      {isData?.subjects && isData.subjects.length > 0 ? (
                        isData.subjects.map((subject, index) => (
                          <div key={index} className="noDecoration">
                            {subject.name}
                          </div>
                        ))
                      ) : (
                        <span>No course available</span>
                      )}
                    </p>
                  </div>
                  <div className="profile-details-text">
                    <span>CreatedAt</span>
                    <p>{isData?.createdAt}</p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TutorProfileBoard;
