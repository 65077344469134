import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StateManagement } from "./StateManagement/StateManagement";
import DefaultLayout from "./Layout/DefaultLayout/DefaultLayout";
import Login from "./Layout/DefaultLayout/Login";
import Register from "./Layout/DefaultLayout/Register";
import Oauth from "./Oauth/Oauth";
import ParentRoute from "./ProtectRoute/Parent/ParentRoute";
import AdminRoute from "./ProtectRoute/Admin/AdminRoute";
import ParentLayout from "./Layout/ParentControl/ParentLayout/ParentLayout";
import { useEffect, useState } from "react";
import AdminLayout from "./Layout/AdminControl/AdminLayout/AdminLayout";
import Admindashboard from "./Layout/AdminControl/Pages/Dashboard";
import Adminfrom from "./Layout/AdminControl/Pages/Register/RegisterForm";
import Parentdashboard from "./Layout/ParentControl/Pages/Dashboard/Dashboard";
import Parentrecord from "./Layout/ParentControl/Pages/Product/Product";
import Parentfrom from "./Layout/ParentControl/Pages/Register/RegisterForm";
import TutorRoute from "./ProtectRoute/Tutor/TutorRoute";
import TutorLayout from "./Layout/TutorControl/TutorLayout/TutorLayout";
import Tutordashboard from "./Layout/TutorControl/Pages/Dashboard/Dashboard";
import Tutorrecord from "./Layout/TutorControl/Pages/Product/Product";
import Tutorfrom from "./Layout/TutorControl/Pages/Register/RegisterForm";
import TutorUpload from "./Layout/TutorControl/Pages/Upload/Upload";
import AdminProfile from "./Layout/AdminControl/Pages/Profile/Profile";
import StudentProfile from "./Layout/AdminControl/Pages/Profile/StudentProfile";
import TutorProfile from "./Layout/TutorControl/Pages/Register/RegisterForm";
import StudentRecords from "./Layout/AdminControl/Pages/Student/StudentRecords";
import ClassRoom from "./Layout/AdminControl/Pages/Classes/ClassRoom";
import Class from "./Layout/AdminControl/Pages/Classes/Class";
import Studentdetails from "./Layout/AdminControl/Pages/Student/StudentDetails";
import CourseList from "./Layout/AdminControl/Pages/Courses/CourseList";
import Course from "./Layout/AdminControl/Pages/Courses/Course";
import TutorRecords from "./Layout/AdminControl/Pages/Tutor/TutorRecords";
import Subject from "./Layout/AdminControl/Pages/Subject/Subject";
import SubjectList from "./Layout/AdminControl/Pages/Subject/SubjectList";
import Assignment from "./Layout/AdminControl/Pages/Assignment/Assignment";
import TutorAssignment from "./Layout/TutorControl/Pages/Assignment/Assignment";
import TutorClassroom from "./Layout/TutorControl/Pages/ClassRoom/ClassRoom";
import TutorClassroomDetails from "./Layout/TutorControl/Pages/ClassRoom/ClassRoomDetails";
import Profile from "./Layout/ParentControl/Pages/Profile";
import AssignmentStudent from "./Layout/ParentControl/Pages/Assignment/Assignments";
import AssignmentDetails from "./Layout/ParentControl/Pages/Assignment/AssignmentDetails";
import Activation from "./Layout/DefaultLayout/Activation";
import ActivationSucess from "./Layout/DefaultLayout/ActivationSuccess";
import ForgotPassword from "./Layout/DefaultLayout/ForgotPassword";
import NewPassword from "./Layout/DefaultLayout/NewPassword";
import PdfEditor from "./Layout/PDFEditor/AnnotPdf";
import PaymentRecords from "./Layout/AdminControl/Pages/Payment";
import ClassRoomDescripition from "./Layout/TutorControl/Pages/ClassRoom/ClassRoomDescripition";
import StudentProfileBoard from "./Layout/Common/StudentProfile";
import TutorProfileBoard from "./Layout/TutorControl/TutorProfileBoard/TutorProfileBoard";
import AssignmentPage from "./Layout/TutorControl/Pages/Assignment/Assignment/AssignmentPage";

const NetworkStatus = ({ setOnlineStatus }) => {
  useEffect(() => {
    function updateOnlineStatus() {
      if (navigator.onLine) {
        setOnlineStatus(true);

        setTimeout(() => {
          setOnlineStatus(null);
        }, 1000);
      } else {
        setOnlineStatus(false);
      }
    }

    updateOnlineStatus();
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return null;
};
function App() {
  const [isPermission, setIsPermission] = useState(
    localStorage.getItem("Role")
  );
  const [online, setOnlineStatus] = useState(null);
  // const rolePermission = localStorage.getItem("Role",);
  return (
    <>
      {/* <div> */}
      {online ? (
        <div className="online">
          <p>Back to online</p>
        </div>
      ) : online === false ? (
        <div className="offline">
          <p>you are offline</p>
        </div>
      ) : null}
      {/* </div> */}
      <StateManagement>
        <NetworkStatus setOnlineStatus={setOnlineStatus} />
        <BrowserRouter>
          <Routes>
            <Route element={<DefaultLayout />}>
              <Route
                path="/"
                element={<Login setIsPermission={setIsPermission} />}
              />
              <Route path="/signup" element={<Register />} />
              <Route path="/signup-success" element={<Activation />} />
              <Route path="/email-activation" element={<ActivationSucess />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/set-new-password" element={<NewPassword />} />
            </Route>
            {isPermission === "PARENT_USER" ? (
              <Route
                element={
                  <ParentRoute role={isPermission}>
                    <ParentLayout />
                  </ParentRoute>
                }
              >
                <Route path="/dashboard" element={<Parentdashboard />} />
                <Route path="/records" element={<Parentrecord />} />
                <Route path="/registration" element={<Parentfrom />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/assignment" element={<AssignmentStudent />} />
                <Route
                  path="/assignment-details"
                  element={<AssignmentDetails />}
                />
              </Route>
            ) : isPermission === "ADMIN_USER" ? (
              <Route
                element={
                  <AdminRoute role={isPermission}>
                    <AdminLayout />
                  </AdminRoute>
                }
              >
                <Route path="/dashboard" element={<Admindashboard />} />
                <Route path="/student" element={<StudentRecords />} />
                <Route path="/payment" element={<PaymentRecords />} />
                <Route path="/tutor" element={<TutorRecords />} />
                <Route path="/course" element={<CourseList />} />
                <Route path="/add-course" element={<Course />} />
                <Route path="/batch" element={<ClassRoom />} />
                <Route path="/tutor-profile" element={<Adminfrom />} />
                <Route path="/add-student" element={<AdminProfile />} />
                <Route path="/edit-student" element={<StudentProfile />} />
                <Route path="/student-details" element={<Studentdetails />} />
                {/* <Route path="/student-details" element={<Studentdetails />} /> */}
                <Route path="/add-tutor" element={<AdminProfile />} />
                <Route path="/add-batch" element={<Class />} />
                <Route path="/subject" element={<Subject />} />
                <Route path="/add-subject" element={<SubjectList />} />
                <Route path="/assignment" element={<Assignment />} />
                <Route path="/assignment-editor" element={<PdfEditor />} />
                <Route path="/studentInfo" element={<StudentProfileBoard />} />
              </Route>
            ) : isPermission === "TUTOR_USER" ? (
              <Route
                element={
                  <TutorRoute role={isPermission}>
                    <TutorLayout />
                  </TutorRoute>
                }
              >
                <Route path="/dashboard" element={<Tutordashboard />} />
                <Route path="/records" element={<Tutorrecord />} />
                <Route path="/assignment" element={<TutorAssignment />} />
                <Route path="/profile" element={<TutorProfile />} />
                <Route path="/upload" element={<TutorUpload />} />
                <Route path="/batch" element={<TutorClassroom />} />
                <Route path="/TutorInfo" element={<TutorProfileBoard />} />
                <Route path="/Assignmentpage" element={<AssignmentPage />} />
                <Route
                  path="/assignment-details"
                  element={<ClassRoomDescripition />}
                />
                <Route path="/assignment-editor" element={<PdfEditor />} />
                <Route
                  path="/batch-details"
                  element={<TutorClassroomDetails />}
                />
                <Route path="/studentInfo" element={<StudentProfileBoard />} />
              </Route>
            ) : null}
          </Routes>
        </BrowserRouter>
      </StateManagement>
    </>
  );
}

export default App;
