import React from "react";
import { Col, Container, Row } from "react-bootstrap";

function Footer() {
  return (
    <Container>
      <Row>
        <Col>
          <div className="footer">
            <h6 className="footer-copyright">© 2024 gurukul</h6>
            <div>
              <p>Privacy</p>
              <p>PolicyDocumentation</p>
              <p>Support</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
