import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BasicButton from "../../Components/Button/Button";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { oAuthLogin } from "../../Service/BaseService/BaseService";
import logo from "../../Assests/icon/11+GA Logo1x2.png";
import banner from "../../Assests/icon/network-connection-graphic-overlay-background.jpg";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../Components/Snackbar/Snackbar";
import Loader from "../../Components/Loader/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function Login({ ...props }) {
  const navigate = useNavigate();
  const [isForm, setIsForm] = useState({ email: "", password: "" });
  const [error, setIsError] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [isRole, setIsRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const toggleCheckbox = () => {
    setIsChecked((prev) => !prev);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // if (isForm.email === "admin" && isForm.password === "admin@123") {
    //   props.setIsPermission("ADMIN_USER");
    //   localStorage.setItem("Role", "ADMIN_USER");
    //   navigate("/dashboard", { replace: true });
    // } else if (isForm.email === "parent" && isForm.password === "parent@123") {
    //   props.setIsPermission("PARENT_USER");
    //   localStorage.setItem("Role", "PARENT_USER");
    //   navigate("/dashboard", { replace: true });
    // } else if (isForm.email === "tutor" && isForm.password === "tutor@123") {
    //   props.setIsPermission("TUTOR_USER");
    //   localStorage.setItem("Role", "TUTOR_USER");
    //   navigate("/dashboard", { replace: true });
    // } else {
    //   return null;
    // }

    if (isForm.email === "" && isForm.password === "") {
      setIsError("fields is requried*");
    } else {
      const form = {
        email: isForm.email,
        password: isForm.password,
      };
      try {
        setIsLoading(true);
        const response = await oAuthLogin(form);
        const responseData = await response.json();
        if (response.ok) {
          if (responseData.role === "admin") {
            props.setIsPermission("ADMIN_USER");
            localStorage.setItem("Role", "ADMIN_USER");
            navigate("/dashboard", { replace: true });
            localStorage.setItem("User", JSON.stringify(responseData));
          } else if (responseData.role === "parent") {
            props.setIsPermission("PARENT_USER");
            localStorage.setItem("Role", "PARENT_USER");
            navigate("/dashboard", { replace: true });
            localStorage.setItem("User", JSON.stringify(responseData));
          } else {
            props.setIsPermission("TUTOR_USER");
            localStorage.setItem("Role", "TUTOR_USER");
            navigate("/dashboard", { replace: true });
            localStorage.setItem("User", JSON.stringify(responseData));
          }
        } else {
          notifyError(responseData.message);
          setIsError(true);
        }
      } catch (err) {
        console.error();
        throw err;
      }
    }
    setIsLoading(false);
    // }
  };

  return (
    <div className="banner-img">
      <Container>
        <Row className="justify-content-end">
          <Col md={4} sm={12}>
            <div className="form-box-block">
              <div className="d-flex justify-content-center flex-column">
                <div className="d-flex justify-content-center">
                  <img src={logo} alt="logo" className="logo" />
                </div>
                <h5 className="text-center">LOGIN</h5>
                <span className="text-danger">{error}</span>
                <form onSubmit={submitHandler}>
                  <div className="form-block">
                    <input
                      placeholder="Email"
                      required
                      autoComplete="email"
                      value={isForm.email}
                      onChange={(e) => {
                        setIsForm({ ...isForm, email: e.target.value });
                      }}
                    />
                    <div className="password-block">
                      <input
                        autoComplete="password"
                        required
                        type={!isVisible ? "password" : "text"}
                        placeholder="Password"
                        value={isForm.password}
                        onChange={(e) => {
                          setIsForm({ ...isForm, password: e.target.value });
                        }}
                      />
                      <div
                        className="visibility"
                        onClick={() => setIsVisible(!isVisible)}
                      >
                        {isVisible ? (
                          <VisibilityIcon className="password-icon" />
                        ) : (
                          <VisibilityOffIcon className="password-icon" />
                        )}
                      </div>
                    </div>
                    <Link to={"#"} className="text-style">Forgot Password?</Link>
                    <div className="submit-block">
                      {isLoading ? (
                        <Loader color="white" size={15} />
                      ) : (
                        <input type="submit" value={"Login"} />
                      )}
                    </div>
                    <div className="text-style">
                      <p>
                        Don't have an account?{" "}
                        <Link to={"/signup"}>Register</Link>
                      </p>
                    </div>
                  </div>
                </form>
                <Snackbar />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
