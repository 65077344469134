import React, { Children, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./RegisterForm.css";
import BasicButton from "../../../../Components/Button/Button";
import profile from "../../../../Assests/icon/60111.jpg";
import { FaCamera } from "react-icons/fa";
import { Link, json, useLocation, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CardBlock from "../../../../Components/CardBlock/CardBlock";
import {
  getClassDetails,
  getCourse,
  oAuthRegister,
  studentRegister,
} from "../../../../Service/BaseService/BaseService";
import { successAlert } from "../../../../Components/Popup/Basicpopup";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../Components/Loader/Loader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import dayjs from "dayjs";
function ParentProfile() {
  useEffect(() => {
    course();
  }, []);
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const location = useLocation();
  const navigate = useNavigate();
  const getParams = new URLSearchParams(location.search);
  const getId = getParams.get("id");
  const [isCollapse, setIsCollapse] = useState(0);
  const [isfile, setIsFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [courseData, setIsCourseData] = useState(null);
  const [classData, setIsClassData] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  const registerForm = {
    name: "",
    surname: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    address1: "",
    address: "",
    postalCode: "",
    Children: [
      {
        firstName: "",
        lastName: "",
        gender: "",
        previewImage: null,
        isGurukulStudent: false,
        dateOfBirth: "",
        academic: "2024-2025",
        currentSchool: "",
        schoolYear: "",
        studentProfilePictures: null,
        courses: "",
        class: "",
      },
    ],
  };
  const [isRegisterForm, setIsRegisterForm] = useState(registerForm);
  const addRowHandler = () => {
    const visible = isRegisterForm.Children.length;
    setIsCollapse(visible);
    setIsRegisterForm((prev) => {
      return {
        ...prev,
        Children: [
          ...prev.Children,
          {
            previewImage: null,
            isGurukulStudent: false,
            firstName: "",
            lastName: "",
            gender: "",
            dateOfBirth: "",
            academic: "2024-2025",
            currentSchool: "",
            schoolYear: "",
            courses: "",
            class: "",
            studentProfilePictures: null,
          },
        ],
      };
    });
  };
  const removeRowHandler = (i) => {
    setIsRegisterForm((prev) => {
      return {
        ...prev,
        Children: prev.Children.filter((item, index) => index !== i),
      };
    });
  };

  const changeValueHandler = (e, i) => {
    const { name, type, files, value } = e.target;
    const newValue = type === "file" ? files[0] : value;
    if (name === "courses") {
      let updateClass = courseData.find((item, index) => item._id === value);
      setIsClassData(updateClass ? updateClass.classes : null);
    }

    if (type === "file") {
      const reader = new FileReader();
      reader.onload = () => {
        setIsRegisterForm((prev) => {
          const updatedChildren = prev.Children.map((item, index) => {
            if (index === i) {
              return {
                ...item,
                [name]: newValue,
                previewImage: reader.result,
              };
            }
            return item;
          });
          return { ...prev, Children: updatedChildren };
        });
      };
      reader.readAsDataURL(files[0]);
    } else {
      setIsRegisterForm((prev) => {
        if (0 <= i) {
          if (name === "studentcheck") {
            return {
              ...prev,
              Children: prev.Children.map((item, index) => {
                return index === i
                  ? { ...item, isGurukulStudent: !item.isGurukulStudent }
                  : item;
              }),
            };
          }
          return {
            ...prev,
            Children: prev.Children.map((item, index) => {
              return index === i ? { ...item, [name]: newValue } : item;
            }),
          };
        } else {
          return { ...prev, [name]: newValue };
        }
      });
    }
  };

  const collapseRowHandler = (i) => {
    setIsCollapse((prev) => {
      if (prev === i) {
        return null;
      }
      return i;
    });
  };



  const course = async () => {
    try {
      const response = await getCourse();
      const responseData = await response.json();
      if (response.ok) {
        setIsCourseData(responseData.message);
      }
    } catch (err) {
      throw err;
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();

    if (isRegisterForm.password !== isRegisterForm.confirmPassword) {
      toast.error("Confirm password does not match");
      return;
    }

    const invalidChild = isRegisterForm.Children.some(
      (child) => !child.gender || !child.schoolYear || !child.courses
    );

    if (invalidChild) {
      toast.error("Fields are required");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("name", isRegisterForm.name);
    formData.append("surname", isRegisterForm.surname);
    formData.append("phone", isRegisterForm.phone);
    formData.append("email", isRegisterForm.email);
    formData.append("password", isRegisterForm.password);
    formData.append("confirmPassword", isRegisterForm.confirmPassword);
    formData.append("address", isRegisterForm.address);
    formData.append("address1", isRegisterForm.address1);
    formData.append("postalCode", isRegisterForm.postalCode);

    isRegisterForm.Children.forEach((child, index) => {
      formData.append(`Children[${index}][firstName]`, child.firstName);
      formData.append(`Children[${index}][lastName]`, child.lastName);
      formData.append(`Children[${index}][gender]`, child.gender);
      formData.append(`Children[${index}][dateOfBirth]`, child.dateOfBirth);
      formData.append(`Children[${index}][academic]`, child.academic);
      formData.append(`Children[${index}][currentSchool]`, child.currentSchool);
      formData.append(`Children[${index}][schoolYear]`, child.schoolYear);
      formData.append(`Children[${index}][courses]`, child.courses);
      formData.append(`Children[${index}][classId]`, child.class);
      formData.append(
        `Children[${index}][isGurukulStudent]`,
        child.isGurukulStudent
      );
      if (child.studentProfilePictures) {
        formData.append(`studentProfilePictures`, child.studentProfilePictures);
      }
    });

    try {
      const response = await oAuthRegister(formData);
      const responseData = await response.json();

      if (response.ok) {
        setIsRegisterForm(registerForm);
        navigate(`/signup-success?email=${isRegisterForm.email}`);
        successAlert(responseData.message);
      } else {
        toast.error(responseData.message);
      }
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while registering.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Row>
        <Col md={12}>
          <form onSubmit={submitHandler}>
            <div>
              <div className="profile-form">
                <h5>Parent Details</h5>
                <div className="registration-setup">
                  <div>
                    <label>Name</label>
                    <input
                      name="name"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.name}
                      onChange={changeValueHandler}
                    />
                  </div>
                  <div>
                    <label>Surname</label>
                    <input
                      name="surname"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.surname}
                      onChange={changeValueHandler}
                    />
                  </div>
                </div>
                <div className="registration-setup">
                  <div>
                    <label>Email address</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.email}
                      onChange={changeValueHandler}
                    />
                  </div>
                  <div>
                    <label>Phone</label>
                    <input
                      name="phone"
                      className="form-control modal-inputCourseModal"
                      // required
                      value={isRegisterForm.phone}
                      onChange={changeValueHandler}
                    />
                  </div>
                </div>
                <div className="registration-setup">
                  <div>
                    <label>Password</label>
                    <input
                      name="password"
                      type="password"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.password}
                      onChange={changeValueHandler}
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <label>Confirm Password</label>
                    <input
                      type={!isVisible ? "password" : "text"}
                      name="confirmPassword"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.confirmPassword}
                      onChange={changeValueHandler}
                    />
                    <div
                      className="visibility pt-3"
                      onClick={() => setIsVisible(!isVisible)}
                      style={{right:"6px", top:"27px", height:"auto"}}
                    >
                      {isVisible ? (
                        <VisibilityIcon className="password-icon" />
                      ) : (
                        <VisibilityOffIcon className="password-icon" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="registration-setup">
                  <div>
                    <label>Address</label>
                    <textarea
                      name="address"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.address}
                      onChange={changeValueHandler}
                    ></textarea>
                  </div>
                </div>
                <div className="registration-setup">
                  <div>
                    <label>Address 1 (optional)</label>
                    <textarea
                      name="address1"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.address1}
                      onChange={changeValueHandler}
                    ></textarea>
                  </div>
                </div>
                <div className="registration-setup">
                  <div>
                    <label>Postal Code</label>
                    <input
                      name="postalCode"
                      className="form-control modal-inputCourseModal"
                      value={isRegisterForm.postalCode}
                      onChange={changeValueHandler}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <CardBlock
                  changeValueHandler={changeValueHandler}
                  form={isRegisterForm.Children}
                  removeRowHandler={removeRowHandler}
                  collapseRowHandler={collapseRowHandler}
                  isCollapse={isCollapse}
                  selectedImage={selectedImage}
                  courseData={courseData}
                  classData={classData}
                />
              </div>
              <div className="d-flex justify-content-end mb-3">
                <BasicButton
                  value={"+ Add student "}
                  baseStyle="profile-save"
                  onClick={addRowHandler}
                />
              </div>
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="submit-register-block mb-3">
                {isLoading ? (
                  <Loader color="white" size={15} />
                ) : (
                  <input
                    type="submit"
                    className="submit-btn-register "
                    value={"Save"}
                  />
                )}
              </div>
              <div>
                <p className="text-style">
                  Do you have a account? <Link to={"/"}>signIn</Link>
                </p>
              </div>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default ParentProfile;
