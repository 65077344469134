import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Profile.css";
import BasicButton from "../../../../Components/Button/Button";
import profile from "../../../../Assests/icon/60111.jpg";
import { FaCamera } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { roleRegister } from "../../../../Service/BaseService/BaseService";
import { successAlert } from "../../../../Components/Popup/Basicpopup";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function Profile() {
  const user = localStorage.getItem("User");
  const userToken = JSON.parse(user);
  const location = useLocation();
  const getParams = new URLSearchParams(location.search);
  const record = getParams.get("record");
  const getId = getParams.get("id");
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const form = {
    email: "",
    password: "",
    gender: "",
    role: record,
    firstName: "",
    phone: "",
    dateOfBirth: "",
  };
  const [isInput, setIsInput] = useState(form);
  const handleChange = (e) => {
    setIsInput((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await roleRegister(userToken.token, isInput);
      const responseData = await response.json();
      if (response.ok) {
        successAlert(responseData.message);
        if (record === "student") {
          navigate("/student");
        } else {
          navigate("/tutor");
        }
      }
    } catch (err) {}
  };
  return (
    <Container>
      <Row>
        <Col md={12}>
          <div className="profile-form">
            <h5>Add {record === "student" ? "student" : "tutor"}</h5>
            <form onSubmit={submitHandler}>
              <div>
                <div>
                  <label>Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={isInput.firstName}
                  />
                </div>
                <div>
                  <label>Gender</label>
                  <select
                    onChange={handleChange}
                    className="form-control select-block"
                    required
                    name="gender"
                    value={isInput.gender}
                  >
                    <option>select</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
              <div>
                <div>
                  <label>Phone</label>
                  <input
                    name="phone"
                    className="form-control no-spinners"
                    required
                    onChange={handleChange}
                    value={isInput.phone}
                    type="number"
                  />
                </div>
                <div>
                  <label>Date of Birth</label>
                  <input
                    name="dateOfBirth"
                    className="form-control"
                    type="date"
                    onChange={handleChange}
                    value={isInput.dateOfBirth}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label>Email address</label>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    value={isInput.email}
                  />
                </div>
                <div style={{ position: "relative" }}>
                  <label>Password</label>
                  <input
                    className="form-control"
                    type={!isVisible ? "password" : "text"}
                    name="password"
                    onChange={handleChange}
                    value={isInput.password}
                  />
                  <div
                    className="visibility pt-3"
                    onClick={() => setIsVisible(!isVisible)}
                    style={{top:"27px"}}
                  >
                    {isVisible ? (
                      <VisibilityIcon className="password-icon" />
                    ) : (
                      <VisibilityOffIcon className="password-icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="save-border d-flex justify-content-end">
                <input
                  type="submit"
                  className="submit-save-btn"
                  value={"Save"}
                />
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Profile;
