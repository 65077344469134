import React, { useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import upload from "../../../../../Assests/icon/file.png";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Snackbar, {
  notifyError,
  notifySucess,
} from "../../../../../Components/Snackbar/Snackbar";
import { CircleMinus } from "lucide-react";
import BasicDropdown from "../../../../../Components/Dropdown/Dropdown";
import BasicButton from "../../../../../Components/Button/Button";
import {
  createAssignment,
  getByIdAssignment,
  getClassStudent,
  updateAssignment,
} from "../../../../../Service/BaseService/BaseService";
import { successAlert } from "../../../../../Components/Popup/Basicpopup";

const AssignmentPage = (props) => {
  const {
    modalCloseHandler,
    data,
    student,
    selectAll,

    file,
  } = props;
  console.log();
  const user = localStorage.getItem("User");
  const userToken = JSON?.parse(user);

  const [isFile, setIsFile] = useState([]);
  const [isData, setIsData] = useState(null);
  const [isStudent, setIsStudent] = useState();
  const [isTotalStudent, setIsTotalStudent] = useState();
  const [isSelectAll, setIsSelectAll] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [rowDataDefault, setRowDataDefault] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignmentId, setIsAssignment] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const form = {
    name: "",
    description: "",
    dueDate: "",
    classRoomId: "",
    scheduleDate: "",
    schedule: "",
    studentIds: [],
    assignmentURL: null,
    Assignments: null,
  };

  const [isFormData, setIsFormData] = useState(form);
  const changeValue = (e) => {
    setIsFormData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    if (e.target.name === "classRoomId") {
      return getStudent(`classId=${e.target.value}`, null);
    }
  };
  const getStudent = async (id, assignmentId) => {
    try {
      const response = await getClassStudent(userToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        console.log(responseData?.assignedStudents[0]);
        setIsStudent(
          responseData?.assignedStudents[0]?.studentId?.map((item) => ({
            ...item,
            isChecked: false,
          }))
        );
        if (assignmentId) {
          getByAssignmentData(assignmentId);
          setIsAssignment(assignmentId);
        }
      }
    } catch (err) {
      throw err;
    }
  };
  const removeRowHandler = (i) => {
    let update = [...isFile];
    update = update.filter((item, index) => index !== i);
    setIsFile(update);
    setIsFormData((prev) => {
      return {
        ...prev,
        assignmentURL: update,
        Assignments: update,
      };
    });
  };

  const getByAssignmentData = async (id) => {
    try {
      const response = await getByIdAssignment(userToken.token, id);
      const responseData = await response.json();
      if (response.ok) {
        setIsFormData(responseData.message);
        setIsFormData((prev) => {
          return {
            ...prev,
            Assignments: responseData.message.assignmentURL,
          };
        });
        setIsFile(responseData.message.assignmentURL);
        setIsStudent((prev) => {
          let update = prev.map((item, index) => {
            return {
              ...item,
              isChecked: responseData.message.studentIds.includes(item._id),
            };
          });
          checkHandler(update);
          return update;
        });
      }
    } catch (err) {
      throw err;
    }
  };
  const checkHandler = (update) => {
    setIsSelectAll((prev) => {
      return update.every((item) => item.isChecked);
    });
  };

  const selectallHandler = (e) => {
    let updateArray = [...isStudent];
    if (e.target.checked) {
      updateArray = updateArray?.map((item, index) => {
        return { ...item, isChecked: true };
      });
    } else {
      updateArray = updateArray?.map((item, index) => {
        return { ...item, isChecked: false };
      });
    }
    setIsStudent(updateArray);
    setIsSelectAll((prev) => {
      return updateArray?.every((item) => item.isChecked);
    });
  };
  const handleClick = () => {
    document.getElementById("file-upload").click();
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const buttonValue = e.nativeEvent.submitter.value;

    const scheme =
      buttonValue === "Submit" || buttonValue === "Update" ? "submit" : "draft";
    const updatedForm = {
      ...isFormData,
      schedule: scheme,
      studentIds: isStudent
        ?.filter((item) => item.isChecked)
        ?.map((item) => item._id),
    };
    setIsFormData(updatedForm);

    if (!updatedForm.classRoomId) {
      return notifyError("class not selected");
    }

    const formData = new FormData();
    formData.append("name", updatedForm?.name);
    formData.append("dueDate", updatedForm.dueDate);
    formData.append("description", updatedForm.description);
    formData.append(
      "scheduleDate",
      updatedForm.scheduleDate === null ? "" : updatedForm.scheduleDate
    );
    formData.append("schedule", updatedForm.schedule);
    formData.append("studentIds", JSON.stringify(updatedForm.studentIds));
    if (isAssignmentId) {
      formData.append(
        "assignmentURL",
        JSON.stringify(
          updatedForm.assignmentURL.map((item) => ({
            filename: item.filename,
            filepath: item.filepath,
            _id: item._id,
          }))
        )
      );
    } else {
      formData.append(
        "assignmentURL",
        JSON.stringify(updatedForm.assignmentURL)
      );
    }
    if (updatedForm.Assignments && updatedForm.Assignments.length > 0) {
      updatedForm.Assignments.filter((file) => file instanceof File).forEach(
        (file) => {
          formData.append("assignments", file, file?.name);
        }
      );
    } else {
      formData.append("assignments", null);
    }

    if (!isAssignmentId) {
      try {
        const response = await createAssignment(
          userToken.token,
          updatedForm.classRoomId,
          formData
        );
        const responseData = await response.json();
        if (response.ok) {
          setIsFormData(form);
          console.log(form);
          modalCloseHandler();
          setIsAssignment(null);
          successAlert(responseData.message);
          //   fetchAllData();
        } else {
          notifyError(responseData.message);
        }
      } catch (err) {}
    } else {
      try {
        const response = await updateAssignment(
          userToken.token,
          isAssignmentId,
          formData
        );
        const responseData = await response.json();
        if (response.ok) {
          setIsFormData(form);
          modalCloseHandler();
          setIsAssignment(null);
          successAlert(responseData.message);
          //   fetchAllData();
          // navigate("/assignment");
        } else {
          notifyError(responseData.message);
        }
      } catch (err) {}
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      let updateAssignemnt = [...isFile];
      updateAssignemnt = [...updateAssignemnt, file];
      setIsFile(updateAssignemnt);
      setIsFormData((prev) => {
        return {
          ...prev,
          Assignments: updateAssignemnt,
        };
      });
    }
    // profileUpdate(formData);
  };
  const changeHandler = (type, i) => {
    let updateData = [...isStudent];
    if (type === "add") {
      updateData = updateData?.map((item, index) =>
        item._id === i ? { ...item, isChecked: !item.isChecked } : item
      );
    } else {
      updateData = updateData?.map((item, index) =>
        item._id === i ? { ...item, isChecked: false } : item
      );
    }
    setIsStudent(updateData);
    setIsSelectAll((prev) => {
      return updateData?.every((item) => item.isChecked);
    });
  };
  return (
    <Container>
      <Row>
        <Col>
          <div className="row-block p-3">
            <Form onSubmit={submitHandler}>
              <div className="d-flex gap-3">
                <div style={{ flex: 1 }}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="modal-label">Title</Form.Label>
                    <Form.Control
                      className="text-focus"
                      required
                      value={form?.name}
                      onChange={changeValue}
                      name="name"
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="modal-label">Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      className="form-control"
                      name="description"
                      required
                      value={form?.description}
                      onChange={changeValue}
                      rows={3}
                    />
                  </Form.Group>
                  <div className="d-flex gap-2">
                    <div style={{ flex: 1 }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">Batch</Form.Label>
                        <Form.Select
                          className="modal-input"
                          onChange={changeValue}
                          value={form?.classRoomId}
                          name="classRoomId"
                          required
                        >
                          <option>select</option>
                          {data?.map((item, index) => {
                            return (
                              <option value={item._id}>{item.title}</option>
                            );
                          })}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">Student</Form.Label>
                        <BasicDropdown
                          data={student}
                          changeHandler={changeHandler}
                          selectAll={selectAll}
                          selectallHandler={selectallHandler}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex gap-2">
                    <div style={{ flex: 1 }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">
                          Due date(optional)
                        </Form.Label>
                        <Form.Control
                          value={form?.dueDate}
                          type="datetime-local"
                          className="modal-input"
                          onChange={changeValue}
                          name="dueDate"
                        />
                      </Form.Group>
                    </div>
                    <div style={{ flex: 1 }}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="modal-label">
                          Schedule date(optional)
                        </Form.Label>
                        <Form.Control
                          disabled={isAssignmentId && form.schedule !== "draft"}
                          value={form?.scheduleDate}
                          type="datetime-local"
                          className="modal-input"
                          name="scheduleDate"
                          onChange={changeValue}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="modal-label">Attachments</Form.Label>
                    <div className="upload-block" onClick={handleClick}>
                      <div>
                        <img
                          src={upload}
                          alt="upload"
                          className="upload-icon"
                        />
                      </div>
                      <div>
                        <label className="upload-label">
                          <span className="span-text">
                            Click here to upload
                          </span>
                        </label>
                      </div>
                      <Form.Control
                        id="file-upload"
                        className="modal-input file-upload"
                        type="file"
                        accept=".pdf"
                        autoFocus
                        onChange={handleFileChange}
                        name="title"
                      />
                    </div>
                  </Form.Group>
                  <div className="d-flex gap-2 flex-wrap">
                    {file?.map((item, index) => {
                      return (
                        <div className="upload-file">
                          <div className="mt-2">
                            <div>
                              <div>
                                <DescriptionOutlinedIcon className="file-icon" />
                                <span>{item.name || item.filename}</span>
                              </div>
                            </div>
                          </div>
                          <CircleMinus
                            className="minus-icon"
                            onClick={() => removeRowHandler(index)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <Modal.Footer className="modal-footer-modal gap-2">
                <BasicButton
                  value={"Close"}
                  baseStyle="close-btn"
                  onClick={modalCloseHandler}
                />
                <input
                  type="submit"
                  className="submit-save-btn-draft"
                  value={"save as draft"}
                />
                <input
                  type="submit"
                  className="submit-save-btn"
                  value={isAssignmentId ? "Update" : "Submit"}
                />
              </Modal.Footer>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AssignmentPage;
