import React from "react";
import { Outlet } from "react-router-dom";
import "./DefaultLayout.css";
import Footer from "./Footer";

function DefaultLayout() {
  return (
    <div className="default-layout">
      <Outlet />
      {/* <div className="footer-layout">
        <Footer />
      </div> */}
    </div>
  );
}

export default DefaultLayout;
