import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../Assests/icon/11+GA Logo 3x4.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function NewPassword() {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={4}>
          <div className="contanier-type">
            <form>
              <div className="mt-3">
                <img src={logo} alt="Logo Small" />
                <h3>Set New Password</h3>
                <p>Enter your new password and rest your password.</p>
                <div className="password-block">
                  <input
                    autoComplete="password"
                    required
                    type={!isVisible ? "password" : "text"}
                    placeholder="Password"
                    // value={isForm.password}
                    // onChange={(e) => {
                    //   setIsForm({ ...isForm, password: e.target.value });
                    // }}
                  />
                  <div
                    className="visibility"
                    onClick={() => setIsVisible(!isVisible)}
                  >
                    {isVisible ? (
                      <VisibilityIcon className="password-icon" />
                    ) : (
                      <VisibilityOffIcon className="password-icon" />
                    )}
                  </div>
                </div>
                <div className="justify-content-center d-flex">
                  <div className="submit-register-block mt-3 w-100">
                    <input
                      type="submit"
                      className="submit-btn-register"
                      value={"Reset Password"}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-center">
                <p>
                  Back to{" "}
                  <Link to={"/"} className="clk-link">
                    Login
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default NewPassword;
